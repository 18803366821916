import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'

import warning from 'common/assets/svg/warning.svg'

import styles from './OutOfCreditsModal.module.scss'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import { useNavigate } from 'react-router-dom'
import BuyCreditsModal from 'common/components/BuyConvrt/BuyCreditsModal'

interface OutOfCreditsModalProps {
  open: boolean
  onClose: () => void
  creditType?: string
  location?: any
  type?: any
  caption?: any
  isSubCaption?: any
}

const OutOfCreditsModal: React.FC<OutOfCreditsModalProps> = ({
  open,
  onClose,
  creditType,
  location,
  type = 'points',
  caption = 'lists',
  isSubCaption = true,
}) => {
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [subscriptionType, setSubscriptionType] = useState('BETA')
  const [openBuyCreditsModal, setOpenBuyCreditsModal] = useState(false)

  const navigate = useNavigate()

  const locationMap: any = {
    hubspot: 'with export to HubSpot',
    campaign: 'with your Campaign',
    companies: 'filtering companies',
    contacts: 'filtering prospects',
    list: 'create list',
  }

  const selectedLocation = locationMap[location] || ''

  const button =
    subscriptionType === 'BETA' || subscriptionType === 'TRIAL' ? (
      <Button
        size={'large'}
        style={{ color: 'white', backgroundColor: '#7043ff' }}
        onClick={() => {
          if (type === 'limit') {
            navigate('/checkout')
          } else {
            setOpenBuyCreditsModal(true)
          }
        }}>
        {type === 'limit' ? 'Update' : 'Time To Top Up Your Points'}
      </Button>
    ) : (
      <Button
        size={'large'}
        style={{ color: 'white', backgroundColor: '#7043ff' }}
        onClick={() => {
          // window.open('https://calendly.com/roy-convrt/demo-convrt', '_blank')
          if (type === 'limit') {
            navigate('/checkout')
          } else {
            setOpenBuyCreditsModal(true)
          }
        }}>
        {location === 'companies' || location === 'contacts' || type === 'limit'
          ? 'Update'
          : 'Time To Top Up Your Points'}
      </Button>
    )

  useEffect(() => {
    const getSubscriptionType = async () => {
      const { data } = await refetchGetUserDetails()
      const type = data?.subscription_type
      setSubscriptionType(type)
    }
    getSubscriptionType()
  }, [])

  return (
    <>
      <Modal
        className='modalStyleNewDeal'
        centered
        open={open}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        onCancel={() => {
          onClose()
        }}
        width={'fit-content'}
        style={{ minWidth: 500, maxWidth: 500 }}>
        <div className={styles.contentStyle}>
          <div className={styles.container}>
            <img src={warning} alt='' />
            {/* <p>Insufficient {creditType} Credits for Export</p> */}
            <p>{type === 'limit' ? `Reached the ${caption} limit ` : `Insufficient Points `}</p>
            {type !== 'limit' && <span style={{ fontSize: '14px' }}>Time To Top Up Your Points</span>}
            <span>
              {' '}
              {isSubCaption
                ? type === 'limit'
                  ? `Your plan does not supports adding more ${caption}`
                  : `Please replenish your points to proceed ${selectedLocation}`
                : ''}
            </span>
            <div className={styles.footer}>
              <Button size={'large'} onClick={onClose}>
                Cancel
                {/* {type === 'limit' ? 'Cancel' : 'Use my points'} */}
              </Button>
              {button}
            </div>
          </div>
        </div>
      </Modal>
      <BuyCreditsModal
        open={openBuyCreditsModal}
        onClose={() => {
          setOpenBuyCreditsModal(false)
        }}
      />
    </>
  )
}
export default OutOfCreditsModal
