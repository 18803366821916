import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'state'
import {
  useLazyAddHubspotFieldMappingQuery,
  useLazyGetCreditsQuery,
  useLazyGetHandleSpothubQuery,
  useLazyGetHubspotFieldMappingQuery,
  useLazyGetHubspotPropertiesQuery,
  useLazyGetLoginHubSpotQuery,
} from '../../state/api/SettingsApi'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { LocalStorageKeys } from 'common/constants/localstorage.constants'
import { SET_HUBSPOT_DEALS } from 'features/contacts/state/slice/contactsSlice'
import { get, post } from 'common/api/axios'
import { message } from 'antd'

type hubspot_integration_list_type = { caption: string; id: number; from?: any; to?: any; group?: string }[]
let numOfSelectRows: number = 0

const items: {
  label: string
  options: {
    dataSource?: string
    label: string
    value: string
    type: string
    parent_label?: string
  }[]
}[] = [
  {
    label: 'Contacts',
    options: [
      {
        label: 'Name',
        value: 'full_name',
        type: 'string',
        parent_label: 'Contacts',
      },
      {
        label: 'Company',
        value: 'company_name',
        type: 'string',
        parent_label: 'Contacts',
      },
      {
        label: 'Location',
        value: 'city',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Job title',
        value: 'job_title',
        type: 'string',
        parent_label: 'Contacts',
      },
      {
        label: 'Email',
        value: 'email',
        type: 'string',
        parent_label: 'Contacts',
      },
      {
        label: 'Phone Number',
        value: 'phone_numbers',
        type: 'number',
        parent_label: 'Contacts',
      },
      {
        label: 'Telegram',
        value: 'telegram_account',
        type: 'string',
        parent_label: 'Contacts',
      },
      {
        label: 'Company Domain',
        value: 'company_domain',
        type: 'string',
        parent_label: 'Contacts',
      },
      {
        label: 'Linkedin page',
        value: 'linkedin',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Twitter',
        value: 'twitter',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Seniority',
        value: 'seniority',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Source (Convrt)',
        value: 'source_convrt_contacts',
        type: 'string',
        parent_label: 'Contacts',
      },
    ],
  },
  {
    label: 'Company',
    options: [
      {
        label: 'Company',
        value: 'name',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Description',
        value: 'description',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Company URL',
        value: 'url',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Revenue',
        value: 'raw_yearly_revenue',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Location',
        value: 'city',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Vertical',
        value: 'vertical',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Active wallets',
        value: 'monthly_active_users',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'TVL',
        value: 'tvl',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Token name',
        value: 'token_name',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Smart contracts',
        value: 'sc_count',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Total funding',
        value: 'funding_total_usd',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'State',
        value: 'state',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Domain',
        value: 'domain',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Phone',
        value: 'contact_phone',
        type: 'string',
        parent_label: 'Companies',
      },
      {
        label: 'Source (Convrt)',
        value: 'source_convrt_company',
        type: 'string',
        parent_label: 'Companies',
      },
    ],
  },
  {
    label: 'Campaign',
    options: [
      {
        label: 'Name',
        value: 'name',
        type: 'string',
        parent_label: 'Campaign',
      },
      {
        label: 'Platform',
        value: 'platform',
        type: 'string',
        parent_label: 'Campaign',
      },
    ],
  },
]
const defaultIntegrationList = {
  '1': {
    from: { value: 'full_name', label: 'Name' },
    to: { value: 'full_name', label: 'First Name' },
    caption: 'row-1',
    id: 1,
    group: 'Contacts',
  },
  '2': {
    from: { value: 'email', label: 'Email' },
    to: { value: 'email', label: 'Email' },
    caption: 'row-2',
    id: 2,
    group: 'Contacts',
  },
  // '3': {
  //   from: { value: 'phone_numbers', label: 'Phone Number' },
  //   to: { value: 'phone', label: 'Phone' },
  //   caption: 'row-3',
  //   id: 3,
  //   group: 'Contacts',
  // },
  '4': {
    from: { value: 'job_title', label: 'Job title' },
    to: { value: 'jobtitle', label: 'Job title' },
    caption: 'row-4',
    id: 4,
    group: 'Contacts',
  },
  '5': {
    from: { value: 'telegram_account', label: 'Telegram' },
    to: { value: 'telegram', label: 'Telegram' },
    caption: 'row-5',
    id: 5,
    group: 'Contacts',
  },
  '6': {
    from: { value: 'city', label: 'Location' },
    to: { value: 'location', label: 'Location' },
    caption: 'row-6',
    id: 6,
    group: 'Contacts',
  },
  '7': {
    from: { value: 'company_name', label: 'Company' },
    to: { value: 'company', label: 'company' },
    caption: 'row-7',
    id: 7,
    group: 'Contacts',
  },
  '8': {
    from: { value: 'linkedin', label: 'Linkedin page' },
    to: { value: 'linkedin', label: 'Linkedin page' },
    caption: 'row-8',
    id: 8,
    group: 'Contacts',
  },
  '9': {
    from: { value: 'twitter', label: 'Twitter' },
    to: { value: 'twitter', label: 'Twitter' },
    caption: 'row-9',
    id: 9,
    group: 'Contacts',
  },
  '10': {
    from: { value: 'seniority', label: 'Seniority' },
    to: { value: 'seniority', label: 'Seniority' },
    caption: 'row-10',
    id: 10,
    group: 'Contacts',
  },
  '11': {
    from: { value: 'company_domain', label: 'Company Domain' },
    to: { value: 'website', label: 'Company Domain' },
    caption: 'row-11',
    id: 11,
    group: 'Contacts',
  },
  '12': {
    from: { value: 'name', label: 'Company' },
    to: { value: 'name', label: 'Company' },
    caption: 'row-12',
    id: 12,
    group: 'Companies',
  },
  '13': {
    from: { value: 'city', label: 'Location' },
    to: { value: 'city', label: 'Location' },
    caption: 'row-13',
    id: 13,
    group: 'Companies',
  },
  '14': {
    from: { value: 'state', label: 'State' },
    to: { value: 'state', label: 'State' },
    caption: 'row-14',
    id: 14,
    group: 'Companies',
  },
  '15': {
    from: { value: 'domain', label: 'Domain' },
    to: { value: 'domain', label: 'Domain' },
    caption: 'row-15',
    id: 15,
    group: 'Companies',
  },
  // '16': {
  //   from: { value: 'contact_phone', label: 'Phone' },
  //   to: { value: 'phone', label: 'Phone' },
  //   caption: 'row-16',
  //   id: 16,
  //   group: 'Companies',
  // },
  '17': {
    from: { value: 'name', label: 'Name' },
    to: { value: 'name', label: 'Name' },
    caption: 'row-17',
    id: 17,
    group: 'Campaign',
  },
  '18': {
    from: { value: 'platform', label: 'Platform' },
    to: { value: 'platform', label: 'Platform' },
    caption: 'row-18',
    id: 18,
    group: 'Campaign',
  },
}

let selectedOptions: any = {}

const useHubspot = () => {
  const dispatch = useAppDispatch()
  // eslint-disable-next-line
  const [user, setUser] = useLocalStorage(LocalStorageKeys.HUBSPOT_ACCESS_TOKEN, null)
  const [hubSpotKey, setHubSpotKey] = useState('')
  const navigate = useNavigate()
  const [refetchAddHubspotFieldMapping, { isFetching: isFetchingAddHubspot }] = useLazyAddHubspotFieldMappingQuery()
  const [refetchGetHubspotFieldMapping] = useLazyGetHubspotFieldMappingQuery()
  const [isLoading, setIsLoading] = useState(true)
  const [refetchGetCredits, { data: credits }] = useLazyGetCreditsQuery()
  // eslint-disable-next-line
  const [showExpirationMessage, setShowExpirationMessage] = useState(0)
  const [existKey, setExistKey] = useState(false)
  const [refetchAddUpdateHubSpot] = useLazyGetHandleSpothubQuery()
  const [refetchLoginHubSpot] = useLazyGetLoginHubSpotQuery()
  const { userDetails } = useAuth()
  const [hubspotIntegrationList, setHubspotIntegrationList] = useState<hubspot_integration_list_type>([])
  const [refetchGetHubspotProperties, { data: hubspotProps }] = useLazyGetHubspotPropertiesQuery()

  let objetcFieldMapping: any = {}

  const handleOptionSelect = (fieldMapping: any, row_id: number, type: string, group: string) => {
    if (objetcFieldMapping[row_id] === undefined) {
      objetcFieldMapping[row_id] = { from: '', to: '', caption: `row-${row_id}`, id: row_id, group: group }
    }
    objetcFieldMapping[row_id][type] = fieldMapping
    selectedOptions = { ...selectedOptions, ...objetcFieldMapping }

    const newList = hubspotIntegrationList.map((data: any) => {
      let row = { ...data }
      if (row.id === row_id) {
        row[type] = objetcFieldMapping[row_id][type]
        // try {
        //   data.from = objetcFieldMapping[row_id]?.from
        //   data.to = objetcFieldMapping[row_id]?.to
        // } catch (err) {
        //   console.log(err)
        // }
      }
      return row
    })
    setHubspotIntegrationList(newList)
  }
  const handleAddItemsToMappingList = (group = '') => {
    numOfSelectRows++
    setHubspotIntegrationList([
      ...hubspotIntegrationList,
      {
        caption: `row-${numOfSelectRows}`,
        id: numOfSelectRows,
        from: { label: 'Select', value: `select-from-${numOfSelectRows}` },
        to: { label: 'Select', value: `select-to-${numOfSelectRows}` },
        group,
      },
    ])
  }

  const deleteRowFieldMapping = (row_id: string) => {
    selectedOptions = hubspotIntegrationList.filter((item: any) => {
      return item.id !== row_id
    })

    setHubspotIntegrationList((hubspotIntegrationList) =>
      hubspotIntegrationList.filter((item: any) => item.id !== row_id),
    )
  }

  useEffect(() => {
    if (window.location.search) {
      const search = window.location.search
      const queryString = search.startsWith('?') ? search.slice(1) : search

      const [key, value] = queryString.split('=')
      if (key === 'code' && value) {
        const addUpdateHubSpot = async () => {
          try {
            const result = await refetchAddUpdateHubSpot(`${value}`)
            if (result?.error) {
              message.error(
                result?.error?.data?.message ? result?.error?.data?.message : 'An Error has occured. Please try again.',
              )
            } else {
              setUser(result.data.response.data.access_token)
              message.success(`Hubspot API key added successfully`)
              await refetchGetCredits()
              await refetchAddHubspotFieldMapping({
                hubspotFieldMapping: defaultIntegrationList,
                isSaveFieldMapping: false,
              })
              navigate(`/settings`)
              const fetchHubspotData = async () => {
                const tokenHubSpot = localStorage.getItem('hubspot_access_token')
                const res = await refetchGetHubspotProperties(tokenHubSpot)
                setExistKey(res.isSuccess)
              }
              fetchHubspotData().catch(console.error)

              //setExistKey(true)
            }
          } catch (e) {
            console.error('ERROR: ', e)
          }
        }
        addUpdateHubSpot()
      }
    }
  }, [])

  useEffect(() => {
    post('/log', {
      action: 'settings',
    })

    const fetchData = async () => {
      try {
        const result = await refetchGetCredits()
        if (result?.error) {
          setShowExpirationMessage(result?.error?.status)
        }
      } catch (e) {
        console.error('ERROR: ', e)
      }
    }

    fetchData().catch(console.error)

    const fetchHubspotData = async () => {
      try {
        const tokenHubSpot = localStorage.getItem('hubspot_access_token')
        const res = await refetchGetHubspotProperties(tokenHubSpot)
        setExistKey(res.isSuccess)
      } catch (e) {
        console.error('ERROR: ', e)
      }
    }

    const fetchHubspotDealData = async () => {
      try {
        const fetchedDeals = await get('/outreach/getHubspotAllDeals')
        dispatch(SET_HUBSPOT_DEALS(fetchedDeals.data.response))
      } catch (e) {
        console.error('ERROR: ', e)
      }

      // setHunspotDeals(fetchedDeals.data.response)
    }
    const tokenHubSpot = localStorage.getItem('hubspot_access_token')
    if (tokenHubSpot && tokenHubSpot !== 'null') {
      fetchHubspotDealData()
      fetchHubspotData().catch(console.error)
    }
  }, [])

  useEffect(() => {
    if (credits) {
      if (credits?.response?.hubspot_key) {
        setHubSpotKey(credits.response.hubspot_key[0].auth_key)
        // setExistKey(true)
      }
    }
  }, [credits?.response?.hubspot_key])

  const handleLoginHubSpot = async () => {
    try {
      const result = await refetchLoginHubSpot()
      const newWindow = window.open(result.data.response, '_blank')

      if (newWindow) {
        newWindow.focus()
      }
    } catch (e) {
      console.error('ERROR: ', e)
    }
  }

  const fetchHubspotFieldMapping = async () => {
    try {
      const result = await refetchGetHubspotFieldMapping()
      if (result.data.response.length !== 0) {
        selectedOptions = result.data.response
        const index = Object.keys(selectedOptions).pop() || 0
        numOfSelectRows = selectedOptions[index].id
        let list = Object.values(result.data.response)
        const requiredFields = [
          {
            from: { value: 'name', label: 'Name' },
            to: { value: 'name', label: 'Name' },
            id: 17,
            group: 'Campaign',
            caption: '',
          },
          {
            from: { value: 'platform', label: 'platform' },
            to: { value: 'platform', label: 'platform' },
            id: 18,
            group: 'Campaign',
            caption: '',
          },
        ]

        let isModified = false

        requiredFields.forEach((field) => {
          const exists = list.some((item: any) => item.group === field.group && item.from.value === field.from.value)
          if (!exists) {
            field.id = list.length
            field.caption = `row-${field.id}`
            list.push(field)
            isModified = true
          }
        })

        if (isModified) {
          await refetchAddHubspotFieldMapping({
            hubspotFieldMapping: list,
            isSaveFieldMapping: false,
          })
        }

        setHubspotIntegrationList(list.map((i: any) => ({ ...i })))
      }
      setIsLoading(false)
    } catch (e) {
      console.error('ERROR: ', e)
    }
  }

  // This function removes mapping options based on the active addons.
  const filterItems = (items: any, addons: any) => {
    if (!addons?.funding) {
      items.forEach((category: any) => {
        category.options = category.options.filter(
          (option: any) =>
            !['funding_total_usd', 'last_funding_date', 'last_funding_amount_usd'].includes(option.value),
        )
      })
    }
    if (!addons?.onChain) {
      items.forEach((category: any) => {
        category.options = category.options.filter(
          (option: any) =>
            ![
              'chains_repartition',
              'tvl',
              'avg_deployment_diff_in_sec',
              'sc_count',
              'monthly_volume',
              'transactions_count',
              'token_name',
              'token_symbol',
              'total_users',
              'monthly_active_users',
              'monthly_new_users',
              'whales',
              'chains',
            ].includes(option.value),
        )
      })
    }
    if (!addons?.web2metrics) {
      items.forEach((category: any) => {
        category.options = category.options.filter(
          (option: any) => !['top_countries', 'website_traffic'].includes(option.value),
        )
      })
    }
    if (!addons?.revenue) {
      items.forEach((category: any) => {
        category.options = category.options.filter(
          (option: any) => !['raw_yearly_revenue', 'revenue_source'].includes(option.value),
        )
      })
    }
    return items
  }

  useEffect(() => {
    if (existKey) {
      fetchHubspotFieldMapping()
    }
  }, [existKey])

  const addons = userDetails.addons
  const newItems = filterItems(items, addons)
  // filterItems(items, addons)

  return {
    existKey,
    hubSpotKey,
    handleLoginHubSpot,
    fetchHubspotFieldMapping,
    handleAddItemsToMappingList,
    refetchAddHubspotFieldMapping,
    refetchGetHubspotFieldMapping,
    hubspotIntegrationList,
    newItems,
    handleOptionSelect,
    hubspotProps,
    deleteRowFieldMapping,
    isLoading,
    isFetchingAddHubspot,
    setExistKey,
    setUser,
  }
}

export default useHubspot
