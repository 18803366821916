import React, { useState } from 'react'
import { Tooltip, Switch, Modal } from 'antd' // Import Tooltip and Switch from your library
import BlurPaywallFilters from '../../../features/intent-signals/components/paywalls/BlurPaywallFilters'
import { outreach } from 'common/constants/outreach.constants'
// import coming_soon_badge from 'common/assets/svg/coming_soon_badge.svg'
import LockComponent from 'common/assets/svg/lock.svg'

import styles from './CircleSwitch.module.scss'

interface CircleSwitchProps {
  text: string
  tooltipText: string
  color?: string
  checked: boolean
  onChange: () => void
  disabled: boolean
  addons?: boolean
}

const CircleSwitch: React.FC<CircleSwitchProps> = ({
  text,
  tooltipText,
  color,
  checked,
  onChange,
  disabled,
  addons,
}) => {
  const circleStyles = {
    backgroundColor: color === 'green' ? '#50e2ba' : color === 'pink' ? '#FF4FE6' : '#7043FF',
    border: color === 'green' ? '2px solid #3dc7a8' : color === 'pink' ? '2px solid #E62DCA' : '2px solid #8F6BFF',
  }
  const [openContactSalesMap, setOpenContactSalesMap] = useState(false)

  return (
    <div className={styles.switchesContainer}>
      <div className={styles.textContainer}>
        <div className={styles.circle} style={circleStyles}>
          <span>{text}</span>
        </div>
        <div className={styles.text}>
          <Tooltip title={tooltipText}>
            <p>{text} Connection Circle</p>
          </Tooltip>
        </div>
      </div>
      <div className={styles.switch}>
        {addons ? (
          <Switch size={'small'} checked={checked} onChange={onChange} disabled={disabled} />
        ) : (
          <div
            className={styles.comingSoon}
            onClick={() => {
              setOpenContactSalesMap(true)
            }}>
            <img src={LockComponent} alt='' />
          </div>
        )}
      </div>
      <Modal
        open={openContactSalesMap}
        onCancel={() => setOpenContactSalesMap(false)}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        width={600}
        bodyStyle={{
          height: 350,
        }}>
        <div className='flex justify-content-center'>
          <BlurPaywallFilters
            addon={`connectplus_${text}`}
            component={[]}
            onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
            customClass='contactSalesNoMargin'
            type=''
          />
        </div>
      </Modal>
    </div>
  )
}

export default CircleSwitch
