import React, { useEffect, useMemo, useState } from 'react'
import { Typography, Modal, message, Breadcrumb, Tooltip } from 'antd'
import styles from './SocialSettings.module.scss'
import OutreachConnection from 'common/components/OutreachConnection/OutreachConnection'
import useHubspot from '../Pages/Hooks/useHubspot'
import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'
import OnBoardingQrcodPage from '../../../common/components/OutreachConnection/OnBoardingPages/OnBoardingQrcodPage' //OnBoardingPages/OnBoardingQrcodPage'
import {
  useLazyGetCampaignsForAccountsServiceQuery,
  useLazyUpdateOutreachDetailsQuery,
} from 'features/settings/state/api/SettingsApi'
import SocialConnectionModal from 'common/components/SocialConnection/SocialConnectionModal'
// import { getTelegramColumns, getInstagramColumns, getLinkedinColumns } from './Columns/tableColumns'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import SocialBox from './Components/SocialBox'
import hubspotLogo from 'common/assets/jpg/hubspot.jpg'
import zapierLogo from 'common/assets/jpg/zapier.png'
import pipedriveLogo from 'common/assets/jpg/pipedrive.jpg'
import ChangeUserInfoModal from './Components/ChangeUserInfoModal'
import SocialAccountTable from './Components/SocialAccountTable'
import { getInstagramTableData, getLinkedinTableData, getTelegramTableData } from 'common/utils/userHelpers'
import AppIntegration from './AppIntegration'

interface SocialSettingsProps {
  hubspotKey: any
  shouldHideCheckout: any
}

const SocialSettings: React.FC<SocialSettingsProps> = ({ hubspotKey, shouldHideCheckout }) => {
  const [refetchUpdateOutreachDetails] = useLazyUpdateOutreachDetailsQuery()
  const [getCampaignNames, { data: allCampaignsAccount }] = useLazyGetCampaignsForAccountsServiceQuery()
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [openQrcodPage, setOpenQrcodPage] = useState(false)
  const [openChangeAccountInfo, setOpenChangeAccountInfo] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState({ area_code: '', number: '' })
  const [user, setUser] = useLocalStorage('user', null)
  const [polling, setPolling] = useState(false)
  const [outreachConnectionModalOpen, setOutreachConnectionModalOpen] = useState(false)
  // const [openAddToMappingListModal, setOpenAddToMappingListModal] = useState(false)
  const { handleLoginHubSpot } = useHubspot()
  const [openSocialConnectionModal, setOpenSocialConnectionModal] = useState(false)
  const [platform, setPlatform] = useState<any>('')
  const [checkLocalStorage, setCheckLocalStorage] = useState()
  const { userDetails } = useAuth()
  const [userData, setUserData] = useState(userDetails)
  const [currentDetailSocial, setCurrentDetailSocial] = useState('')

  const [telegramTableData, setTelegramTableData] = useState<any>(getTelegramTableData(user, allCampaignsAccount))
  // eslint-disable-next-line
  const [instagramTableData, setInstagramTableData] = useState<any>(getInstagramTableData(user))
  const [linkedinTableData, setLinkedinTableData] = useState<any>(getLinkedinTableData(user))

  const handleOutreachEdit = async (key: React.Key, e: React.ChangeEvent<HTMLInputElement>, type: any) => {
    const value = e.target.value

    const userData = await refetchUpdateOutreachDetails({
      value,
      type,
      key,
    })

    setUser(userData?.data)
  }

  // const itemsDropdown = [
  //   {
  //     label: <span>Get login code</span>,
  //     key: '0',
  //   },
  //   {
  //     label: <span>Change Account Info</span>,
  //     key: '1',
  //   },
  // ]
  // eslint-disable-next-line
  // const telegramColumns = getTelegramColumns({
  //   handleOutreachEdit,
  //   itemsDropdown,
  //   setPhoneNumber,
  //   setOpenQrcodPage,
  //   setOpenChangeAccountInfo,
  // })
  // // eslint-disable-next-line
  // const instagramColumns = getInstagramColumns()
  // // eslint-disable-next-line
  // const linkedinColumns = getLinkedinColumns()

  const channelConnectedCount = useMemo(() => {
    let count = 0
    telegramTableData?.length > 0 && count++
    linkedinTableData?.length > 0 && count++

    return count
  }, [telegramTableData, linkedinTableData])

  const formatAmountBySocial: any = {
    telegram: telegramTableData?.length,
    linkedin: linkedinTableData?.length,
  }
  const shouldRender = Object.values(formatAmountBySocial).some((amount) => amount === 0)

  const handleHubspotMapping = async () => {
    // setOpenAddToMappingListModal(true)
    setCurrentDetailSocial('Hubspot')
  }

  const appIntergration = [
    {
      name: 'Zapier',
      logo: zapierLogo,
      description: 'Automates workflows by connecting your apps, saving time and reducing manual work.',
      comingSoon: true,
    },
    {
      name: 'Hubspot',
      logo: hubspotLogo,
      description: 'A CRM platform for managing customer interactions, marketing, and sales automation.',
      connected: hubspotKey,
      functions: { connect: handleLoginHubSpot, hubspotMapping: handleHubspotMapping },
    },
    {
      name: 'Pipedrive',
      logo: pipedriveLogo,
      description: 'A sales CRM with visual pipelines and customizable workflows to streamline sales processes.',
      comingSoon: true,
    },
  ]

  const handleOpenSocialConnectionModal = (platform: string) => {
    setPlatform(platform)
    // addon
    if (platform === 'telegram' || userData?.addons?.linkedin_campaign) {
      setOpenSocialConnectionModal(true)
    } else {
      message.warning(`Your plan does not support connecting your ${platform} account.`)
    }
  }

  const handleAddSocialAccount = (socialName: string) => {
    if (socialName === 'telegram') {
      setOutreachConnectionModalOpen(true)
      setPolling(true)
    } else {
      handleOpenSocialConnectionModal(socialName)
    }
  }

  const handleNavigateToDetailSocial = (socialName: string) => {
    setCurrentDetailSocial(socialName)
  }

  const handleNavigateToDetailAppIntegration = (appName: string) => {
    setCurrentDetailSocial(appName)
  }

  const handleAddTelegramAccount = () => {
    setOutreachConnectionModalOpen(true)
    setPolling(true)
  }

  const handleEditTelegramAccount = (data: any) => {
    setPhoneNumber({ area_code: data.area_code, number: data.number })
    // setOpenChangeAccountInfo(true)
  }

  const handleGetTelegramCode = (data: any) => {
    setPhoneNumber({ area_code: data.area_code, number: data.number })
    setOpenQrcodPage(true)
  }

  useEffect(() => {
    if (polling) {
      const pollLocalStorage = setInterval(() => {
        const currentUser = JSON.parse(localStorage.getItem('user') || 'null')
        if (JSON.stringify(currentUser) !== JSON.stringify(user)) {
          setUser(currentUser)
        }
      }, 1000)

      return () => clearInterval(pollLocalStorage)
    }
  }, [user, setUser])

  useEffect(() => {
    setTelegramTableData(getTelegramTableData(user, allCampaignsAccount))
    // setInstagramTableData(getInstagramTableData(user))
  }, [user, checkLocalStorage, allCampaignsAccount])

  useEffect(() => {
    setInstagramTableData(getInstagramTableData(user))
    setLinkedinTableData(getLinkedinTableData(user))
  }, [checkLocalStorage, openSocialConnectionModal, user])

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      setUserData(data)
    }

    if (user) {
      getUserDetails()
    }

    const getCampaignsData = async () => {
      await getCampaignNames()
    }
    getCampaignsData()
  }, [])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    if (Object.keys(user)?.length > 0) {
      setTelegramTableData(getTelegramTableData(user, allCampaignsAccount))
    }
  }, [localStorage.getItem('user')])

  return (
    <>
      <div className={styles['social-page']}>
        <div className={styles['social-page__header']}>
          <Breadcrumb
            items={[
              {
                title: 'Settings',
                onClick: () => setCurrentDetailSocial(''),
              },
              {
                title: <span>{currentDetailSocial && 'Social Intergration'}</span>,
              },
            ]}
          />

          <Typography className={styles['social-page__header__pageName']}>
            {currentDetailSocial ? currentDetailSocial : 'Social & Intergrations'}
          </Typography>
        </div>

        {!currentDetailSocial && (
          <div className={styles['social-page__body']}>
            {channelConnectedCount > 0 && (
              <div className={styles['social-page__body__section']}>
                <Typography className={styles['social-page__body__section__title']}>
                  {channelConnectedCount} Channels connected to Convrt
                </Typography>
                <div className={styles.wrapping}>
                  {Object.keys(formatAmountBySocial)
                    .filter((social) => formatAmountBySocial[social] > 0)
                    .map((social: any) => {
                      return (
                        <SocialBox
                          name={social}
                          imageSocialKey={social}
                          accountCount={formatAmountBySocial[social]}
                          onClickBox={() => handleNavigateToDetailSocial(social)}
                        />
                      )
                    })}
                </div>
              </div>
            )}

            {shouldRender && (
              <div className={styles['social-page__body__section']}>
                <Typography className={styles['social-page__body__section__title']}>
                  Add {channelConnectedCount > 0 && 'More'} Networks
                </Typography>
                <div className={styles.wrapping}>
                  {Object.keys(formatAmountBySocial)
                    .filter((social) => formatAmountBySocial[social] === 0)
                    .map((social: any) => {
                      return (
                        <SocialBox
                          name={social}
                          imageSocialKey={social}
                          isAddAccount
                          onAddAccount={() => handleAddSocialAccount(social)}
                        />
                      )
                    })}
                </div>
              </div>
            )}

            <div className={styles['social-page__body__section']}>
              <Typography className={styles['social-page__body__section__title']}>App Intergrations</Typography>

              <div className={styles['social-page__body__appIntergrate']}>
                {appIntergration.map((app) => {
                  return (
                    <div
                      className={styles['social-page__body__appIntergrate__item']}
                      onClick={() => handleNavigateToDetailAppIntegration(app.name)}>
                      <div className={styles['social-page__body__appIntergrate__item__info']}>
                        <img src={app.logo} alt={`${app.name} logo`} />
                        <div>
                          <Typography className={styles['social-page__body__appIntergrate__item__info__name']}>
                            {app.name}
                          </Typography>
                          <Tooltip title={app.description}>
                            <Typography className={styles['social-page__body__appIntergrate__item__info__description']}>
                              {app.description}
                            </Typography>
                          </Tooltip>
                        </div>
                      </div>
                      {app.comingSoon && (
                        <div className={styles['social-page__body__appIntergrate__item__bottom']}>Coming Soon</div>
                      )}
                      {!app.comingSoon && (
                        <div
                          className={styles['social-page__body__appIntergrate__item__bottom__connected']}
                          onClick={(e) => {
                            e.stopPropagation()
                            app.connected ? app.functions?.hubspotMapping() : app.functions?.connect()
                          }}>
                          {app.connected ? 'Hubspot Field Mapping' : 'Connect'}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}

        {currentDetailSocial === 'telegram' && telegramTableData?.length > 0 && (
          <SocialAccountTable
            data={telegramTableData}
            platform='telegram'
            onAddAccount={handleAddTelegramAccount}
            onEditAccount={handleEditTelegramAccount}
            setOpenQrcodPage={handleGetTelegramCode}
            handleOutreachEdit={handleOutreachEdit}
          />
        )}

        {currentDetailSocial === 'linkedin' && linkedinTableData?.length > 0 && (
          <SocialAccountTable
            data={linkedinTableData}
            platform='linkedin'
            onAddAccount={() => handleOpenSocialConnectionModal('linkedin')}
            onEditAccount={() => {}}
          />
        )}

        {currentDetailSocial === 'Hubspot' && <AppIntegration setCurrentDetailSocial={setCurrentDetailSocial} />}
      </div>
      {/* {openAddToMappingListModal && (
        <HubspotFieldMappingModal
          open={openAddToMappingListModal}
          onClose={() => {
            setOpenAddToMappingListModal(false)
          }}
          items={newItems}
        />
      )} */}
      {openQrcodPage && (
        <Modal
          open={openQrcodPage}
          onCancel={() => setOpenQrcodPage(false)}
          centered
          okButtonProps={{
            className: 'hidden',
          }}
          cancelButtonProps={{
            className: 'hidden',
          }}
          // min-width={1050}
          // maskClosable={false}
          className={styles.modal_outreach_connection}
          bodyStyle={{
            height: 590,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <OnBoardingQrcodPage
            areaCode={phoneNumber.area_code}
            phoneNumber={phoneNumber.number}
            handleGetChatLoginCode={() => {}}
            closeIcon={false}
          />
        </Modal>
      )}
      {outreachConnectionModalOpen && (
        <OutreachConnection
          open={outreachConnectionModalOpen}
          onClose={() => {
            setOutreachConnectionModalOpen(false)
            setPolling(false)
          }}
        />
      )}
      {openChangeAccountInfo && (
        <ChangeUserInfoModal
          open={openChangeAccountInfo}
          onCancel={() => {
            setOpenChangeAccountInfo(false)
          }}
          phoneNumber={phoneNumber}
          setCheckLocalStorage={setCheckLocalStorage}
        />
      )}
      {openSocialConnectionModal && (
        <SocialConnectionModal
          open={openSocialConnectionModal}
          onClose={() => setOpenSocialConnectionModal(false)}
          platform={platform}
          setCheckLocalStorage={setCheckLocalStorage}
        />
      )}
    </>
  )
}

export default SocialSettings
