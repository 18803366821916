export const countriesWithPhonePrefix = [
  {
    label: 'United States',
    value: 'United States',
    phonePrefix: '1',
    countryCode: 'US',
  },
  {
    label: 'United Kingdom',
    value: 'United Kingdom',
    phonePrefix: '44',
    countryCode: 'GB',
  },
  {
    label: 'India',
    value: 'India',
    phonePrefix: '91',
    countryCode: 'IN',
  },
  {
    label: 'Canada',
    value: 'Canada',
    phonePrefix: '1',
    countryCode: 'CA',
  },
  {
    label: 'France',
    value: 'France',
    phonePrefix: '33',
    countryCode: 'FR',
  },
  {
    label: 'Singapore',
    value: 'Singapore',
    phonePrefix: '65',
    countryCode: 'SG',
  },
  {
    label: 'Germany',
    value: 'Germany',
    phonePrefix: '49',
    countryCode: 'DE',
  },
  {
    label: 'Switzerland',
    value: 'Switzerland',
    phonePrefix: '41',
    countryCode: 'CH',
  },
  {
    label: 'Spain',
    value: 'Spain',
    phonePrefix: '34',
    countryCode: 'ES',
  },
  {
    label: 'United Arab Emirates',
    value: 'United Arab Emirates',
    phonePrefix: '971',
    countryCode: 'AE',
  },
  {
    label: 'Netherlands',
    value: 'Netherlands',
    phonePrefix: '31',
    countryCode: 'NL',
  },
  {
    label: 'Australia',
    value: 'Australia',
    phonePrefix: '61',
    countryCode: 'AU',
  },
  {
    label: 'Italy',
    value: 'Italy',
    phonePrefix: '39',
    countryCode: 'IT',
  },
  {
    label: 'Hong Kong',
    value: 'Hong Kong',
    phonePrefix: '852',
    countryCode: 'HK',
  },

  {
    label: 'Algeria',
    value: 'Algeria',
    phonePrefix: '213',
    countryCode: 'DZ',
  },

  {
    label: 'Andorra',
    value: 'Andorra',
    phonePrefix: '376',
    countryCode: 'AD',
  },
  {
    label: 'Angola',
    value: 'Angola',
    phonePrefix: '244',
    countryCode: 'AO',
  },
  {
    label: 'Anguilla',
    value: 'Anguilla',
    phonePrefix: '1-264',
    countryCode: 'AI',
  },
  {
    label: 'Antarctica',
    value: 'Antarctica',
    phonePrefix: '672',
    countryCode: 'AQ',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
    phonePrefix: '1-268',
    countryCode: 'AG',
  },
  {
    label: 'Argentina',
    value: 'Argentina',
    phonePrefix: '54',
    countryCode: 'AR',
  },
  {
    label: 'Armenia',
    value: 'Armenia',
    phonePrefix: '374',
    countryCode: 'AM',
  },
  {
    label: 'Aruba',
    value: 'Aruba',
    phonePrefix: '297',
    countryCode: 'AW',
  },

  {
    label: 'Austria',
    value: 'Austria',
    phonePrefix: '43',
    countryCode: 'AT',
  },
  {
    label: 'Azerbaijan',
    value: 'Azerbaijan',
    phonePrefix: '994',
    countryCode: 'AZ',
  },
  {
    label: 'Bahamas',
    value: 'Bahamas',
    phonePrefix: '1-242',
    countryCode: 'BS',
  },
  {
    label: 'Bahrain',
    value: 'Bahrain',
    phonePrefix: '973',
    countryCode: 'BH',
  },
  {
    label: 'Bangladesh',
    value: 'Bangladesh',
    phonePrefix: '880',
    countryCode: 'BD',
  },
  {
    label: 'Barbados',
    value: 'Barbados',
    phonePrefix: '1-246',
    countryCode: 'BB',
  },
  {
    label: 'Belarus',
    value: 'Belarus',
    phonePrefix: '375',
    countryCode: 'BY',
  },
  {
    label: 'Belgium',
    value: 'Belgium',
    phonePrefix: '32',
    countryCode: 'BE',
  },
  {
    label: 'Belize',
    value: 'Belize',
    phonePrefix: '501',
    countryCode: 'BZ',
  },
  {
    label: 'Benin',
    value: 'Benin',
    phonePrefix: '229',
    countryCode: 'BJ',
  },
  {
    label: 'Bermuda',
    value: 'Bermuda',
    phonePrefix: '1-441',
    countryCode: 'BM',
  },
  {
    label: 'Bhutan',
    value: 'Bhutan',
    phonePrefix: '975',
    countryCode: 'BT',
  },
  {
    label: 'Bolivia',
    value: 'Bolivia',
    phonePrefix: '591',
    countryCode: 'BO',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
    phonePrefix: '387',
    countryCode: 'BA',
  },
  {
    label: 'Botswana',
    value: 'Botswana',
    phonePrefix: '267',
    countryCode: 'BW',
  },
  {
    label: 'Brazil',
    value: 'Brazil',
    phonePrefix: '55',
    countryCode: 'BR',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory',
    phonePrefix: '246',
    countryCode: 'IO',
  },
  {
    label: 'British Virgin Islands',
    value: 'British Virgin Islands',
    phonePrefix: '1-284',
    countryCode: 'VG',
  },
  {
    label: 'Brunei',
    value: 'Brunei',
    phonePrefix: '673',
    countryCode: 'BN',
  },
  {
    label: 'Bulgaria',
    value: 'Bulgaria',
    phonePrefix: '359',
    countryCode: 'BG',
  },
  {
    label: 'Burkina Faso',
    value: 'Burkina Faso',
    phonePrefix: '226',
    countryCode: 'BF',
  },
  {
    label: 'Burundi',
    value: 'Burundi',
    phonePrefix: '257',
    countryCode: 'BI',
  },
  {
    label: 'Cambodia',
    value: 'Cambodia',
    phonePrefix: '855',
    countryCode: 'KH',
  },
  {
    label: 'Cameroon',
    value: 'Cameroon',
    phonePrefix: '237',
    countryCode: 'CM',
  },

  {
    label: 'Cape Verde',
    value: 'Cape Verde',
    phonePrefix: '238',
    countryCode: 'CV',
  },
  {
    label: 'Cayman Islands',
    value: 'Cayman Islands',
    phonePrefix: '1-345',
    countryCode: 'KY',
  },
  {
    label: 'Central African Republic',
    value: 'Central African Republic',
    phonePrefix: '236',
    countryCode: 'CF',
  },
  {
    label: 'Chad',
    value: 'Chad',
    phonePrefix: '235',
    countryCode: 'TD',
  },
  {
    label: 'Chile',
    value: 'Chile',
    phonePrefix: '56',
    countryCode: 'CL',
  },
  {
    label: 'China',
    value: 'China',
    phonePrefix: '86',
    countryCode: 'CN',
  },
  {
    label: 'Colombia',
    value: 'Colombia',
    phonePrefix: '57',
    countryCode: 'CO',
  },
  {
    label: 'Comoros',
    value: 'Comoros',
    phonePrefix: '269',
    countryCode: 'KM',
  },
  {
    label: 'Cook Islands',
    value: 'Cook Islands',
    phonePrefix: '682',
    countryCode: 'CK',
  },
  {
    label: 'Costa Rica',
    value: 'Costa Rica',
    phonePrefix: '506',
    countryCode: 'CR',
  },
  {
    label: 'Croatia',
    value: 'Croatia',
    phonePrefix: '385',
    countryCode: 'HR',
  },
  {
    label: 'Cuba',
    value: 'Cuba',
    phonePrefix: '53',
    countryCode: 'CU',
  },
  {
    label: 'Curacao',
    value: 'Curacao',
    phonePrefix: '599',
    countryCode: 'CW',
  },
  {
    label: 'Cyprus',
    value: 'Cyprus',
    phonePrefix: '357',
    countryCode: 'CY',
  },
  {
    label: 'Czech Republic',
    value: 'Czech Republic',
    phonePrefix: '420',
    countryCode: 'CZ',
  },
  {
    label: 'Democratic Republic of the Congo',
    value: 'Democratic Republic of the Congo',
    phonePrefix: '243',
    countryCode: 'CD',
  },
  {
    label: 'Denmark',
    value: 'Denmark',
    phonePrefix: '45',
    countryCode: 'DK',
  },
  {
    label: 'Djibouti',
    value: 'Djibouti',
    phonePrefix: '253',
    countryCode: 'DJ',
  },
  {
    label: 'Dominica',
    value: 'Dominica',
    phonePrefix: '1-767',
    countryCode: 'DM',
  },
  {
    label: 'Dominican Republic',
    value: 'Dominican Republic',
    phonePrefix: '1-809',
    countryCode: 'DO',
  },
  {
    label: 'East Timor',
    value: 'East Timor',
    phonePrefix: '670',
    countryCode: 'TL',
  },
  {
    label: 'Ecuador',
    value: 'Ecuador',
    phonePrefix: '593',
    countryCode: 'EC',
  },
  {
    label: 'Egypt',
    value: 'Egypt',
    phonePrefix: '20',
    countryCode: 'EG',
  },
  {
    label: 'El Salvador',
    value: 'El Salvador',
    phonePrefix: '503',
    countryCode: 'SV',
  },
  {
    label: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
    phonePrefix: '240',
    countryCode: 'GQ',
  },
  {
    label: 'Eritrea',
    value: 'Eritrea',
    phonePrefix: '291',
    countryCode: 'ER',
  },
  {
    label: 'Estonia',
    value: 'Estonia',
    phonePrefix: '372',
    countryCode: 'EE',
  },
  {
    label: 'Ethiopia',
    value: 'Ethiopia',
    phonePrefix: '251',
    countryCode: 'ET',
  },
  {
    label: 'Falkland Islands',
    value: 'Falkland Islands',
    phonePrefix: '500',
    countryCode: 'FK',
  },
  {
    label: 'Faroe Islands',
    value: 'Faroe Islands',
    phonePrefix: '298',
    countryCode: 'FO',
  },
  {
    label: 'Fiji',
    value: 'Fiji',
    phonePrefix: '679',
    countryCode: 'FJ',
  },
  {
    label: 'Finland',
    value: 'Finland',
    phonePrefix: '358',
    countryCode: 'FI',
  },

  {
    label: 'French Polynesia',
    value: 'French Polynesia',
    phonePrefix: '689',
    countryCode: 'PF',
  },
  {
    label: 'Gabon',
    value: 'Gabon',
    phonePrefix: '241',
    countryCode: 'GA',
  },
  {
    label: 'Gambia',
    value: 'Gambia',
    phonePrefix: '220',
    countryCode: 'GM',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
    phonePrefix: '995',
    countryCode: 'GE',
  },

  {
    label: 'Ghana',
    value: 'Ghana',
    phonePrefix: '233',
    countryCode: 'GH',
  },
  {
    label: 'Gibraltar',
    value: 'Gibraltar',
    phonePrefix: '350',
    countryCode: 'GI',
  },
  {
    label: 'Greece',
    value: 'Greece',
    phonePrefix: '30',
    countryCode: 'GR',
  },
  {
    label: 'Greenland',
    value: 'Greenland',
    phonePrefix: '299',
    countryCode: 'GL',
  },
  {
    label: 'Grenada',
    value: 'Grenada',
    phonePrefix: '1-473',
    countryCode: 'GD',
  },
  {
    label: 'Guam',
    value: 'Guam',
    phonePrefix: '1-671',
    countryCode: 'GU',
  },
  {
    label: 'Guatemala',
    value: 'Guatemala',
    phonePrefix: '502',
    countryCode: 'GT',
  },
  {
    label: 'Guernsey',
    value: 'Guernsey',
    phonePrefix: '44-1481',
    countryCode: 'GG',
  },
  {
    label: 'Guinea',
    value: 'Guinea',
    phonePrefix: '224',
    countryCode: 'GN',
  },
  {
    label: 'Guinea-Bissau',
    value: 'Guinea-Bissau',
    phonePrefix: '245',
    countryCode: 'GW',
  },
  {
    label: 'Guyana',
    value: 'Guyana',
    phonePrefix: '592',
    countryCode: 'GY',
  },
  {
    label: 'Haiti',
    value: 'Haiti',
    phonePrefix: '509',
    countryCode: 'HT',
  },
  {
    label: 'Honduras',
    value: 'Honduras',
    phonePrefix: '504',
    countryCode: 'HN',
  },

  {
    label: 'Hungary',
    value: 'Hungary',
    phonePrefix: '36',
    countryCode: 'HU',
  },
  {
    label: 'Iceland',
    value: 'Iceland',
    phonePrefix: '354',
    countryCode: 'IS',
  },

  {
    label: 'Indonesia',
    value: 'Indonesia',
    phonePrefix: '62',
    countryCode: 'ID',
  },
  { label: 'Iran', value: 'Iran', phonePrefix: '98', countryCode: 'IR' },
  {
    label: 'Iraq',
    value: 'Iraq',
    phonePrefix: '964',
    countryCode: 'IQ',
  },
  {
    label: 'Ireland',
    value: 'Ireland',
    phonePrefix: '353',
    countryCode: 'IE',
  },
  {
    label: 'Isle of Man',
    value: 'Isle of Man',
    phonePrefix: '44-1624',
    countryCode: 'IM',
  },
  {
    label: 'Israel',
    value: 'Israel',
    phonePrefix: '972',
    countryCode: 'IL',
  },

  {
    label: 'Ivory Coast',
    value: 'Ivory Coast',
    phonePrefix: '225',
    countryCode: 'CI',
  },
  {
    label: 'Jamaica',
    value: 'Jamaica',
    phonePrefix: '1-876',
    countryCode: 'JM',
  },
  {
    label: 'Japan',
    value: 'Japan',
    phonePrefix: '81',
    countryCode: 'JP',
  },
  {
    label: 'Jersey',
    value: 'Jersey',
    phonePrefix: '44-1534',
    countryCode: 'JE',
  },
  {
    label: 'Jordan',
    value: 'Jordan',
    phonePrefix: '962',
    countryCode: 'JO',
  },
  {
    label: 'Kazakhstan',
    value: 'Kazakhstan',
    phonePrefix: '7',
    countryCode: 'KZ',
  },
  {
    label: 'Kenya',
    value: 'Kenya',
    phonePrefix: '254',
    countryCode: 'KE',
  },
  {
    label: 'Kiribati',
    value: 'Kiribati',
    phonePrefix: '686',
    countryCode: 'KI',
  },
  {
    label: 'Kosovo',
    value: 'Kosovo',
    phonePrefix: '383',
    countryCode: 'XK',
  },
  {
    label: 'Kuwait',
    value: 'Kuwait',
    phonePrefix: '965',
    countryCode: 'KW',
  },
  {
    label: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
    phonePrefix: '996',
    countryCode: 'KG',
  },
  {
    label: 'Laos',
    value: 'Laos',
    phonePrefix: '856',
    countryCode: 'LA',
  },
  {
    label: 'Latvia',
    value: 'Latvia',
    phonePrefix: '371',
    countryCode: 'LV',
  },
  {
    label: 'Lebanon',
    value: 'Lebanon',
    phonePrefix: '961',
    countryCode: 'LB',
  },
  {
    label: 'Lesotho',
    value: 'Lesotho',
    phonePrefix: '266',
    countryCode: 'LS',
  },
  {
    label: 'Liberia',
    value: 'Liberia',
    phonePrefix: '231',
    countryCode: 'LR',
  },
  {
    label: 'Libya',
    value: 'Libya',
    phonePrefix: '218',
    countryCode: 'LY',
  },
  {
    label: 'Liechtenstein',
    value: 'Liechtenstein',
    phonePrefix: '423',
    countryCode: 'LI',
  },
  {
    label: 'Lithuania',
    value: 'Lithuania',
    phonePrefix: '370',
    countryCode: 'LT',
  },
  {
    label: 'Luxembourg',
    value: 'Luxembourg',
    phonePrefix: '352',
    countryCode: 'LU',
  },
  {
    label: 'Macao',
    value: 'Macao',
    phonePrefix: '853',
    countryCode: 'MO',
  },
  {
    label: 'Macedonia',
    value: 'Macedonia',
    phonePrefix: '389',
    countryCode: 'MK',
  },
  {
    label: 'Madagascar',
    value: 'Madagascar',
    phonePrefix: '261',
    countryCode: 'MG',
  },
  {
    label: 'Malawi',
    value: 'Malawi',
    phonePrefix: '265',
    countryCode: 'MW',
  },
  {
    label: 'Malaysia',
    value: 'Malaysia',
    phonePrefix: '60',
    countryCode: 'MY',
  },
  {
    label: 'Maldives',
    value: 'Maldives',
    phonePrefix: '960',
    countryCode: 'MV',
  },
  {
    label: 'Mali',
    value: 'Mali',
    phonePrefix: '223',
    countryCode: 'ML',
  },
  {
    label: 'Malta',
    value: 'Malta',
    phonePrefix: '356',
    countryCode: 'MT',
  },
  {
    label: 'Marshall Islands',
    value: 'Marshall Islands',
    phonePrefix: '692',
    countryCode: 'MH',
  },
  {
    label: 'Mauritania',
    value: 'Mauritania',
    phonePrefix: '222',
    countryCode: 'MR',
  },
  {
    label: 'Mauritius',
    value: 'Mauritius',
    phonePrefix: '230',
    countryCode: 'MU',
  },
  {
    label: 'Mayotte',
    value: 'Mayotte',
    phonePrefix: '262',
    countryCode: 'YT',
  },
  {
    label: 'Mexico',
    value: 'Mexico',
    phonePrefix: '52',
    countryCode: 'MX',
  },
  {
    label: 'Micronesia',
    value: 'Micronesia',
    phonePrefix: '691',
    countryCode: 'FM',
  },
  {
    label: 'Moldova',
    value: 'Moldova',
    phonePrefix: '373',
    countryCode: 'MD',
  },
  {
    label: 'Monaco',
    value: 'Monaco',
    phonePrefix: '377',
    countryCode: 'MC',
  },
  {
    label: 'Mongolia',
    value: 'Mongolia',
    phonePrefix: '976',
    countryCode: 'MN',
  },
  {
    label: 'Montenegro',
    value: 'Montenegro',
    phonePrefix: '382',
    countryCode: 'ME',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
    phonePrefix: '1-664',
    countryCode: 'MS',
  },
  {
    label: 'Morocco',
    value: 'Morocco',
    phonePrefix: '212',
    countryCode: 'MA',
  },
  {
    label: 'Mozambique',
    value: 'Mozambique',
    phonePrefix: '258',
    countryCode: 'MZ',
  },
  {
    label: 'Myanmar',
    value: 'Myanmar',
    phonePrefix: '95',
    countryCode: 'MM',
  },
  {
    label: 'Namibia',
    value: 'Namibia',
    phonePrefix: '264',
    countryCode: 'NA',
  },
  {
    label: 'Nauru',
    value: 'Nauru',
    phonePrefix: '674',
    countryCode: 'NR',
  },
  {
    label: 'Nepal',
    value: 'Nepal',
    phonePrefix: '977',
    countryCode: 'NP',
  },

  {
    label: 'Netherlands Antilles',
    value: 'Netherlands Antilles',
    phonePrefix: '599',
    countryCode: 'AN',
  },
  {
    label: 'New Caledonia',
    value: 'New Caledonia',
    phonePrefix: '687',
    countryCode: 'NC',
  },
  {
    label: 'New Zealand',
    value: 'New Zealand',
    phonePrefix: '64',
    countryCode: 'NZ',
  },
  {
    label: 'Nicaragua',
    value: 'Nicaragua',
    phonePrefix: '505',
    countryCode: 'NI',
  },
  {
    label: 'Niger',
    value: 'Niger',
    phonePrefix: '227',
    countryCode: 'NE',
  },
  {
    label: 'Nigeria',
    value: 'Nigeria',
    phonePrefix: '234',
    countryCode: 'NG',
  },
  {
    label: 'Niue',
    value: 'Niue',
    phonePrefix: '683',
    countryCode: 'NU',
  },
  {
    label: 'North Korea',
    value: 'North Korea',
    phonePrefix: '850',
    countryCode: 'KP',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
    phonePrefix: '1-670',
    countryCode: 'MP',
  },
  {
    label: 'Norway',
    value: 'Norway',
    phonePrefix: '47',
    countryCode: 'NO',
  },
  {
    label: 'Oman',
    value: 'Oman',
    phonePrefix: '968',
    countryCode: 'OM',
  },
  {
    label: 'Pakistan',
    value: 'Pakistan',
    phonePrefix: '92',
    countryCode: 'PK',
  },
  {
    label: 'Palau',
    value: 'Palau',
    phonePrefix: '680',
    countryCode: 'PW',
  },
  {
    label: 'Palestine',
    value: 'Palestine',
    phonePrefix: '970',
    countryCode: 'PS',
  },
  {
    label: 'Panama',
    value: 'Panama',
    phonePrefix: '507',
    countryCode: 'PA',
  },
  {
    label: 'Papua New Guinea',
    value: 'Papua New Guinea',
    phonePrefix: '675',
    countryCode: 'PG',
  },
  {
    label: 'Paraguay',
    value: 'Paraguay',
    phonePrefix: '595',
    countryCode: 'PY',
  },
  {
    label: 'Peru',
    value: 'Peru',
    phonePrefix: '51',
    countryCode: 'PE',
  },
  {
    label: 'Philippines',
    value: 'Philippines',
    phonePrefix: '63',
    countryCode: 'PH',
  },
  {
    label: 'Pitcairn',
    value: 'Pitcairn',
    phonePrefix: '64',
    countryCode: 'PN',
  },
  {
    label: 'Poland',
    value: 'Poland',
    phonePrefix: '48',
    countryCode: 'PL',
  },
  {
    label: 'Portugal',
    value: 'Portugal',
    phonePrefix: '351',
    countryCode: 'PT',
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico',
    phonePrefix: '1-787',
    countryCode: 'PR',
  },
  {
    label: 'Qatar',
    value: 'Qatar',
    phonePrefix: '974',
    countryCode: 'QA',
  },
  {
    label: 'Republic of the Congo',
    value: 'Republic of the Congo',
    phonePrefix: '242',
    countryCode: 'CG',
  },
  {
    label: 'Reunion',
    value: 'Reunion',
    phonePrefix: '262',
    countryCode: 'RE',
  },
  {
    label: 'Romania',
    value: 'Romania',
    phonePrefix: '40',
    countryCode: 'RO',
  },
  {
    label: 'Russia',
    value: 'Russia',
    phonePrefix: '7',
    countryCode: 'RU',
  },
  {
    label: 'Rwanda',
    value: 'Rwanda',
    phonePrefix: '250',
    countryCode: 'RW',
  },
  {
    label: 'Saint Helena',
    value: 'Saint Helena',
    phonePrefix: '290',
    countryCode: 'SH',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
    phonePrefix: '1-869',
    countryCode: 'KN',
  },
  {
    label: 'Saint Lucia',
    value: 'Saint Lucia',
    phonePrefix: '1-758',
    countryCode: 'LC',
  },
  {
    label: 'Saint Martin',
    value: 'Saint Martin',
    phonePrefix: '590',
    countryCode: 'MF',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'Saint Pierre and Miquelon',
    phonePrefix: '508',
    countryCode: 'PM',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
    phonePrefix: '1-784',
    countryCode: 'VC',
  },
  {
    label: 'Samoa',
    value: 'Samoa',
    phonePrefix: '685',
    countryCode: 'WS',
  },
  {
    label: 'San Marino',
    value: 'San Marino',
    phonePrefix: '378',
    countryCode: 'SM',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
    phonePrefix: '239',
    countryCode: 'ST',
  },
  {
    label: 'Saudi Arabia',
    value: 'Saudi Arabia',
    phonePrefix: '966',
    countryCode: 'SA',
  },
  {
    label: 'Senegal',
    value: 'Senegal',
    phonePrefix: '221',
    countryCode: 'SN',
  },
  {
    label: 'Serbia',
    value: 'Serbia',
    phonePrefix: '381',
    countryCode: 'RS',
  },
  {
    label: 'Seychelles',
    value: 'Seychelles',
    phonePrefix: '248',
    countryCode: 'SC',
  },
  {
    label: 'Sierra Leone',
    value: 'Sierra Leone',
    phonePrefix: '232',
    countryCode: 'SL',
  },

  {
    label: 'Sint Maarten',
    value: 'Sint Maarten',
    phonePrefix: '1-721',
    countryCode: 'SX',
  },
  {
    label: 'Slovakia',
    value: 'Slovakia',
    phonePrefix: '421',
    countryCode: 'SK',
  },
  {
    label: 'Slovenia',
    value: 'Slovenia',
    phonePrefix: '386',
    countryCode: 'SI',
  },
  {
    label: 'Solomon Islands',
    value: 'Solomon Islands',
    phonePrefix: '677',
    countryCode: 'SB',
  },
  {
    label: 'Somalia',
    value: 'Somalia',
    phonePrefix: '252',
    countryCode: 'SO',
  },
  {
    label: 'South Africa',
    value: 'South Africa',
    phonePrefix: '27',
    countryCode: 'ZA',
  },
  {
    label: 'South Korea',
    value: 'South Korea',
    phonePrefix: '82',
    countryCode: 'KR',
  },
  {
    label: 'South Sudan',
    value: 'South Sudan',
    phonePrefix: '211',
    countryCode: 'SS',
  },

  {
    label: 'Sri Lanka',
    value: 'Sri Lanka',
    phonePrefix: '94',
    countryCode: 'LK',
  },
  {
    label: 'Sudan',
    value: 'Sudan',
    phonePrefix: '249',
    countryCode: 'SD',
  },
  {
    label: 'Surilabel',
    value: 'Surilabel',
    phonePrefix: '597',
    countryCode: 'SR',
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'Svalbard and Jan Mayen',
    phonePrefix: '47',
    countryCode: 'SJ',
  },
  {
    label: 'Swaziland',
    value: 'Swaziland',
    phonePrefix: '268',
    countryCode: 'SZ',
  },
  {
    label: 'Sweden',
    value: 'Sweden',
    phonePrefix: '46',
    countryCode: 'SE',
  },

  {
    label: 'Syria',
    value: 'Syria',
    phonePrefix: '963',
    countryCode: 'SY',
  },
  {
    label: 'Taiwan',
    value: 'Taiwan',
    phonePrefix: '886',
    countryCode: 'TW',
  },
  {
    label: 'Tajikistan',
    value: 'Tajikistan',
    phonePrefix: '992',
    countryCode: 'TJ',
  },
  {
    label: 'Tanzania',
    value: 'Tanzania',
    phonePrefix: '255',
    countryCode: 'TZ',
  },
  {
    label: 'Thailand',
    value: 'Thailand',
    phonePrefix: '66',
    countryCode: 'TH',
  },
  {
    label: 'Togo',
    value: 'Togo',
    phonePrefix: '228',
    countryCode: 'TG',
  },
  {
    label: 'Tokelau',
    value: 'Tokelau',
    phonePrefix: '690',
    countryCode: 'TK',
  },
  {
    label: 'Tonga',
    value: 'Tonga',
    phonePrefix: '676',
    countryCode: 'TO',
  },
  {
    label: 'Tunisia',
    value: 'Tunisia',
    phonePrefix: '216',
    countryCode: 'TN',
  },
  {
    label: 'Turkey',
    value: 'Turkey',
    phonePrefix: '90',
    countryCode: 'TR',
  },
  {
    label: 'Turkmenistan',
    value: 'Turkmenistan',
    phonePrefix: '993',
    countryCode: 'TM',
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'Turks and Caicos Islands',
    phonePrefix: '1-649',
    countryCode: 'TC',
  },
  {
    label: 'Tuvalu',
    value: 'Tuvalu',
    phonePrefix: '688',
    countryCode: 'TV',
  },
  {
    label: 'U.S. Virgin Islands',
    value: 'U.S. Virgin Islands',
    phonePrefix: '1-340',
    countryCode: 'VI',
  },
  {
    label: 'Uganda',
    value: 'Uganda',
    phonePrefix: '256',
    countryCode: 'UG',
  },
  {
    label: 'Ukraine',
    value: 'Ukraine',
    phonePrefix: '380',
    countryCode: 'UA',
  },

  {
    label: 'Uruguay',
    value: 'Uruguay',
    phonePrefix: '598',
    countryCode: 'UY',
  },
  {
    label: 'Uzbekistan',
    value: 'Uzbekistan',
    phonePrefix: '998',
    countryCode: 'UZ',
  },
  {
    label: 'Vanuatu',
    value: 'Vanuatu',
    phonePrefix: '678',
    countryCode: 'VU',
  },
  {
    label: 'Vatican',
    value: 'Vatican',
    phonePrefix: '379',
    countryCode: 'VA',
  },
  {
    label: 'Venezuela',
    value: 'Venezuela',
    phonePrefix: '58',
    countryCode: 'VE',
  },
  {
    label: 'Vietnam',
    value: 'Vietnam',
    phonePrefix: '84',
    countryCode: 'VN',
  },
  {
    label: 'Wallis and Futuna',
    value: 'Wallis and Futuna',
    phonePrefix: '681',
    countryCode: 'WF',
  },
  {
    label: 'Western Sahara',
    value: 'Western Sahara',
    phonePrefix: '212',
    countryCode: 'EH',
  },
  {
    label: 'Yemen',
    value: 'Yemen',
    phonePrefix: '967',
    countryCode: 'YE',
  },
  {
    label: 'Zambia',
    value: 'Zambia',
    phonePrefix: '260',
    countryCode: 'ZM',
  },
  {
    label: 'Zimbabwe',
    value: 'Zimbabwe',
    phonePrefix: '263',
    countryCode: 'ZW',
  },
  {
    label: 'Afghanistan',
    value: 'Afghanistan',
    phonePrefix: '93',
    countryCode: 'AF',
  },
  {
    label: 'Albania',
    value: 'Albania',
    phonePrefix: '355',
    countryCode: 'AL',
  },
  {
    label: 'American Samoa',
    value: 'American Samoa',
    phonePrefix: '1-684',
    countryCode: 'AS',
  },
]
