import React, { useEffect, useState, SyntheticEvent } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Button, Input, Modal, message, Alert, Space, Popconfirm, Col } from 'antd'
import classnames from 'classnames'
import FancyDrawer from 'common/components/fancy-drawer/fancy-drawer'
import FancyTable from 'common/components/fancy-table/fancy-table'
import c_linkedin from 'common/assets/svg/c_linkedin.svg'
import c_twitter from 'common/assets/svg/c_twitter.svg'
import briefcase from 'common/assets/svg/briefcase.svg'
import location from 'common/assets/svg/location.svg'
import { ConfirmBox } from 'common/constants/modal.constants'
import { useDisclaimer } from 'common/hooks/useDisclaimer'

import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'

import {
  useGetContactMutation,
  useGetForCompaniesListMutation,
  useLazyGetTelegramContactsQuery,
} from 'features/contacts/state/api/ContactsApi'

import { useLazyGetLoginHubSpotQuery, useLazyGetPointsStoreQuery } from '../settings/state/api/SettingsApi'

import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_COMPANY_ID,
  // SET_SELECTED_CONTACTS,
  SET_SELECTED_CONTACTS,
  SET_LAST_ACTION_PAGE_OUTREAH,
} from 'features/my-contact/state/slice/contactsSlice'
import {
  useGetCompanyMutation,
  useLazyAddNewListQuery,
  useLazyGetListsQuery,
  useLazyUpdateListQuery,
} from '../intent-signals/state/api/CompaniesApi'
import { injectionRegex } from '../../common/static-data/userData'
import { MESSAGE_ERROR } from '../../common/constants/messages.constants'
import { useConnectPlus } from 'common/providers/ConnectPlusProvider'
import { useAuth } from 'common/hooks/useAuth.hooks'
import BlurPaywall from 'features/intent-signals/components/paywalls/BlurPaywall'
import CompanyDetails from 'features/intent-signals/components/company-details/company-details'
import ErrorMessage from 'features/intent-signals/components/ErrorMessage'
import { outreach } from 'common/constants/outreach.constants'

import styles from './contacts.module.scss'
import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'
import AreYouSureModal from 'common/components/General/AreYouSureModal'
import { useLazyGetCampaignsRecipientsQuery, useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import { useSetExposeMutation } from 'features/contacts/state/api/ContactsApi'
import ContactsActions from 'features/contacts/components/ContactsActions'
import CircularImage from 'features/contacts/components/contact-details/CircularImage'
import ContactDetails from 'features/contacts/components/contact-details/contact-details'
import { SET_LISTS } from 'features/intent-signals/state/slice/companiesSlice'
import { contactsTableColumn } from './constants/contactTable'
import { ContactTableAction } from './components/ContactTableAction'
import { isArray } from 'lodash'
import classNames from 'classnames'

const MyContact = ({
  additionalColumns = [],
  contacts,
  refetchGetContacts,
  contactsError,
  isLoading,
  currentList,
  tableParams,
  handleChangeTableParamsContact,
}: {
  additionalColumns?: any[]
  setCounts?: any
  contacts: any
  refetchGetContacts: any
  contactsError: any
  isLoading: any
  currentList: any
  tableParams: any
  handleChangeTableParamsContact: any
}) => {
  const [userPoints, setUserPoints] = useState<any>()
  const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [openBanner] = useState(true)
  const [popconfirmLinkedin, setPopconfirmLinkedin] = useState(false)
  const [popconfirmTwitter, setPopconfirmTwitter] = useState(false)
  const [limitOpenOutOfCreditsModal, setLimitOpenOutOfCreditsModal] = useState('points')
  const [captionCreditsModal, setCaptionCreditsModal] = useState('')
  const [getCampaignNames, { data: allCampaignsAccount }] = useLazyGetCampaignsRecipientsQuery()
  const { showDisclaimer, toggleShowDisclaimer } = useDisclaimer()

  const [showAlert, setShowAlert] = useState(false)

  const navigate = useNavigate()
  const { userDetails } = useAuth()
  const [userData, setUserData] = useState(userDetails)
  const { selectedId } = useParams()
  const { search, pathname } = useLocation()
  const [errorMessage, setErrorMessage] = useState('')
  const [companyDetailsLoading, setCompanyDetailsLoading] = useState(false)
  const [refetchGetCompany, { data: company }] = useGetCompanyMutation()
  const [outOfCredits, setOutOfCredits] = useState(false)
  const [linkedinAlreadyValue, setLinkedinAlreadyValue] = useState<any>()
  const [twitterAlreadyValue, setTwitterAlreadyValue] = useState<any>()

  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorStatusCode] = useState(0)
  const [showTelegram, setShowTelegram] = useState(false)
  const [telegramResult, setTelegramResult] = useState('')
  const [showCompanyDetails, setShowCompanyDetails] = useState(false)
  const [showContactDetails, setShowContactDetails] = useState(false)
  const [addToListModal, setAddToListModal] = useState(false)
  const [selectedList, setSelectedList] = useState<string>('')
  const [addToListInput, setAddToListInput] = useState(false)

  const [actionsPaywallOpen, setActionsPaywallOpen] = useState(false)
  const [refetchLoginHubSpot] = useLazyGetLoginHubSpotQuery()

  const [openOutOfCreditsModal, setOpenOutOfCreditsModal] = useState(false)
  const [openLoadingErrorModal, setOpenLoadingErrorModal] = useState(false)

  const [refetchGetContact, { data: contact, isLoading: loading }] = useGetContactMutation()

  const [refetchExpose, { data: expose }] = useSetExposeMutation()

  // const [userPoints, setUserPoints] = useState<any>()
  // const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()

  //const [refetchTackedCredits] = useGetAlreadyTackedCreditsMutation()

  const [cookies] = useCookies(['selected_companies'])
  const { setConnectPlusSum, setConnectPlusPictures } = useConnectPlus()

  const { company_lists, lists } = useAppSelector((state) => state.myContactsCompanies)
  const { selected_contacts } = useAppSelector((state) => state.myContacts)

  const [refetchUpdateList] = useLazyUpdateListQuery()
  const [refetchAddList] = useLazyAddNewListQuery()
  const [refetchLists] = useLazyGetListsQuery()
  const [refetchGetForCompaniesList] = useGetForCompaniesListMutation()
  const [refetchGetTelegramContacts] = useLazyGetTelegramContactsQuery()

  const dispatch = useAppDispatch()

  useEffect(() => {
    cookies.selected_companies !== undefined && dispatch(SET_COMPANY_ID(cookies.selected_companies?.toString()))
  }, [cookies])

  useEffect(() => {
    // if (contacts?.totalNumber) handleChangeTableParamsContact({ ...tableParams, total: Number(contacts?.totalNumber) })

    const getTelegram = async () => {
      const { data } = await refetchGetTelegramContacts()

      setConnectPlusSum(data?.totalContacts)
      setConnectPlusPictures(data?.pictures)
    }

    getTelegram()
  }, [])

  useEffect(() => {
    if (!selectedId) return

    const fetchData = async () => {
      setShowContactDetails(true)
      await refetchGetContact(selectedId)
    }

    fetchData()
  }, [selectedId])

  useEffect(() => {
    dispatch(SET_LAST_ACTION_PAGE_OUTREAH(tableParams.current))
  }, [tableParams.current])

  const handleSelectedRows = (rows: any, keys: any) => {
    dispatch(SET_SELECTED_CONTACTS(keys))
  }

  const handleUnSelectRows = (rows: any) => {
    let arrSelectedCompanies = selected_contacts

    for (let i = 0; i < rows.length; i++) {
      arrSelectedCompanies = arrSelectedCompanies?.filter((item) => item !== rows[i]?.index)
    }
    dispatch(SET_SELECTED_CONTACTS(arrSelectedCompanies))
  }

  const handleRemoveRow = (key: string) => {
    const arr = selected_contacts?.filter((e) => e !== key)

    dispatch(SET_SELECTED_CONTACTS(arr))
  }

  const handleAddItemsToList = async () => {
    const list: any = company_lists?.filter((item: any) => item.id === selectedList)

    if (list.length > 0) {
      let ids = ''

      selected_contacts?.forEach((item: any) => {
        if (item && ids.indexOf(item) === -1) {
          ids += item + ','
        }
      })

      const result = await refetchGetForCompaniesList({
        contact_ids: ids,
      })

      let assignedItems = list[0].company_ids ? list[0].company_ids : ''
      for (let index = 0; index < result?.data?.rows?.length; index++) {
        if (assignedItems.indexOf(result?.data?.rows[index]?.company_id) === -1)
          assignedItems += result?.data?.rows[index]?.company_id + ','
      }

      await refetchUpdateList({ id: selectedList, items_list: assignedItems, field_name: 'company_ids' })

      let con_assignedItems = list[0].contact_ids ? list[0].contact_ids : ''

      selected_contacts.forEach((item) => {
        if (con_assignedItems.indexOf(item) === -1) con_assignedItems += item + ','
      })

      await refetchUpdateList({ id: selectedList, items_list: con_assignedItems, field_name: 'contact_ids' })
    }

    let getLists = await refetchLists()
    if (getLists) dispatch(SET_LISTS(getLists.data))
    setAddToListModal(false)
  }

  const handleAddNewItem = async (event: any) => {
    if (13 === event.keyCode) {
      if (injectionRegex.test(event.target.value)) {
        message.error(MESSAGE_ERROR.INVALID_LIST_NAME)
      } else {
        let checkItem = company_lists.find((item: any) => item.name === event.target.value)

        if (!checkItem) {
          let result = await refetchAddList({ name: event.target.value, type: 2 })
          if (result?.error?.status === 470) {
            setOutOfCredits(true)
          } else {
            if (result) {
              let getLists = await refetchLists()

              if (getLists) dispatch(SET_LISTS(getLists.data))

              message.success(`The list '${event.target.value}' was created successfully`)
              setAddToListInput(!addToListInput)
            }
          }
        } else {
          message.success(`We already have a list with the same name!`)
        }
      }
    }
  }

  const convertString = (str: string) => {
    // eslint-disable-next-line
    const parts = str.replace(/[\[\]"'"]/g, '').split(',')
    const converted = parts.map((part: string) => part.trim()).join(' ')
    return converted
  }

  const tags: string[] = []

  if (contact?.response?.tags) {
    const tagsList = JSON.parse(contact?.response?.tags)
    tagsList.forEach((element: any) => {
      element.value !== '' && element.type !== 'name' && tags.push(convertString(element.value))
    })
  }

  useEffect(() => {
    // if (contact?.response?.linkedin_already !== undefined) {
    setLinkedinAlreadyValue(contact?.response?.linkedin_already)
    // }
    // if (contact?.response?.twitter_already !== undefined) {
    setTwitterAlreadyValue(contact?.response?.twitter_already)
    // }
  }, [contact])

  useEffect(() => {
    // setLinkedinAlreadyValue(null)
    // if (expose?.response?.exposeData?.linkedin !== undefined) {
    // setLinkedinAlreadyValue(expose?.response?.exposeData.linkedin)
    // }
    // setTwitterAlreadyValue(null)
    // if (expose?.response?.exposeData?.twitter !== undefined) {
    // setTwitterAlreadyValue(expose?.response?.exposeData.twitter)
    // }
  }, [expose])

  useEffect(() => {
    const getCampaignsData = async () => {
      await getCampaignNames()
    }
    getCampaignsData()
  }, [])

  const onClickLinkedIn = (e: SyntheticEvent) => {
    e.preventDefault()
    if (contact?.response?.linkedin_already) {
      window.open(contact?.response?.linkedin_already, '_blank')
    } else {
      if (linkedinAlreadyValue) window.open(linkedinAlreadyValue, '_blank')
      else setPopconfirmLinkedin(true)
    }
  }

  const handleClickLinkedIn = async () => {
    setPopconfirmLinkedin(false)
    const key = 'linkedin'
    const result = await refetchExpose({ selectedId, key })

    if (result?.error?.status === 470) {
      setOpenOutOfCreditsModal(true)
      setLimitOpenOutOfCreditsModal('limit')
      setCaptionCreditsModal('linkedin')
      // message.error(result?.error.data.message)
    } else {
      setLinkedinAlreadyValue(result.data.response.exposeData.linkedin)
      window.open(result.data.response.exposeData.linkedin, '_blank')
    }
  }

  const onClickTwitter = (e: SyntheticEvent) => {
    e.preventDefault()

    // setPopconfirmTwitter(true)
    if (contact?.response?.twitter_already) {
      window.open(contact?.response?.twitter_already, '_blank')
    } else {
      if (twitterAlreadyValue) window.open(twitterAlreadyValue, '_blank')
      else setPopconfirmTwitter(true)
    }
    // refetchTracking({
    //   contact_id: contact?.response?.index,
    //   activity: 'Check Twitter',
    //   twitter: contact?.response?.twitter,
    // })
    // window.open(contact?.response?.twitter, '_blank')
  }

  const handleClickTwitter = async () => {
    setPopconfirmTwitter(false)
    const key = 'twitter'
    const result = await refetchExpose({ selectedId, key })

    if (result?.error?.status === 470) {
      setOpenOutOfCreditsModal(true)
      setLimitOpenOutOfCreditsModal('limit')
      setCaptionCreditsModal('twitter')

      //message.error(result?.error.data.message)
    } else {
      setTwitterAlreadyValue(result.data.response.exposeData.twitter)
      window.open(result.data.response.exposeData.twitter, '_blank')
    }
  }

  const handleLoginHubSpot = async () => {
    const result = await refetchLoginHubSpot()

    const newWindow = window.open(result.data.response, '_blank')

    if (newWindow) {
      newWindow.focus()
    }
  }

  const handleShowCompany = async (company_id: any) => {
    setShowCompanyDetails(false)
    setTimeout(async () => {
      setCompanyDetailsLoading(true)
      setShowCompanyDetails(true)

      const companyDetails = await refetchGetCompany(company_id)

      if (companyDetails) setCompanyDetailsLoading(false)
    }, 500)
  }

  const injectIntoColumns = () => {
    const indexOfCompanyField = contactsTableColumn.findIndex((x: any) => x.key === 'company_name')
    if (indexOfCompanyField === -1) return contactsTableColumn

    const newColumns: any = contactsTableColumn
    newColumns[indexOfCompanyField].onCell = (record: any) => ({
      onClick: (e: any) => {
        e.stopPropagation()
        handleShowCompany(record?.company_id)
      },
    })

    const tempColumns = [...newColumns, ...additionalColumns]
    return tempColumns
  }

  const currentContact = contacts?.rows?.filter((item: any) => item.index === contact?.response?.index)?.[0]

  useEffect(() => {
    if (contactsError) {
      if (contactsError.status === 504 || contactsError.status === 'FETCH_ERROR') {
        setOpenLoadingErrorModal(true)
        return
      }
      if (contactsError.status === 470 && currentList) {
        setOpenOutOfCreditsModal(true)
        return
      }
    }
  }, [contactsError, currentList])

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      setUserData(data)
    }
    getUserDetails()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const points_store = await refetchGetPointsStore()
      setUserPoints({
        points: points_store?.data?.response?.points,
        pointsStore: points_store?.data?.response?.pointsStore,
      })
    }
    fetchData().catch(console.error)
  }, [])

  const handleSelectFifty = () => {
    const respSelectFormat = currentList ? contacts?.contacts_ids : contacts.fiftyContacts
    const filtyRows = [...selected_contacts, ...respSelectFormat]

    const uniqueContactsIds = Array.from(new Set(filtyRows))
    dispatch(SET_SELECTED_CONTACTS(uniqueContactsIds))
  }
  const handleSelectAllData = () => {
    const respSelectFormat = currentList ? contacts?.allContactsIdsData : contacts?.allIdContacts

    if (userData.addons.allow_select_all_data) {
      const newContactsIds = [...selected_contacts, ...respSelectFormat]
      const uniqueContactsIds = Array.from(new Set(newContactsIds))
      dispatch(SET_SELECTED_CONTACTS(uniqueContactsIds))
    } else {
      message.warning('Your plan does not support selecting all data.')
    }
  }

  const handleSelectAllTelegram = () => {
    if (userData.addons.allow_select_all_telegram) {
      const newContactsIds = [...selected_contacts, ...contacts.allContactsIdsTGData]

      const uniqueContactsIds = Array.from(new Set(newContactsIds))
      dispatch(SET_SELECTED_CONTACTS(uniqueContactsIds))
    } else {
      message.warning('Your plan does not support selecting all Telegram prospects.')
    }
  }
  const handleSelectAllLinkedin = () => {
    if (userData.addons.allow_select_all_linkedin) {
      const newContactsIds = [...selected_contacts, ...contacts.allContactsIdsLinkedinData]

      const uniqueContactsIds = Array.from(new Set(newContactsIds))
      dispatch(SET_SELECTED_CONTACTS(uniqueContactsIds))
    } else {
      message.warning('Your plan does not support selecting all Linkedin prospects.')
    }
  }
  const isRowSelected = isArray(selected_contacts) && selected_contacts?.length > 0

  return (
    <>
      <>
        <div className={classNames({ 'with-tableAction': isRowSelected })}>
          <div className='flex-1'>
            <div>
              {isRowSelected && (
                <div style={{ padding: '24px 24px 0 24px' }}>
                  <ContactTableAction
                    lists={lists}
                    isCompany={false}
                    selectedContacts={selected_contacts}
                    selectedCompanies={[]}
                    currentList={currentList}
                    refetchData={refetchGetContacts}
                    onDissmiss={() => {
                      dispatch(SET_SELECTED_CONTACTS([]))
                    }}
                  />
                </div>
              )}

              <FancyTable
                preserveSelectedRowKeys
                loading={isLoading}
                displayAll={contacts?.displayAll}
                page_type={'contacts'}
                totalItems={contacts?.totalNumber}
                totalDataNumber={contacts?.totalDataNumber || contacts?.totalNumber}
                key={'index'}
                columns={[...injectIntoColumns()]}
                data={contacts?.rows?.map((contact: any) => ({
                  ...contact,
                  key: contact.index,
                  campaigns: allCampaignsAccount?.response,
                  contactsTracking: userData?.contacts_tracking,
                  openCompanyDrawer: () => setShowContactDetails(true),
                }))}
                handleShowDetails={async (id) => {
                  if (id?.toLocaleLowerCase()?.startsWith('csv')) return
                  navigate(`${pathname}/${id}${search}`)
                }}
                tableParams={{
                  ...tableParams,
                  total: contacts?.totalNumber,
                }}
                setTableParams={handleChangeTableParamsContact}
                handleSelectedRows={handleSelectedRows}
                handleUnSelectRows={handleUnSelectRows}
                selected_items={selected_contacts}
                handleRemoveRow={handleRemoveRow}
                hasBanner={openBanner}
                handleSelectFifty={handleSelectFifty}
                handleSelectAllData={handleSelectAllData}
                handleSelectAllTelegram={handleSelectAllTelegram}
                handleSelectAllLinkedin={handleSelectAllLinkedin}
                isFromOutreach={true}
                className={classnames('my_contact_table', {
                  'with-tableAction': isRowSelected,
                  'no-data': contacts?.rows?.length === 0,
                })}
              />
            </div>
          </div>

          <Modal
            className='modalErrorMessage'
            title='Choose export destination'
            open={showErrorMessage}
            onCancel={() => {
              setShowErrorMessage(false)
              setErrorMessage('')
            }}
            footer={null}>
            <p>{errorMessage}</p>
          </Modal>

          <Modal
            className='modalTelegram'
            title='Telegram url'
            open={showTelegram}
            onCancel={() => {
              setShowTelegram(false)
              setTelegramResult('')
            }}
            footer={null}>
            <a href={`https://t.me/${telegramResult}`} target='_black'>
              {`https://t.me/${telegramResult}`}
            </a>
          </Modal>

          <Modal
            title='Add to List:'
            className='listModal'
            centered
            open={addToListModal}
            footer={
              <Button
                className='align-items-center gap-2 addToList'
                onClick={() => {
                  handleAddItemsToList()
                }}
                disabled={!selectedList}>
                Add to List
              </Button>
            }
            onCancel={() => setAddToListModal(false)}>
            <>
              {company_lists?.map((item: any, index: any) => (
                <div className='rowListItem' key={index}>
                  <span
                    className={classnames('listitem', selectedList === item.id ? 'selectedList' : '')}
                    onClick={() => {
                      setSelectedList(item.id)
                    }}>
                    {item.name}
                  </span>
                </div>
              ))}
              {addToListInput && (
                <div className='addNewContainer'>
                  <Input placeholder='' className='addNewInput' onKeyDown={handleAddNewItem} />
                </div>
              )}
              <span
                className='createANewItem'
                onClick={() => {
                  setAddToListInput(!addToListInput)
                }}>
                + Create New
              </span>
            </>
          </Modal>

          {errorStatusCode >= 400 && (
            <div className={styles.centerAlign}>
              {errorStatusCode === 500 || userDetails.subscription_type === 'PREMIUM' ? (
                <ErrorMessage />
              ) : (
                <ErrorMessage />
                // <IntentSignalsPermanentPaywall trialExpired={errorStatusCode === 403} />
              )}
            </div>
          )}
        </div>
        <Modal
          open={actionsPaywallOpen}
          onCancel={() => setActionsPaywallOpen(false)}
          okButtonProps={{
            className: 'hidden',
          }}
          cancelButtonProps={{
            className: 'hidden',
          }}>
          <div className='flex justify-content-center'>
            <BlurPaywall
              addon={'This filter'}
              onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
              component={[]}
              customClass='contactSalesNoMargin'
            />
          </div>
        </Modal>

        {showAlert && (
          <Alert
            style={{ position: 'absolute', bottom: '69px', right: ' 20px' }}
            message='Enrich HubSpot with Convrt'
            description='To enrich contacts/companies and create/update deals, connecting your HubSpot account with Convrt is required'
            type='info'
            action={
              <Space direction='vertical'>
                <Button
                  size='small'
                  type='primary'
                  onClick={() => {
                    handleLoginHubSpot()
                    setShowAlert(false)
                  }}>
                  Connect
                </Button>
              </Space>
            }
            closable
            onClose={() => setShowAlert(false)}
          />
        )}
      </>

      <FancyDrawer
        onClose={() => {
          setShowContactDetails(false)
          setPopconfirmLinkedin(false)
          setPopconfirmTwitter(false)
          navigate(-1)
        }}
        extraClass={'fancyDrawer extraFancyDrawer'}
        open={showContactDetails}
        closeIcon={'no'}
        title={
          !loading && (
            <div className={classnames('drawerTitleContact', 'contactLogoTop')}>
              <div className={styles.socialContacts}>
                {/* daniel linkedin*/}
                {contact?.response?.linkedin ? (
                  <>
                    <Popconfirm
                      title='Get Linkedin Details'
                      description={
                        <Col>
                          {/* <div>{`${ConfirmBox.ARE_YOU_SURE}`}</div> */}
                          {/* <div>{`You will be redirected by this action. Read disclaimer:`}</div> */}
                          <div>{`This action will cost you ${userPoints?.pointsStore?.linkedin} points. Read disclaimer:`}</div>

                          {showDisclaimer ? (
                            <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                              {ConfirmBox.DISCLAIMER}
                            </p>
                          ) : (
                            <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                              {'Disclaimer'}
                            </span>
                          )}
                        </Col>
                      }
                      open={popconfirmLinkedin && showContactDetails}
                      onConfirm={handleClickLinkedIn}
                      // okButtonProps={{ loading: confirmLoading }}
                      onCancel={() => {
                        setPopconfirmLinkedin(false)
                      }}>
                      <img className='cursor-pointer' src={c_linkedin} onClick={onClickLinkedIn} alt={''} />
                    </Popconfirm>
                    {/* <img className='cursor-pointer' src={c_linkedin} onClick={onClickLinkedIn} alt={''} /> */}
                  </>
                ) : (
                  <img className='imgOpacity' src={c_linkedin} alt={''} />
                )}

                {contact?.response?.twitter ? (
                  <Popconfirm
                    title='Get Twitter Details'
                    description={
                      <Col>
                        <div>{`You will be redirected by this action. Read disclaimer:`}</div>

                        {/* <div>{`${ConfirmBox.ARE_YOU_SURE}`}</div> */}
                        {showDisclaimer ? (
                          <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                            {ConfirmBox.DISCLAIMER}
                          </p>
                        ) : (
                          <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                            {'Disclaimer'}
                          </span>
                        )}
                      </Col>
                    }
                    open={popconfirmTwitter && showContactDetails}
                    onConfirm={handleClickTwitter}
                    // okButtonProps={{ loading: confirmLoading }}
                    onCancel={() => {
                      setPopconfirmTwitter(false)
                    }}>
                    <img className='cursor-pointer' src={c_twitter} onClick={onClickTwitter} alt={''} />
                  </Popconfirm>
                ) : (
                  <img className='imgOpacity' src={c_twitter} alt={''} />
                )}
              </div>
              <div className={styles.actions}>
                <ContactsActions contact={contact} />
              </div>
              {contact &&
                (currentContact?.telegramContact && Object.keys(userDetails?.telegram).length > 0 ? (
                  <div
                    className={styles.hasTelegramImage}
                    style={{
                      border: `3px solid ${
                        currentContact?.connection_level === 1 || contact?.response?.connection_level_already === 1
                          ? '#50e2ba'
                          : currentContact?.connection_level === 2 || contact?.response?.connection_level_already === 2
                          ? '#7043ff'
                          : '#FF4FE6'
                      }`,
                    }}>
                    <CircularImage
                      src={contact?.response?.photo_url}
                      alt=''
                      color={currentContact?.connection_level || contact?.response?.connection_level_already || 3}
                      className={styles.numberCircle}
                      badge={true}
                    />
                  </div>
                ) : (
                  <FancyImage
                    url={contact?.response?.photo_url}
                    name={contact?.response?.full_name}
                    size={90}
                    fontSize={30}
                  />
                ))}

              {/* {contact && <FancyImage url={contact?.response?.photo_url} />} */}
              <p>{contact?.response?.full_name}</p>
              <div className={styles.subTitle}>
                <span>
                  <img className='imgOpacity' src={briefcase} alt={''} />
                  <span>{contact?.response?.job_title}</span>
                </span>
                <span>|</span>
                <span>
                  <img className='imgOpacity' src={location} alt={''} />
                  <span>{contact?.response?.location ? contact?.response?.location : 'NA'}</span>
                </span>
              </div>
              <div>
                {tags?.length > 0 && (
                  <div className={styles.tagListParent}>
                    {tags?.map((tag, index) => (
                      <span className='contactTagsDetails contactTagsRewriteMargin' key={index}>
                        {tag}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )
        }>
        {!loading ? (
          <ContactDetails
            data={contact?.response}
            communitiesData={{
              connection_level: currentContact?.connection_level || contact?.response?.connection_level_already,
              shared_groups: currentContact?.shared_groups,
            }}
            toggleHide={(value: any) => {
              setShowContactDetails(value)
            }}
            refetch={refetchGetContacts}
          />
        ) : (
          <LoadingBox />
        )}
        {/* {start && <LoadingBox />} */}
      </FancyDrawer>
      <FancyDrawer
        onClose={() => {
          setShowCompanyDetails(false)
          window.location.pathname.indexOf('companies') === -1 && navigate(`/exposedContacts${search}`)
        }}
        open={showCompanyDetails}
        customClass={'companyDetails'}
        title={
          !companyDetailsLoading && (
            <div className={'drawerTitle'}>
              <FancyImage url={company?.s3_logo} iscompany={true} name={company?.name} />
              <p>
                {company?.name}
                <a href={company?.url} target='_blanck'>
                  {company?.url}
                </a>
              </p>
            </div>
          )
        }>
        {!companyDetailsLoading ? (
          <CompanyDetails
            data={company}
            toggleHide={(value: any) => {
              setShowCompanyDetails(value)
            }}
            showCompany={handleShowCompany}
          />
        ) : (
          <LoadingBox />
        )}
      </FancyDrawer>

      {openOutOfCreditsModal && (
        <OutOfCreditsModal
          creditType='Contacts Filter'
          open={openOutOfCreditsModal}
          onClose={() => {
            setOpenOutOfCreditsModal(false)

            if (limitOpenOutOfCreditsModal === 'points') navigate('/exposedContacts')

            setLimitOpenOutOfCreditsModal('points')
          }}
          location={'contacts'}
          type={limitOpenOutOfCreditsModal}
          caption={captionCreditsModal}
        />
      )}

      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          creditType={'list'}
          location='lists'
        />
      )}
      {openLoadingErrorModal && (
        <AreYouSureModal
          open={openLoadingErrorModal}
          onClose={() => {
            setOpenLoadingErrorModal(false)
          }}
          title='Oops! It seems like this is taking longer than expected.'
          message="Please try refreshing the page or consider adjusting your filters for a smoother experience. If the issue persists, we're here to help!"
          onConfirm={() => {
            window.location.reload()
          }}
          widthStyle={600}
          confirmText='Refresh'
        />
      )}
    </>
  )
}

export default MyContact
