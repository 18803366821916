import React, { useState } from 'react'
import { Menu, MenuProps, Tooltip } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from 'common/hooks/useAuth.hooks'

import vectorBlack from 'common/assets/svg/vector_black.svg'
import vectorWhite from 'common/assets/svg/vector_white.svg'
import lightBulbWhite from 'common/assets/svg/Lightbulb.svg'
import lightBulbBlack from 'common/assets/svg/LightbulbBlack.svg'
import contactsWhite from 'common/assets/svg/contactsWhite.svg'
import contactsBlack from 'common/assets/svg/contactsBlack.svg'
// import eventsWhite from 'common/assets/svg/meetingsWhite.svg'
// import eventsBlack from 'common/assets/svg/meetingsBlack.svg'
import chatsBlack from 'common/assets/svg/chatsBlack.svg'
import chatsWhite from 'common/assets/svg/chatsWhite.svg'
import { MenuServices } from 'common/services/menu-services'
import { useAppSelector } from 'state'

import styles from './top-menu.module.scss'
import ExitModal from '../OutreachCampaign/NewCampaign/UI/ExitModal'

interface ITopMenu {
  collapsed: boolean
  selectedKey: string
  callbackOnChange?: (value?: string) => void
}

const TopMenu: React.FC<ITopMenu> = ({ collapsed, callbackOnChange, selectedKey }) => {
  const [openExitModal, setOpenExitModal] = useState(false)
  const { isBetaOrTrialUser } = useAuth()
  const { isSubscriptionDaysPassed } = useAppSelector((state) => state.filters)

  const navigate = useNavigate()
  const disableNavigationsRoutes = ['/outreach/create-new-campaign']
  const [navigateTo, setNavigateTo] = useState('')

  type MenuItem = Required<MenuProps>['items'][number]

  const location = useLocation()

  const lightBulb =
    location.pathname.includes('/companies') || location.pathname.includes('/contacts')
      ? lightBulbBlack
      : lightBulbWhite
  // const contacts = location.pathname.includes('/contacts') ? contactsBlack : contactsWhite
  // const events = location.pathname.includes('/events') ? eventsBlack : eventsWhite
  const exposedContacts = location.pathname.includes('/exposedContacts') ? contactsBlack : contactsWhite
  const unifiedInbox = location.pathname.includes('/unifiedInbox') ? chatsBlack : chatsWhite
  const outreach = location.pathname.includes('/outreach') ? vectorBlack : vectorWhite

  const items: MenuItem[] = [
    MenuServices.getItem(
      !collapsed && 'Research',
      'companies' || 'contacts',
      <img src={lightBulb} alt={''} />,
      isSubscriptionDaysPassed,
    ),
    // MenuServices.getItem(
    //   !collapsed && 'Events',
    //   'events',
    //   <Badge count={<div className={styles.badge}>{'BETA'}</div>}>
    //     <img src={events} alt={''} />
    //   </Badge>,
    // ),
    MenuServices.getItem(
      !collapsed && 'My Contacts',
      'exposedContacts',
      <img src={exposedContacts} alt={''} />,
      isSubscriptionDaysPassed,
    ),
    MenuServices.getItem(
      !collapsed && 'Unified Inbox',
      'unifiedInbox',
      <img src={unifiedInbox} alt={''} />,
      isSubscriptionDaysPassed,
    ),
    MenuServices.getItem(
      !collapsed && (
        <Tooltip
          placement='bottomRight'
          title={
            isBetaOrTrialUser && (
              <div style={{ margin: '10px' }}>
                {` In Outreach tab you can see all the contacts you exposed and easly reach out to them in bulk and see your
              stats.`}
              </div>
            )
          }>
          {'Outreach'}
        </Tooltip>
      ),
      'outreach',
      // <img style={{ height: '30px' }} src={outreach} alt={''} />
      // <Badge count={<div className={styles.newBadge}>{'BETA'}</div>}>
      <img src={outreach} alt={''} />,
      // </Badge>
      isSubscriptionDaysPassed,
    ),
  ]

  const handleNavigation = (value: any) => {
    const routeToNavigate = `/${value.key}`
    const currentRoute = window.location.pathname

    if (disableNavigationsRoutes.includes(currentRoute)) {
      setOpenExitModal(true)
      setNavigateTo(routeToNavigate)
    } else {
      callbackOnChange && callbackOnChange(value.key)
      navigate(routeToNavigate)
    }

    const refreshAfterNavigation = ['/checkout/success']
    if (refreshAfterNavigation.includes(currentRoute)) {
      window.location.reload()
      navigate(routeToNavigate)
    }
  }

  //In Outreach tab you can see all the contacts you exposed and easly reach out to them in bulk and see your stats.
  return (
    <>
      <Menu
        theme='dark'
        defaultSelectedKeys={[
          window.location.pathname.indexOf('companies') >= 0
            ? 'companies'
            : window.location.pathname.indexOf('contacts') >= 0
            ? 'companies'
            : // : window.location.pathname.indexOf('events') >= 0
            // ? 'events'
            window.location.pathname.indexOf('unifiedInbox') >= 0
            ? 'unifiedInbox'
            : window.location.pathname.indexOf('exposedContacts') >= 0
            ? 'exposedContacts'
            : window.location.pathname.indexOf('outreach') >= 0
            ? 'outreach'
            : '',
        ]}
        selectedKeys={[
          window.location.pathname.indexOf('companies') >= 0
            ? 'companies'
            : window.location.pathname.indexOf('contacts') >= 0
            ? 'companies'
            : // : window.location.pathname.indexOf('events') >= 0
            // ? 'events'
            window.location.pathname.indexOf('unifiedInbox') >= 0
            ? 'unifiedInbox'
            : window.location.pathname.indexOf('exposedContacts') >= 0
            ? 'exposedContacts'
            : window.location.pathname.indexOf('outreach') >= 0
            ? 'outreach'
            : '',
        ]}
        mode='inline'
        items={items}
        className={styles.topMenu}
        onSelect={handleNavigation}
      />
      {openExitModal && (
        <ExitModal
          open={openExitModal}
          onClose={() => {
            setOpenExitModal(false)
          }}
          navigateTo={navigateTo}
        />
      )}
    </>
  )
}

export default TopMenu
