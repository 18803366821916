import React, { useEffect, useState } from 'react'
import { Tabs, Form, Mentions } from 'antd'
import styles from './MessageTabs.module.scss'
import full_message_indicator from 'common/assets/svg/full_message_indicator.svg'
import empty_message_indicator from 'common/assets/svg/empty_message_indicator.svg'

interface Option {
  value: string
  label: string
}

interface MessageTabsProps {
  options: Option[]
  updateNodesData: any
  savedMessages: string[] // Now an array of messages
  setCurrentTab: any
  edit_mode: boolean
  enable_editing: boolean
  isOpenPhoneNumberTabel: any
  compare?: any
  isCompareAbTesting?: any
  form: any
}

const MessageTabs: React.FC<MessageTabsProps> = ({
  options,
  updateNodesData,
  savedMessages = [],
  setCurrentTab,
  edit_mode,
  enable_editing,
  isOpenPhoneNumberTabel,
  compare,
  isCompareAbTesting,
  form,
}) => {
  const labels = ['A', 'B', 'C']
  const initialTabs = labels
    .map((label, index) => ({
      label,
      key: label,
      message: savedMessages[index] || '',
    }))
    .filter(
      (tab, index) =>
        index === 0 || (index === 1 && !edit_mode) || (savedMessages[index] && savedMessages[index] !== ''),
    )

  const [activeKey, setActiveKey] = useState<string>(initialTabs?.[0]?.key || 'A')
  const [dynamicLabels, setDynamicLabels] = useState<React.ReactNode[]>(
    labels.map((label, index) => (
      <div className={styles.label}>
        <span>{label}</span>
        <img src={savedMessages[index] ? full_message_indicator : empty_message_indicator} alt='' />
      </div>
    )),
  )
  const [tabs, setTabs] = useState<any[]>(initialTabs)
  const [messages, setMessages] = useState<{ [key: string]: string }>(
    initialTabs.reduce((acc, tab) => ({ ...acc, [tab.key]: tab.message }), {}),
  )

  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey)
    setCurrentTab(newActiveKey)
  }

  const onEdit = (targetKey: any, action: 'add' | 'remove') => {
    if (action === 'add') {
      add()
    } else if (typeof targetKey === 'string') {
      remove(targetKey)
    }
  }

  const add = () => {
    if (tabs.length >= 3) return

    const nextLabel = labels[tabs.length]
    const newTab = {
      label: nextLabel,
      key: nextLabel,
      message: '',
    }
    setTabs([...tabs, newTab])
    // setMessages({ ...messages, [nextLabel]: '' })
    setMessages((prevMessages) => ({ ...prevMessages, [nextLabel]: '' }))
    setActiveKey(newTab.key)
    setCurrentTab(newTab.key)
  }

  const remove = (targetKey: string) => {
    const newTabs = tabs.filter((tab) => tab.key !== targetKey)
    const { [targetKey]: removed, ...newMessages } = messages
    form.setFieldsValue({ [`message${targetKey}`]: '' })

    setTabs(newTabs)
    setMessages(newMessages)
    updateNodesData('', targetKey)

    if (newTabs.length) {
      setActiveKey(newTabs[newTabs.length - 1].key)
    } else {
      setActiveKey('A')
    }
  }

  const handleMentionsChange = (key: string, value: string) => {
    // setMessages({ ...messages, [key]: value })
    setMessages((prevMessages) => ({
      ...prevMessages,
      [key]: value,
    }))
    updateNodesData(value)
    compare(key, value)
  }

  useEffect(() => {
    const updatedLabels = labels.map((label, index) => (
      <div className={styles.label}>
        <span>{label}</span>
        <img src={messages[label] ? full_message_indicator : empty_message_indicator} alt='' />
      </div>
    ))
    setDynamicLabels(updatedLabels)
  }, [messages])

  return (
    <Tabs
      type='editable-card'
      className={isCompareAbTesting ? 'messagesTabsError' : 'messagesTabs'}
      onChange={onChange}
      activeKey={activeKey}
      onEdit={onEdit}
      style={{ margin: 0, paddingTop: '10px' }}
      items={tabs.map((tab, index, arr) => {
        return {
          disabled: isCompareAbTesting,
          label: dynamicLabels[index],
          key: tab.key,
          closable: arr.length > 1 && index === arr.length - 1 && !(edit_mode && !enable_editing) && !edit_mode,
          children: (
            <Form.Item name={`message${tab.label}`} style={{ paddingTop: '0px' }}>
              <Mentions
                className='inputText'
                autoSize={{ minRows: 8, maxRows: 8 }}
                maxLength={5000}
                style={{
                  padding: '15px 0px 50px 15px',
                  marginTop: '-1px',
                  backgroundColor: edit_mode && !enable_editing ? '#f5f5f5' : '',
                  borderTop: 'none',
                  borderRadius: '0px 8px 8px 8px',
                  borderColor: isCompareAbTesting ? '#da1818' : '#7043ff',
                }}
                placeholder='You can use @ to ref user data here'
                defaultValue={messages[tab.key]}
                options={options}
                onChange={(e) => handleMentionsChange(tab.key, e)}
                onSelect={(option: any, prefix: string) => {
                  isOpenPhoneNumberTabel(`@${option.key}`)
                }}
                disabled={edit_mode && !enable_editing}
              />
            </Form.Item>
          ),
        }
      })}
      hideAdd={tabs.length >= 3 || (edit_mode && !enable_editing) || edit_mode || isCompareAbTesting}
    />
  )
}

export default MessageTabs
