import React, { useEffect, useState } from 'react'
import { Layout, Menu } from 'antd'
import {
  ProfileOutlined,
  NotificationOutlined,
  DollarCircleOutlined,
  // TeamOutlined,
  ThunderboltOutlined,
  RocketOutlined,
  LoginOutlined,
} from '@ant-design/icons'
import styles from './SettingsPage.module.scss'
import ProfileSettings from './Pages/ProfileSettings'
import SocialSettings from './Pages/SocialSettings'
import SubscriptionSettings from './Pages/SubscriptionSettings'
import {
  useLazyGetConvrtQuery,
  useLazyGetPaymentDetailsQuery,
  useLazyGetSubscriptionSettingsQuery,
  useLazyGetUsersListQuery,
} from './state/api/SettingsApi'
import AdminSettings from './Pages/AdminSettings'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import useHubspot from './Pages/Hooks/useHubspot'
import CustomerServiceSettings from './Pages/CustomerServiceSettings'

const { Sider, Content } = Layout

const Settings: React.FC = () => {
  const [selectedMenu, setSelectedMenu] = useState('profile')
  const [refetchGetSubscriptionSettings, { data: subscriptionData }] = useLazyGetSubscriptionSettingsQuery()
  const [refetchGetPaymentDetails, { data: paymentData }] = useLazyGetPaymentDetailsQuery()
  const [refetchUsersList, { data: usersList }] = useLazyGetUsersListQuery()
  const navigate = useNavigate()
  const location = useLocation()
  const { userDetails, updateUserDetails } = useAuth()
  const { company_admin } = userDetails
  const [shouldHideCheckout, setShouldHideCheckout] = useState(userDetails?.flag)
  const [isCustomerService, setIsCustomerService] = useState(false)
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [getIsCustomerService] = useLazyGetConvrtQuery()
  const { existKey, setExistKey, setUser } = useHubspot()

  const handleMenuClick = (selectedKey: string) => {
    setSelectedMenu(selectedKey)
  }

  useEffect(() => {
    if (location.state?.action === 'unlink') {
      setExistKey(false)
      setUser('')
    }
  }, [location])

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      setShouldHideCheckout(data.flag)
    }

    const getCS = async () => {
      const { data } = await getIsCustomerService()
      setIsCustomerService(data)
    }

    const getSubscriptionSettings = async () => {
      await refetchGetSubscriptionSettings()
    }
    const getPaymentDetails = async () => {
      await refetchGetPaymentDetails()
    }

    const getUsersList = async () => {
      await refetchUsersList()
    }

    getUserDetails()
    getCS()
    getSubscriptionSettings()
    getPaymentDetails()
    getUsersList()
    updateUserDetails()
  }, [])

  return (
    <Layout className={styles.settingsLayout}>
      <Sider width={260} theme='light' className={styles.sidebar}>
        <div className={styles.sidebarHeader}>Settings</div>
        <Menu
          mode='inline'
          style={{ paddingRight: '20px' }}
          selectedKeys={[selectedMenu]}
          onClick={({ key }) => handleMenuClick(key.toString())}>
          {isCustomerService && (
            <Menu.ItemGroup key='customer_service' title='Customer Service' style={{ marginTop: '20px' }}>
              <Menu.Item key='oneTimeLogin' icon={<LoginOutlined />}>
                One-Time Login
              </Menu.Item>
            </Menu.ItemGroup>
          )}
          <Menu.ItemGroup key='account' title='Account' style={{ marginTop: '20px' }}>
            <Menu.Item key='profile' icon={<ProfileOutlined />}>
              Profile
            </Menu.Item>
            <Menu.Item key='notifications' icon={<NotificationOutlined />}>
              Social & Integrations
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup key='admin' title='Admin' style={{ marginTop: '20px' }}>
            <Menu.Item key='subscription' icon={<DollarCircleOutlined />}>
              Subscriptions & Billing
            </Menu.Item>
            {company_admin && (
              <Menu.Item key='companyAdmin' icon={<RocketOutlined />}>
                {userDetails.company}'s Cockpit
              </Menu.Item>
            )}
            {!shouldHideCheckout && (
              <Menu.Item
                key='checkout'
                icon={<ThunderboltOutlined />}
                onClick={() => {
                  navigate('/checkout')
                }}>
                Upgrade
              </Menu.Item>
            )}
          </Menu.ItemGroup>
        </Menu>
      </Sider>
      <Layout>
        <Content className={styles.content}>
          {/* Content for each setting page goes here */}
          {selectedMenu === 'oneTimeLogin' && isCustomerService && <CustomerServiceSettings />}
          {selectedMenu === 'profile' && <ProfileSettings shouldHideCheckout={shouldHideCheckout} />}
          {selectedMenu === 'notifications' && (
            <SocialSettings shouldHideCheckout={shouldHideCheckout} hubspotKey={existKey} />
          )}
          {selectedMenu === 'subscription' && (
            <SubscriptionSettings
              shouldHideCheckout={shouldHideCheckout}
              data={subscriptionData}
              paymentData={paymentData}
            />
          )}
          {selectedMenu === 'companyAdmin' && (
            <AdminSettings shouldHideCheckout={shouldHideCheckout} data={usersList} paymentData={paymentData} />
          )}
        </Content>
      </Layout>
    </Layout>
  )
}

export default Settings
