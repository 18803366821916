import React, { useEffect, useState } from 'react'
import { Modal, Button, Tooltip, Select, AutoComplete, message } from 'antd'
import styles from './OutreachCampaignWithAIModal.module.scss'
import wizard_icon from 'common/assets/svg/wizard_icon.svg'
import right_arrow from 'common/assets/svg/right_arrow_white.svg'
import step_send_tg from 'common/assets/svg/channels_telegram.svg'
import channels_question_mark from 'common/assets/svg/channels_question_mark.svg'
import channels_linkedin from 'common/assets/svg/channels_linkedin.svg'
import manually from 'common/assets/svg/manually.svg'
import duplicate from 'common/assets/svg/duplicate.svg'
import { post } from 'common/api/axios'
import {
  SET_AI_GENERATED_DATA,
  SET_SELECTED_PLATFORM,
  SET_TEMPLATE,
} from 'common/components/OutreachCampaign/state/outreachCampaignSlice'
import { useAppDispatch, useAppSelector } from 'state'
import {
  useLazyGenerateWithAIQuery,
  useLazyGetUserDetailsQuery,
  useLazyLinkedinGenerateWithAIQuery,
} from 'features/Outreach/state/api/OutreachApi'
import { useNavigate } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'

interface TemplatesWizardModalProps {
  open: boolean
  onCancel: () => void
  setOpenTemplatesWizardModal: any
}

const OutreachCampaignWithAIModal: React.FC<TemplatesWizardModalProps> = ({
  open,
  onCancel,
  setOpenTemplatesWizardModal,
}) => {
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [template, setTemplate] = useState(0)
  const [channelsTelegram, setChannelsTelegram] = useState(true)
  const [channelsLinkedin, setChannelsLinkedin] = useState(false)
  const [channelsQuestionMark] = useState(false)
  const [arrayChannelsQuestionMark] = useState([1, 2, 3, 4])
  const [maxTemplate, setMaxTemplate] = useState(0)
  const navigate = useNavigate()
  const [refetchGenerateWithAI] = useLazyGenerateWithAIQuery()
  const [linkedinGenerateWithAI] = useLazyLinkedinGenerateWithAIQuery()
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const dispatch = useAppDispatch()
  const errorMessage =
    maxTemplate > 0 ? `Your Plan Supports Up To ${maxTemplate} Followup!` : `Your Plan Does Not Support Followups!`
  const [isLoading, setIsLoading] = useState(false)
  const [textHelp, setTextHelp] = useState('DeFi Projects')
  const [textTo, setTextTo] = useState('leverage on-chain data and scale')
  const [textBy, setTextBy] = useState('integrating with our managed API solution')
  const [selectedValue, setSelectedValue] = useState(0)
  const { selected_platform } = useAppSelector((state) => state.outreachCampaign)
  const [linkedinAddon, setLinkedinAddon] = useState(false)

  // const [isPlaceholderVisibleTextHelp, setPlaceholderVisibleTextHelp] = useState(true)
  // const [isPlaceholderVisibleTextTo, setPlaceholderVisibleTextTo] = useState(true)
  // const [isPlaceholderVisibleTextBy, setPlaceholderVisibleTextBy] = useState(true)

  // const handleFocusHelpText = () => {
  //   setPlaceholderVisibleTextHelp(false)
  // }

  const handleToTextChange = (event: any) => {
    if (event === '' || event === undefined) {
      setTextTo(' ')
    } else {
      setTextTo(event)
    }
  }

  // const handleFocusToText = () => {
  //   setPlaceholderVisibleTextTo(false)
  // }

  const handleByTextChange = (event: any) => {
    if (event?.trim() === '' || event === undefined) {
      setTextBy('  ')
    } else {
      setTextBy(event)
    }
  }

  // const handleFocusByText = () => {
  //   setPlaceholderVisibleTextBy(false)
  // }

  const handleSelectChange = (e: any) => {
    setTemplate(e)
    setSelectedValue(e)
    dispatch(SET_TEMPLATE(e))
  }

  const handleGenerate = async () => {
    if (isLoading) return
    if (template > maxTemplate && template < 3) {
      setShowErrorMessage(true)
    } else {
      setIsLoading(true)
      post('/log', {
        action: 'Generate AI Campaign',
        data: { audience: textHelp, pain_points: textTo, solution: textBy, hierarchy: template },
      })

      try {
        /// CHECK IF TELEGRAM OR LINKEDIN
        if (selected_platform === 'linkedin') {
          const { data } = await linkedinGenerateWithAI({
            audience: textHelp,
            pain_points: textTo,
            solution: textBy,
            hierarchy: 1,
          })

          const generatedData = data?.ai_response
          const generatedDataToSteps = {
            '5_1': { message: generatedData[0] },
            '7_1': { message: generatedData?.[1] },
          }

          dispatch(SET_AI_GENERATED_DATA(generatedDataToSteps))
        } else {
          const { data } = await refetchGenerateWithAI({
            audience: textHelp,
            pain_points: textTo,
            solution: textBy,
            hierarchy: template,
          })

          const generatedData = data?.ai_response
          const generatedDataToSteps =
            template === 0
              ? { '1': { message: generatedData[0] } }
              : template === 1
              ? { '1': { message: generatedData[0] }, '3_1': { message: generatedData?.[1] } }
              : template === 2
              ? {
                  '1': { message: generatedData[0] },
                  '3_1': { message: generatedData?.[1] },
                  '6_1': { message: generatedData?.[2] },
                }
              : {}

          dispatch(SET_AI_GENERATED_DATA(generatedDataToSteps))
        }
        setIsLoading(false)
        navigate('/outreach/create-new-campaign')
      } catch (e) {
        console.error(`An error has occured: ${e}`)
        message.error('Something went wrong generating your campaign. Please try again.')
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    const getAddons = async () => {
      const { data } = await refetchGetUserDetails()
      const addons = data?.addons
      const outreachFollowups = addons?.outreach_followups
      if (typeof outreachFollowups === 'number' && outreachFollowups >= 0 && outreachFollowups <= 2) {
        setMaxTemplate(outreachFollowups)
      } else {
        setMaxTemplate(0)
      }

      const linkedin = addons?.linkedin_campaign
      setLinkedinAddon(linkedin)
    }

    dispatch(SET_TEMPLATE(template))
    getAddons()
  }, [])

  useEffect(() => {
    setShowErrorMessage(false)
  }, [template])

  const options = [
    { value: 'DeFi Projects' },
    { value: 'Dapps' },
    { value: 'Exchanges' },
    { value: 'Gaming companies' },
    { value: 'Web3 companies' },
    { value: 'Startups' },
    { value: 'L1 & L2 Companies' },
    { value: 'Agencies' },
    { value: 'Institutionals' },
    { value: 'NFT Marketplaces' },
    { value: 'Payment Providers' },
    { value: 'Companies with on-chain data' },
    { value: 'Investors' },
  ]
  const handleAutoCompleteChange = (e: any) => {
    if (e === '') {
      setTextHelp(' ')
    } else {
      setTextHelp(e)
    }
  }

  const measureTextWidth = (text: string) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (context) {
      context.font = '36px Roboto'
      const metrics = context.measureText(text)
      return metrics.width
    }
    return 0
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.currentTarget.blur()
    }
  }

  return (
    <Modal open={open} onCancel={onCancel} footer={null} width='1000px' className={'outreachCampaignWithAIModal'}>
      <div className={styles.header}>
        <p>Generate Outreach Campaign With AI</p>
        <img src={wizard_icon} alt='' />
      </div>
      <div className={styles.subHeader}>Let us know some info about your outreach, sit back and relax!</div>
      <div className={styles.modalContent}>
        <div className={styles.rightPanel}>
          <div className={styles.previewImage}>
            <div className={styles.card}>
              <div className={styles.free_text_ai}>
                <span>We help </span>
                {/* <span className={styles.autoNewlineTextParent}>
                  <span
                    className={styles.autoNewlineText}
                    contentEditable='true'
                    onInput={handleHelpTextChange}
                    // onKeyDown={handleFocusHelpText}
                  >
                    {textHelp}
                  </span>
                </span> */}
                <AutoComplete
                  className='autoCompleteWizard'
                  style={{ width: measureTextWidth(textHelp), maxWidth: '745px' }}
                  options={options}
                  dropdownStyle={{ minWidth: '200px' }}
                  // defaultValue={options[0]}
                  placeholder={`${textHelp}`}
                  // onClick={() => setTextHelp('')}
                  onChange={handleAutoCompleteChange}
                  filterOption={(inputValue, option) =>
                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
                <span> to</span>
                <span className={styles.autoNewlineTextParent}>
                  <span
                    className={styles.autoNewlineText}
                    contentEditable='true'
                    // onInput={(e: any) => handleToTextChange(e.currentTarget.textContent)}
                    // onBlur={(e: any) => handleToTextChange(e.currentTarget.textContent)}
                    placeholder={`${textTo}`}
                    onBlur={(e: any) => {
                      const bool = e.currentTarget.textContent !== '' && e.currentTarget.textContent !== null
                      bool ? handleToTextChange(e.currentTarget.textContent) : handleToTextChange(' ')
                    }}
                    suppressContentEditableWarning
                    onKeyDown={(e: any) => handleKeyDown(e)}

                    // dangerouslySetInnerHTML={{ __html: textTo }}

                    // onKeyDown={handleFocusToText}
                  >
                    {/* {isPlaceholderVisibleTextTo ? 'leverage on-chain data and scale' : ''} */}
                    {/* {textTo} */}
                  </span>
                </span>
                by
                <span className={styles.autoNewlineTextParent}>
                  <span
                    // role='textbox'
                    className={styles.autoNewlineText}
                    contentEditable='true'
                    placeholder={` ${textBy}`}
                    onBlur={(e: any) => {
                      const bool = e.currentTarget.textContent !== '' && e.currentTarget.textContent !== null
                      bool ? handleByTextChange(e.currentTarget.textContent) : handleByTextChange(' ')
                    }}
                    suppressContentEditableWarning
                    onKeyDown={(e: any) => handleKeyDown(e)}>
                    {/* {isPlaceholderVisibleTextBy ? 'integrating with our managed API solution' : ''} */}
                    {/* {textBy} */}
                  </span>
                </span>
                <span>.</span>
              </div>
              <div className={styles.channels_frame}>
                <div className={styles.channels_caption}>
                  Use these<br></br> Channels:
                </div>
                <span
                  className={channelsTelegram ? styles.checked : styles.unchecked}
                  onClick={() => {
                    setChannelsTelegram(true)
                    setChannelsLinkedin(false)
                    dispatch(SET_SELECTED_PLATFORM('telegram'))
                    dispatch(SET_TEMPLATE(selectedValue))
                    setTemplate(selectedValue)
                  }}>
                  <img src={step_send_tg} alt='' />
                </span>
                <span
                  className={channelsLinkedin ? styles.checked : styles.unchecked}
                  onClick={() => {
                    if (linkedinAddon) {
                      setChannelsLinkedin(true)
                      setChannelsTelegram(false)
                      dispatch(SET_SELECTED_PLATFORM('linkedin'))
                      dispatch(SET_TEMPLATE(3))
                      setTemplate(3)
                    } else {
                      message.warning('Your plan does not support LinkedIn Campaigns.')
                    }
                  }}>
                  <img src={channels_linkedin} alt='' />
                </span>
                {arrayChannelsQuestionMark.map((item) => {
                  return (
                    <span
                      key={item}
                      className={channelsQuestionMark ? styles.checked : styles.unchecked}
                      onClick={() => {
                        //setChannelsLinkedin((prev) => !prev)
                      }}>
                      <Tooltip placement='top' title={'Coming Soon'} arrow={true}>
                        <img src={channels_question_mark} alt='' />
                      </Tooltip>
                    </span>
                  )
                })}
              </div>
              <div className={styles.followups_frame}>
                <div className={styles.channels_caption}>
                  Maximum <br></br> Followups:
                </div>
                <span className={styles.followups_select}>
                  <Select
                    // style={{ color: 'red', background: 'gray' }}
                    defaultValue={channelsLinkedin ? 1 : selectedValue}
                    value={channelsLinkedin ? 1 : selectedValue}
                    className='followups_select'
                    suffixIcon={false}
                    // open={false}
                    style={{ width: 35 }}
                    dropdownMatchSelectWidth={false}
                    // placement={placement}
                    onChange={handleSelectChange}
                    options={
                      channelsLinkedin
                        ? [
                            {
                              value: 1,
                              label: '1',
                            },
                          ]
                        : [
                            {
                              value: 0,
                              label: '0',
                            },
                            {
                              value: 1,
                              label: '1',
                            },
                            {
                              value: 2,
                              label: '2',
                            },
                          ]
                    }
                  />
                </span>
                {/* <div className={styles.slider}>
                  <div className={styles.numberOfFollowups}>
                    <span>Maximum followups:</span>
                    <span>{inputValue}</span>
                  </div>
                  <Slider
                    onChange={onChange}
                    tooltip={{ formatter: null }}
                    min={0}
                    max={2}
                    step={0.1}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                    marks={marks}
                  />
                </div> */}
              </div>
              <div className={styles.btn_parent}>
                <span
                  className={styles.manually_frame}
                  onClick={() => {
                    setOpenTemplatesWizardModal(true)
                    onCancel()
                  }}>
                  <img src={manually} alt='' />
                  <div>
                    <div className={styles.btn_caption}>Create Manually</div>
                    <div className={styles.btn_sub_caption}>Start with your own sequence</div>
                  </div>
                </span>
                <Tooltip placement='top' title={'Coming Soon'} arrow={true}>
                  <span className={styles.duplicate_frame}>
                    <img src={duplicate} alt='' />
                    <div>
                      <div className={styles.btn_caption}>Duplicate</div>
                      <div className={styles.btn_sub_caption}>Duplicate existing sequences</div>
                    </div>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        {showErrorMessage && (
          <span style={{ marginRight: '20px', color: 'red', fontWeight: 600 }}>{errorMessage} </span>
        )}
        <Button
          onClick={handleGenerate}
          disabled={textHelp?.trim()?.length === 0 || textBy?.trim()?.length === 0 || textTo?.trim()?.length === 0}>
          {isLoading ? (
            <div className={styles.generateWithAIButton}>
              <span>Generating...</span>
              <LoadingOutlined />
            </div>
          ) : (
            <div className={styles.generateWithAIButton}>
              <span>Generate with AI</span>
              <img src={right_arrow} alt='' />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  )
}

export default OutreachCampaignWithAIModal
