// New updated list, sorted
export const chains = [
  'select-all',
  'multi-chains',
  'evm-compatible',
  'acala',
  'acala (testnet)',
  'agoric',
  'agoric (testnet)',
  'aleo (testnet)',
  'aleph zero (testnet)',
  'alephzero',
  'algorand',
  'algorand (testnet)',
  'aptos',
  'aptos (testnet)',
  'arbitrum',
  'arbitrum (testnet)',
  'archway',
  'astar',
  'aura',
  'aurora',
  'aurora (testnet)',
  'avalanche',
  'avalanche (testnet)',
  'aztec',
  'base',
  'base (testnet)',
  'beldex',
  'bifrost',
  'bitcichain',
  'bitcoin',
  'bitcoin (testnet)',
  'bitcoincash',
  'bitgert',
  'bitindi',
  'bittorrent',
  'bnb chain',
  'bnb chain (testnet)',
  'boba',
  'boba (testnet)',
  'boba_avax',
  'boba_bnb',
  'bone',
  'brise',
  'bsc',
  'btt',
  'bytom',
  'bytomsidechain',
  'caduceus',
  'callisto',
  'canto',
  'carbon',
  'carbon (testnet)',
  'cardano',
  'cardano (testnet)',
  'casper',
  'celo',
  'celo (testnet)',
  'cfx',
  'chihuahua',
  'chiliz',
  'chromia',
  'chromia (testnet)',
  'ckb',
  'clv',
  'comdex',
  'concordium',
  'concordium (testnet)',
  'conflux',
  'core',
  'core dao',
  'cosmos',
  'cosmos (testnet)',
  'coti',
  'crab',
  'crescent',
  'cro',
  'cronos',
  'cronos (testnet)',
  'crossbell',
  'csc',
  'cube',
  'defichain',
  'defikingdoms',
  'dep',
  'deso',
  'deso (testnet)',
  'dexit',
  'dfk',
  'dfk chain',
  'dogechain',
  'echelon',
  'elastos',
  'elrond',
  'empire',
  'energi',
  'energyweb',
  'enuls',
  'eos',
  'eos evm',
  'equilibrium',
  'era',
  'ergo',
  'etc',
  'ethereum',
  'ethereum (testnet)',
  'ethereum classic',
  'ethereumfair',
  'ethereumpow',
  'ethw',
  'europa',
  'everscale',
  'evmos',
  'evmos (testnet)',
  'fantom',
  'fantom (testnet)',
  'fetch.ai',
  'filecoin',
  'filecoin evm',
  'filecoin evm (testnet)',
  'findora',
  'findora (testnet)',
  'flare',
  'flare network',
  'flare network (testnet)',
  'flow',
  'flow (testnet)',
  'flr',
  'fsn',
  'fuel',
  'fuel (testnet)',
  'function x',
  'functionx',
  'fuse',
  'fuse (testnet)',
  'fusion',
  'gatechain',
  'gather',
  'genshiro',
  'gnosis',
  'gnosis chain',
  'gnosis chain (testnet)',
  'gochain',
  'godwoken',
  'godwokenv1',
  'goerli',
  'gravitybridge',
  'grove',
  'harmony',
  'harmony (testnet)',
  'heco',
  'heco (testnet)',
  'hedera',
  'hedera (testnet)',
  'heiko',
  'hive',
  'hmy',
  'hoo',
  'hpb',
  'hydra',
  'icon',
  'icp',
  'immutable x',
  'immutable x (testnet)',
  'immutablex',
  'injective',
  'injective (testnet)',
  'interlay',
  'internet computer',
  'internet computer (testnet)',
  'iost',
  'iotex',
  'iotx',
  'juno',
  'juno (testnet)',
  'kadena',
  'kadena (testnet)',
  'kardia',
  'kardiachain',
  'kardiachain (testnet)',
  'karura',
  'kava',
  'kcc',
  'kcc (testnet)',
  'kekchain',
  'kintsugi',
  'klay',
  'klaytn',
  'klaytn (testnet)',
  'kucoin',
  'kujira',
  'kujira (testnet)',
  'kusama',
  'lachain',
  'lamden',
  'lbry',
  'libre',
  'linea',
  'linea (testnet)',
  'lisk (testnet)',
  'litecoin',
  'loop',
  'loopring',
  'loot',
  'lum network',
  'lung',
  'mada',
  'manta',
  'mantle',
  'mantle (testnet)',
  'map',
  'meta',
  'meter',
  'meter (testnet)',
  'metis',
  'metis (testnet)',
  'migaloo',
  'milkomeda',
  'mina',
  'mina (testnet)',
  'mixin',
  'mnt',
  'monero',
  'mooi',
  'moonbeam',
  'moonbeam (testnet)',
  'moonriver',
  'movr',
  'mtr',
  'multivac',
  'multiversx',
  'multiversx (testnet)',
  'muuchain',
  'nahmii',
  'nautilus',
  'nautilus (testnet)',
  'near',
  'near (testnet)',
  'neo',
  'neo3',
  'neon',
  'nervos',
  'neutron',
  'neutron (testnet)',
  'nitro (testnet)',
  'nova',
  'nuls',
  'oas',
  'oasis',
  'oasis (testnet)',
  'oasys',
  'obyte',
  'octopus network',
  'octopus network (testnet)',
  'okc',
  'okc (testnet)',
  'okexchain',
  'okt',
  'omax',
  'omni (testnet)',
  'oneledger',
  'ont',
  'ontology',
  'ontologyevm',
  'onus',
  'opbnb',
  'opbnb (testnet)',
  'optimism',
  'optimism (testnet)',
  'orai',
  'oraichain',
  'ordinals-brc20',
  'osmosis',
  'osmosis (testnet)',
  'other',
  'pallete',
  'palm',
  'parallel',
  'persistence',
  'picasso',
  'picasso (testnet)',
  'platon',
  'pls',
  'pokt',
  'polis',
  'polkadot',
  'polkadot (testnet)',
  'polygon',
  'polygon (testnet)',
  'polygon zkevm',
  'polygon zkevm (testnet)',
  'polygonzk',
  'proof',
  'proton',
  'pulse',
  'pulsechain',
  'pze',
  'qtum',
  'quai (testnet)',
  'quicksilver',
  'radix',
  'rangers',
  'redlight',
  'reef',
  'reef (testnet)',
  'regen',
  'rei',
  'rei network',
  'reichain',
  'ripple',
  'rollux',
  'ron',
  'ronin',
  'rose',
  'rsk',
  'sbch',
  'scrl',
  'scroll',
  'scroll (testnet)',
  'sdn',
  'secret',
  'secret network',
  'secret network (testnet)',
  'sei network',
  'sei network (testnet)',
  'sgb',
  'shardeum',
  'shardeum (testnet)',
  'shib',
  'shiden',
  'sifchain',
  'skale',
  'smartbch',
  'solana',
  'solana (testnet)',
  'songbird',
  'sora',
  'stacks',
  'stacks (testnet)',
  'stafi',
  'starcoin',
  'stargaze',
  'starkex',
  'starkex (testnet)',
  'starknet',
  'starknet (testnet)',
  'steem',
  'stellar',
  'step',
  'stride',
  'sui',
  'sui (testnet)',
  'swimmer network',
  'sx',
  'sx network',
  'sxnetwork',
  'syscoin',
  'telos',
  'telosevm',
  'tenet',
  'terra',
  'terra 2.0',
  'terra classic',
  'terra2',
  'tezos',
  'tezos (testnet)',
  'theta',
  'thorchain',
  'thundercore',
  'tlchain',
  'tlos',
  'tomb',
  'tombchain',
  'tomochain',
  'ton',
  'ton (testnet)',
  'tron',
  'tron (testnet)',
  'tron10',
  'tron20',
  'ubiq',
  'ultra',
  'ultron',
  'umee',
  'vechain',
  'velas',
  'venom (testnet)',
  'vision',
  'vision chain',
  'vite',
  'vulcanforged',
  'wan',
  'wanchain',
  'waves',
  'wax',
  'wax (testnet)',
  'wemix',
  'xdai',
  'xdc',
  'xdc network',
  'xinfin',
  'xpla',
  'xrp',
  'xrp ledger',
  'zeniq',
  'zilliqa',
  'zilliqa (testnet)',
  'zksync',
  'zksync (testnet)',
  'zora',
  'zyx',
]

export const EVM_COMPATIBLE_CHAINS = [
  'arbitrum',
  'arbitrum (testnet)',
  'aurora',
  'aurora (testnet)',
  'avalanche',
  'avalanche (testnet)',
  'boba',
  'boba (testnet)',
  'boba_avax',
  'boba_bnb',
  'bsc',
  'bnb chain',
  'bnb chain (testnet)',
  'canto',
  'celo',
  'celo (testnet)',
  'cronos',
  'cronos (testnet)',
  'dfk',
  'dfk chain',
  'evmos',
  'evmos (testnet)',
  'fantom',
  'fantom (testnet)',
  'filecoin evm',
  'filecoin evm (testnet)',
  'gnosis',
  'gnosis chain',
  'gnosis chain (testnet)',
  'goerli',
  'heco',
  'heco (testnet)',
  'kcc',
  'kcc (testnet)',
  'linea',
  'linea (testnet)',
  'mantle',
  'mantle (testnet)',
  'metis',
  'metis (testnet)',
  'moonbeam',
  'moonbeam (testnet)',
  'moonriver',
  'nova',
  'okc',
  'okc (testnet)',
  'optimism',
  'optimism (testnet)',
  'palm',
  'polygon',
  'polygon (testnet)',
  'polygon zkevm',
  'polygon zkevm (testnet)',
  'scroll',
  'scroll (testnet)',
  'sepolia',
  'shardeum',
  'shardeum (testnet)',
  'syscoin',
  'telosevm',
  'xdai',
  'xdc',
  'xdc network',
  'zksync',
  'zksync (testnet)',
]

// Old list:
// export const chains = [
//   'select-all',
//   'multi-chains',
//   'bsc',
//   'ethereum',
//   'polygon',
//   'tron',
//   'avalanche',
//   'fantom',
//   'eos',
//   'arbitrum',
//   'solana',
//   'other',
//   'cronos',
//   'optimism',
//   'wax',
//   'harmony',
//   'tezos',
//   'aurora',
//   'near',
//   'klaytn',
//   'moonriver',
//   'moonbeam',
//   'kava',
//   'celo',
//   'immutablex',
//   'thundercore',
//   'heco',
//   'steem',
//   'cardano',
//   'astar',
//   'algorand',
//   'gnosis',
//   'hive',
//   'oasis',
//   'okexchain',
//   'iotex',
//   'terra',
//   'xdai',
//   'dogechain',
//   'metis',
//   'cro',
//   'telos',
//   'flow',
//   'stacks',
//   'zilliqa',
//   'bitcoin',
//   'cosmos',
//   'ontology',
//   'zksync',
//   'aptos',
//   'kucoin',
//   'boba',
//   'smartbch',
//   'klay',
//   'milkomeda',
//   'hedera',
//   'kardiachain',
//   'tron20',
//   'velas',
//   'everscale',
//   'evmos',
//   'ethereumpow',
//   'vechain',
//   'telosevm',
//   'elrond',
//   'era',
//   'canto',
//   'fuse',
//   'shiden',
//   'theta',
//   'waves',
//   'bitgert',
//   'ripple',
//   'ton',
//   'core',
//   'conflux',
//   'sora',
//   'okt',
//   'secret',
//   'terra2',
//   'neo',
//   'kcc',
//   'rsk',
//   'ronin',
//   'bittorrent',
//   'meter',
//   'litecoin',
//   'stellar',
//   'wanchain',
//   'tron10',
//   'ethw',
//   'godwokenv1',
//   'hoo',
//   'juno',
//   'osmosis',
//   'movr',
//   'sui',
//   'mixin',
//   'tomochain',
//   'kardia',
//   'clv',
//   'kujira',
//   'polkadot',
//   'xinfin',
//   'icon',
//   'csc',
//   'starknet',
//   'obyte',
//   'sbch',
//   'fusion',
//   'syscoin',
//   'rei',
//   'comdex',
//   'sgb',
//   'ergo',
//   'xdc',
//   'wan',
//   'elastos',
//   'wemix',
//   'kadena',
//   'filecoin',
//   'hmy',
//   'etc',
//   'mada',
//   'cube',
//   'ontologyevm',
//   'energi',
//   'nuls',
//   'acala',
//   'songbird',
//   'injective',
//   'carbon',
//   'persistence',
//   'tlos',
//   'ont',
//   'step',
//   'kusama',
//   'vulcanforged',
//   'xrp',
//   'findora',
//   'chiliz',
//   'starcoin',
//   'vite',
//   'nova',
//   'iotx',
//   'echelon',
//   'cfx',
//   'godwoken',
//   'proton',
//   'sxnetwork',
//   'platon',
//   'mooi',
//   'lachain',
//   'boba_bnb',
//   'boba_avax',
//   'dfk',
//   'karura',
//   'loop',
//   'crescent',
//   'stargaze',
//   'sdn',
//   'ultron',
//   'tomb',
//   'bitcichain',
//   'brise',
//   'dexit',
//   'thorchain',
//   'bitcoincash',
//   'multivac',
//   'map',
//   'pze',
//   'palm',
//   'flare',
//   'reef',
//   'umee',
//   'interlay',
//   'hydra',
//   'stride',
//   'equilibrium',
//   'genshiro',
//   'icp',
//   'bifrost',
//   'regen',
//   'defichain',
//   'sifchain',
//   'stafi',
//   'sx',
//   'pulsechain',
//   'parallel',
//   'heiko',
//   'empire',
//   'goerli',
//   'bytomsidechain',
//   'neo3',
//   'pallete',
//   'btt',
//   'coti',
//   'ultra',
//   'lbry',
//   'zeniq',
//   'europa',
//   'nahmii',
//   'callisto',
//   'bitindi',
//   'libre',
//   'tlchain',
//   'meta',
//   'lamden',
//   'enuls',
//   'defikingdoms',
//   'mtr',
//   'ckb',
//   'tombchain',
//   'quicksilver',
//   'reichain',
//   'vision',
//   'rangers',
//   'orai',
//   'oasys',
//   'kekchain',
//   'polis',
//   'lung',
//   'omax',
//   'crab',
//   'flr',
// ]
