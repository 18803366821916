import { Alert, Button, Space, Tooltip, Typography } from 'antd'
import hubspot from 'common/assets/svg/hubspot_svg.svg'
import hubspot_orange from 'common/assets/svg/hubspot_svg_orange.svg'
import exportIcon from 'common/assets/svg/export.svg'
import exportIconPurple from 'common/assets/svg/export_purple.svg'
import removeIcon from 'common/assets/svg/remove.svg'
import removeIconPurple from 'common/assets/svg/remove_purple.svg'
import addIcon from 'common/assets/svg/add.svg'
import addIconPurple from 'common/assets/svg/add_purple.svg'
import enrich from 'common/assets/svg/enrich.svg'
import styles from './style.module.scss'
import { useLazyGetLoginHubSpotQuery, useLazyGetPointsStoreQuery } from 'features/settings/state/api/SettingsApi'
import { useState } from 'react'
import HubspotModal from 'features/Outreach/components/Hubspot/HubspotModal'
import CSVModal from 'features/Outreach/components/CSVModal'
import AddToListModal from 'features/Outreach/components/AddToListModal'
import MyContactRemoveFromListModal from '../RemoveFromListModal'

export const ContactTableAction = ({
  selectedContacts,
  isCompany,
  selectedCompaniesObject,
  onDissmiss = () => {},
  currentList,
  refetchData,
  lists,
}: any) => {
  const [refetchLoginHubSpot] = useLazyGetLoginHubSpotQuery()
  const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()
  const [userPoints, setUserPoints] = useState<any>()
  const [openHubspotModal, setOpenHubspotModal] = useState(false)
  const [openCSVModal, setOpenCSVModal] = useState(false)
  const [openAddToListModal, setOpenAddToListModal] = useState(false)
  const [openRemoveFromListModal, setOpenRemoveFromListModal] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [isHubspotHovered, setIsHubspotHovered] = useState(false)
  const [isExportHovered, setIsExportHovered] = useState(false)
  const [isRemoveHovered, setIsRemoveHovered] = useState(false)
  const [isAddHovered, setIsAddHovered] = useState(false)

  const token = localStorage.getItem('hubspot_access_token')

  const handleLoginHubSpot = async () => {
    const result = await refetchLoginHubSpot()

    const newWindow = window.open(result.data.response, '_blank')

    if (newWindow) {
      newWindow.focus()
    }
  }

  return (
    <div className={styles.contactAction}>
      <div className={styles.contactAction__left}>
        <Typography className={styles.contactAction__left__title}>
          {selectedContacts?.length || selectedCompaniesObject?.length} Contact Selected
        </Typography>
        <Typography className={styles.contactAction__left__dismiss} onClick={onDissmiss}>
          Dismiss
        </Typography>
      </div>

      <div className={styles.contactAction__right}>
        <div className={styles.contactAction__right__icon}>
          <img
            src={isHubspotHovered ? hubspot_orange : hubspot}
            alt='hubspot'
            onClick={() => {
              const hubspot_access_token = localStorage.getItem('hubspot_access_token')
              if (hubspot_access_token === 'null' || !hubspot_access_token) {
                setShowAlert(true)
              } else {
                setOpenHubspotModal(true)
                setShowAlert(false)
              }
            }}
            onMouseEnter={() => setIsHubspotHovered(true)}
            onMouseLeave={() => setIsHubspotHovered(false)}
          />

          <img
            src={isExportHovered ? exportIconPurple : exportIcon}
            alt='exportIcon'
            onClick={() => {
              const fetchData = async () => {
                const points_store = await refetchGetPointsStore()
                setUserPoints({
                  points: points_store?.data?.response?.points,
                  pointsStore: points_store?.data?.response?.pointsStore,
                })
              }
              fetchData().catch(console.error)
              setOpenCSVModal(true)
            }}
            onMouseEnter={() => setIsExportHovered(true)}
            onMouseLeave={() => setIsExportHovered(false)}
          />

          <img
            src={isRemoveHovered ? removeIconPurple : removeIcon}
            alt='removeIcon'
            onClick={() => {
              setOpenRemoveFromListModal(true)
            }}
            onMouseEnter={() => setIsRemoveHovered(true)}
            onMouseLeave={() => setIsRemoveHovered(false)}
          />

          <img
            src={isAddHovered ? addIconPurple : addIcon}
            alt='addIcon'
            onClick={() => {
              setOpenAddToListModal(true)
            }}
            onMouseEnter={() => setIsAddHovered(true)}
            onMouseLeave={() => setIsAddHovered(false)}
          />
        </div>

        <Tooltip title='Coming Soon'>
          <div className={styles.contactAction__right__enrich}>
            <img src={enrich} alt='enrich' />
            <Typography className={styles.contactAction__right__enrich__text}>Enrich</Typography>
          </div>
        </Tooltip>

        <Tooltip title='Coming Soon'>
          <Button style={{ background: '#F3F4F6', border: '1px solid #DFE1E6' }}>Add To Campaigns</Button>
        </Tooltip>
      </div>

      {token && (
        <HubspotModal
          open={openHubspotModal}
          onClose={() => {
            setOpenHubspotModal(false)
          }}
          selectedContacts={selectedContacts}
          selectedCompanies={selectedCompaniesObject}
          isCompany={isCompany}
        />
      )}

      {openCSVModal && (
        <CSVModal
          isCompany={isCompany}
          open={openCSVModal}
          onClose={() => {
            setOpenCSVModal(false)
          }}
          userPoints={userPoints}
        />
      )}

      {openAddToListModal && (
        <AddToListModal
          open={openAddToListModal}
          onClose={() => {
            setOpenAddToListModal(false)
          }}
          isCompany={isCompany}
        />
      )}

      {openRemoveFromListModal && (
        <MyContactRemoveFromListModal
          lists={lists}
          open={openRemoveFromListModal}
          onClose={() => {
            setOpenRemoveFromListModal(false)
          }}
          isCompany={isCompany}
          listID={currentList ? currentList?.id : 'exposed_contacts'}
          selectedCompanies={selectedCompaniesObject}
          selectedContacts={selectedContacts}
          refetchData={refetchData}
        />
      )}

      {showAlert && (
        <Alert
          style={{ position: 'absolute', bottom: '69px', right: ' 20px' }}
          message='Enrich HubSpot with Convrt'
          description='To enrich contacts/companies and create/update deals, connecting your HubSpot account with Convrt is required'
          type='info'
          action={
            <Space direction='vertical'>
              <Button
                size='small'
                type='primary'
                onClick={() => {
                  handleLoginHubSpot()
                  setShowAlert(false)
                }}>
                Connect
              </Button>
            </Space>
          }
          closable
          onClose={() => setShowAlert(false)}
        />
      )}
    </div>
  )
}
