import React from 'react'
import { Modal, Switch, Button } from 'antd'
import styles from './ImportLeadsFromCsvModal.module.scss'
// import { useLazyGetLeadsQuery } from 'features/Outreach/state/api/OutreachApi'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
// import { post } from 'common/api/axios'
import FileSaver from 'file-saver'

import CSVImporter from 'common/components/csv-importer/csv-importer'
import csv_icon from 'common/assets/svg/csv_purple.svg'
import { useLazyDownloadCSVQuery } from 'features/Outreach/state/api/OutreachApi'
import { useAppSelector } from 'state'
interface ImportLeadsFromCsvModalProps {
  open: boolean
  onClose: () => void
  onFinish: (campaign_id_tmp: any, campaign_name_tmp: any, csvData: any) => void
  selectedLists?: any
  isLoading?: boolean
  excludeLeadsInOtherCampaigns: boolean
  exclusePreviouslySent: boolean
  // Add the missing property types for setAllCntactsToggle and setFailedCntactsToggle
  setExcludeLeadsInOtherCampaigns: React.Dispatch<React.SetStateAction<boolean>>
  setExclusePreviouslySent: React.Dispatch<React.SetStateAction<boolean>>
}

const ImportLeadsFromCsvModal: React.FC<ImportLeadsFromCsvModalProps> = ({
  open,
  onClose,
  onFinish,
  selectedLists,
  isLoading,
  excludeLeadsInOtherCampaigns,
  exclusePreviouslySent,
  setExcludeLeadsInOtherCampaigns,
  setExclusePreviouslySent,
}) => {
  const [refetcDownloadCSV] = useLazyDownloadCSVQuery()
  const { selected_platform } = useAppSelector((state) => state.outreachCampaign)

  const handleToggleSwitchAllCntactsToggle = () => {
    setExcludeLeadsInOtherCampaigns(!excludeLeadsInOtherCampaigns)
  }

  const handleToggleSwitchFailedCntactsToggle = () => {
    setExclusePreviouslySent(!exclusePreviouslySent)
  }
  // const [refetcGetLeads, { data: leadsData }] = useLazyGetLeadsQuery()
  // const [contactsListIds, setContactsListIds] = useState<any[]>([])
  // const [sources, setSources] = useState<any[]>([])
  // const selectedSources = selectedLists?.map((item: any) => item.source_name)
  // const [credits, setCredits] = useState(0)

  // useEffect(() => {
  //   const getLeads = async () => {
  //     await refetcGetLeads()
  //   }
  //   getLeads()
  // }, [])

  // useEffect(() => {
  //   if (selectedSources) {
  //     setSources(selectedSources)
  //   }
  // }, [selectedLists])

  // const handleCheckboxClick = (event: any, list: any) => {
  //   const { id, name, numberOfTelegramCredits } = list
  //   if (event.target.checked) {
  //     setContactsListIds((prevContactsListIds) => [...prevContactsListIds, id])
  //     setSources((prev) => [...prev, name])
  //     setCredits((prev) => prev + numberOfTelegramCredits)
  //   } else {
  //     setContactsListIds((prevContactsListIds) =>
  //       prevContactsListIds.filter((contactsListIds) => contactsListIds !== id),
  //     )
  //     setSources((prev) => prev.filter((listName) => listName !== name))
  //     setCredits((prev) => (prev - numberOfTelegramCredits < 0 ? 0 : prev - numberOfTelegramCredits))
  //   }
  // }

  return (
    <>
      {open && (
        <Modal
          title={<span>Import CSV file</span>}
          centered
          open={open}
          onCancel={onClose}
          footer={null}
          style={{ minWidth: '530px' }}>
          <div className={styles.container}>
            {isLoading ? (
              <div className={styles.loading}>
                <LoadingBox />
              </div>
            ) : (
              <>
                <img src={csv_icon} alt='' style={{ height: '30px', width: '30px' }} />
                <div className={styles.download_CSV}>
                  <Button
                    onClick={async () => {
                      //downloadCSV
                      const result = await refetcDownloadCSV()
                      const csvData = new Blob([result.error.data], { type: 'text/csv;charset=utf-8;' })
                      FileSaver.saveAs(csvData, 'csv_template.csv')
                    }}>{`Download CSV Template`}</Button>
                </div>
                {/* <div className={styles.download_CSV}>Download CSV Template</div> */}

                <CSVImporter onFinish={onFinish} />
                {/* <Button
              className={styles.Button}
              onClick={() => {
                post('/log', {
                  action: 'Add Lists Campaign From CSV',
                })
                // onFinish(contactsListIds, credits, allCntactsToggle, failedCntac¸tsToggle)
              }}>
              Browse
            </Button> */}
                <div style={{ textAlign: 'center' }}>
                  <div>There is a limit of 5000 rows for CSV uploads.</div>
                  <p>Upload a CSV file with the following fields:</p>
                  {/* <div style={{}}>full_name, company, job_title, telegram_handle, custom_1, custom_2</div> */}
                  <div style={{}}>full_name, company, job_title, handle, custom_1, custom_2</div>
                </div>
              </>
            )}
          </div>
          <div style={{ padding: '15px' }}>
            <div className={styles.switchItem}>
              <Switch
                onChange={handleToggleSwitchAllCntactsToggle}
                checked={excludeLeadsInOtherCampaigns}
                size={'small'}
              />
              <span className={styles.switchLabel}>
                {`Exclude Leads Already Present in Other ${
                  selected_platform ? selected_platform.charAt(0).toUpperCase() + selected_platform.slice(1) : ''
                } Campaigns`}
              </span>
            </div>
            <div className={styles.switchItem}>
              <Switch onChange={handleToggleSwitchFailedCntactsToggle} checked={exclusePreviouslySent} size={'small'} />
              <span className={styles.switchLabel}>
                {` Exclude Leads Previously Sent in Other ${
                  selected_platform ? selected_platform.charAt(0).toUpperCase() + selected_platform.slice(1) : ''
                } Campaigns`}
              </span>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ImportLeadsFromCsvModal
