import React, { useEffect, useState } from 'react'
// import { Button, Layout } from 'antd'
import { Layout } from 'antd'
// import styles from './NewCampaign.module.scss'
// import Canvas from '../NewCampaign/Components/Canvas'
// import Sider from 'antd/es/layout/Sider'
import { Content } from 'antd/es/layout/layout'

import { useLocation } from 'react-router-dom'

import {
  useAppDispatch,
  // useAppSelector
} from 'state'
// import NewCampaignTelegramMessage from '../NewCampaign/Components/SideScreens/Telegram/NewCampaignTelegramMessage'
// import NewCampaignHubspotEnrichment from '../NewCampaign/Components/SideScreens/Hubspot/NewCampaignHubspotEnrichment'
// import NewCampaignIsReplied from '../NewCampaign/Components/SideScreens/Telegram/NewCampaignIsReplied'
// import NewCampaignWait from '../NewCampaign/Components/SideScreens/General/NewCampaignWait'
import {
  SET_SEQUENCE_DATA,
  SET_EDIT_MODE,
  SET_SELECTED_SENDER,
  SET_ENABLE_EDITING,
  SET_TEMPLATE,
  SET_CAMPAIGN_FILTERS,
  SET_CAMPAIGN_DATES_FILTERS,
} from '../state/outreachCampaignSlice'
import EditCampaignHeader from './EditCampaignHeader'
import {
  useLazyGetCampaignByIdQuery,
  useLazySetCampaignRecipientsQuery,
  useLazyGetCampaignsRecipientsQuery,
} from 'features/Outreach/state/api/OutreachApi'
import LeadsTable from '../NewCampaign/Leads/LeadsTable'
import EditCampaignFooter from './EditCampaignFooter'
import Canvas from '../NewCampaign/Components/Canvas'
import Sider from 'antd/es/layout/Sider'
import NewCampaignTelegramMessage from '../NewCampaign/Components/SideScreens/Telegram/NewCampaignTelegramMessage'
import NewCampaignHubspotEnrichment from '../NewCampaign/Components/SideScreens/Hubspot/NewCampaignHubspotEnrichment'
import NewCampaignIsReplied from '../NewCampaign/Components/SideScreens/Telegram/NewCampaignIsReplied'
import NewCampaignWait from '../NewCampaign/Components/SideScreens/General/NewCampaignWait'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import LinkedinMessage from '../NewCampaign/Components/SideScreens/LinkedIn/LinkedinMessage'
import LinkedinLike from '../NewCampaign/Components/SideScreens/LinkedIn/LinkedinLike'
import LinkedinAccepted from '../NewCampaign/Components/SideScreens/LinkedIn/LinkedinAccepted'
import LinkedinConnect from '../NewCampaign/Components/SideScreens/LinkedIn/LinkedinConnect'

const EditCampaign: React.FC = () => {
  // eslint-disable-next-line
  const [currentSideBar, setCurrentSideBar] = useState({ type: '', nodeId: '' })
  // const { template } = useAppSelector((state) => state.outreachCampaign)
  const [refetchGetCampaignById] = useLazyGetCampaignByIdQuery()
  const [currentStep, setCurrentStep] = useState(0)
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const campaignData = location.state

  // eslint-disable-next-line
  const [refetcSetCampaignRecipients, { data: campaignRecipientsData }] = useLazySetCampaignRecipientsQuery()
  const [refetcGetRecipients] = useLazyGetCampaignsRecipientsQuery()

  const [nodesData, setNodesData] = useState({
    campaign_name: campaignData.campaign_name,
    campaign_description: campaignData.description,
    status: campaignData.status,
    steps: campaignData.steps,
  })
  // eslint-disable-next-line
  const [template, setTemplate] = useState(0)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(SET_SEQUENCE_DATA(nodesData))
    dispatch(SET_CAMPAIGN_DATES_FILTERS({}))
    dispatch(SET_CAMPAIGN_FILTERS({}))
    // dispatch(SET_EDIT_MODE(true))
  }, [])

  useEffect(() => {
    const getCampaign = async () => {
      const { data } = await refetchGetCampaignById({ campaign_id: campaignData.campaign_id })

      const campaign_name = data.response.campaign_name
      const campaign_description = data.response.description
      const status = data.response.status
      const steps = JSON.parse(data.response.steps)
      const platform = steps?.[1]?.type === 'linkedin_connect' ? 'linkedin' : 'telegram'

      // HARDCODED _ CHANGE IF WANT MODULAR
      if (steps?.[1]?.type === 'linkedin_connect') {
        setTemplate(3)
        dispatch(SET_TEMPLATE(3))
      } else {
        setTemplate(Object.keys(steps).length === 2 ? 0 : Object.keys(steps).length === 4 ? 1 : 2)
      }

      const updatedData = {
        campaign_name: campaign_name,
        campaign_description: campaign_description,
        status: status,
        steps: steps,
      }
      setNodesData(updatedData)
      dispatch(SET_SEQUENCE_DATA(updatedData))
      dispatch(SET_EDIT_MODE(true))
      dispatch(SET_ENABLE_EDITING(status === 'running' || status === 'failed' ? false : true))
      // dispatch(SET_ENABLE_EDITING(false))
      if (platform === 'linkedin') {
        for (const step in steps) {
          if (steps.hasOwnProperty(step)) {
            const currentStep = steps[step]
            if (currentStep.data && currentStep.data.sender) {
              dispatch(SET_SELECTED_SENDER(currentStep.data.sender))
              break
            }
          }
        }
      } else {
        dispatch(SET_SELECTED_SENDER(steps?.[1]?.data?.sender))
      }
      setIsLoading(false)
    }

    const getRecipients = async () => {
      await refetcGetRecipients()
    }

    getCampaign()
    getRecipients()
  }, [])

  // eslint-disable-next-line
  const siderStyle: React.CSSProperties = {
    lineHeight: '120px',
    color: 'black',
    backgroundColor: 'white',
    boxShadow: '#ececec -5px 0px 9px 0px',
    borderRadius: '8px 0px 0px 8px',
    zIndex: 0,
    overflow: 'auto',
    // maxHeight: 100,
    margin: '2px 0px',
  }
  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: 'black',
    // display: 'flex',
  }

  return (
    <Layout>
      <EditCampaignHeader
        nodesData={nodesData}
        setNodesData={setNodesData}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        campaignId={campaignData?.campaign_id}
      />
      {!isLoading && currentStep === 0 && (
        <Layout>
          <Content style={contentStyle}>
            <Canvas setCurrentSideBar={setCurrentSideBar} template={template} />
          </Content>
          <Sider width='500px' style={siderStyle}>
            {currentSideBar.type === 'send_tg_message' && (
              <NewCampaignTelegramMessage
                currentSideBar={currentSideBar}
                nodesData={nodesData}
                setNodesData={setNodesData}
              />
            )}
            {currentSideBar.type === 'send_linkedin_message' && (
              <LinkedinMessage currentSideBar={currentSideBar} nodesData={nodesData} setNodesData={setNodesData} />
            )}
            {currentSideBar.type === 'enrich_hubspot' && (
              <NewCampaignHubspotEnrichment
                currentSideBar={currentSideBar}
                nodesData={nodesData}
                setNodesData={setNodesData}
              />
            )}
            {currentSideBar.type === 'is_message_replied' && (
              <NewCampaignIsReplied currentSideBar={currentSideBar} nodesData={nodesData} setNodesData={setNodesData} />
            )}
            {currentSideBar.type === 'is_wait' && (
              <NewCampaignWait currentSideBar={currentSideBar} nodesData={nodesData} setNodesData={setNodesData} />
            )}
            {currentSideBar.type === 'linkedin_like_post' && (
              <LinkedinLike currentSideBar={currentSideBar} nodesData={nodesData} setNodesData={setNodesData} />
            )}
            {currentSideBar.type === 'is_linkedin_connected' && (
              <LinkedinAccepted currentSideBar={currentSideBar} nodesData={nodesData} setNodesData={setNodesData} />
            )}
            {currentSideBar.type === 'linkedin_connect' && (
              <LinkedinConnect currentSideBar={currentSideBar} nodesData={nodesData} setNodesData={setNodesData} />
            )}
          </Sider>
        </Layout>
      )}
      {!isLoading && currentStep === 1 && (
        <Layout>
          <Content style={contentStyle}>
            <LeadsTable
              data={campaignRecipientsData ? campaignRecipientsData?.response : []}
              campaign_id={campaignData.campaign_id}
              campaign_name={campaignData.campaign_name}
            />
          </Content>
        </Layout>
      )}
      {isLoading && (
        <Layout>
          <Content style={contentStyle}>
            <LoadingBox />
          </Content>
        </Layout>
      )}
      {/* <NewCampaignFooter currentStep={currentStep} setCurrentStep={setCurrentStep} /> */}
      <EditCampaignFooter
        id={campaignData.campaign_id}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        nodesData={nodesData}
        setNodesData={setNodesData}
      />
    </Layout>
  )
}

export default EditCampaign
