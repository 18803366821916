import { useEffect, useState } from 'react'
import { Checkbox, Input, Spin } from 'antd'
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'

import { telegramGet } from 'common/api/axios'

import styles from './OnBoardingPageE.module.scss'
import { useAuth } from 'common/hooks/useAuth.hooks'

interface OnBoardingPageEProps {
  handleGroupsChange: any
  handleContactsChange: any
  formData: any
  selectedGroups: any
  selectedContacts: any
}

const OnBoardingPageE = ({
  handleGroupsChange,
  handleContactsChange,
  formData,
  selectedGroups,
  selectedContacts,
}: OnBoardingPageEProps) => {
  const { userDetails } = useAuth()

  const [groups, setGroups] = useState<any>([])
  const [contacts, setContacts] = useState<any>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredGroups, setFilteredGroups] = useState<any>([])
  const [filteredContacts, setFilteredContacts] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const isFilteredContacts =
    searchValue || filteredContacts?.filter((contact: any) => selectedContacts?.includes(contact?.id))?.length > 0
  const isFilteredGroups =
    searchValue || filteredGroups?.filter((group: any) => selectedGroups?.includes(group?.id))?.length > 0

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value)
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const fetchedGroups = await telegramGet('/getGroups', {
        params: {
          email: userDetails?.email,
          number: formData?.telegramNumber,
          area_code: formData?.prefix,
        },
      })

      const fetchedContacts = await telegramGet('/getContacts', {
        params: {
          email: userDetails?.email,
          number: formData?.telegramNumber,
          area_code: formData?.prefix,
        },
      })

      setGroups(fetchedGroups.data)
      setContacts(fetchedContacts.data)
      setLoading(false)
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (groups) {
      setFilteredGroups(
        groups?.filter(
          (group: any) =>
            group.title?.toLowerCase().indexOf(searchValue.trim()?.toLowerCase()) >= 0 ||
            selectedGroups?.includes(group?.id),
        ),
      )
    }

    if (contacts) {
      setFilteredContacts(
        contacts?.filter(
          (contact: any) =>
            `${contact.firstname || ''} ${contact.lastname || ''}`
              ?.toLowerCase()
              .indexOf(searchValue.trim()?.toLowerCase()) >= 0 || selectedContacts?.includes(contact.id),
        ),
      )
    }
  }, [searchValue, groups, contacts])

  return (
    <div className={styles.container}>
      <div className={styles.containerText}>
        <p>{'We Care About Your Privacy'}</p>
        <section>
          {`If there are any groups, chats or contacts that you wish to exclude from importing into your Convrt account, select them now.`}
        </section>
      </div>
      <div className={styles.searchContainer}>
        <Input
          prefix={<SearchOutlined style={{ color: '#d9d9d9', marginRight: '2px' }} />}
          onChange={handleSearch}
          placeholder={`Search Groups, Chats or Contacts`}
          defaultValue={searchValue}
          disabled={loading}
        />
      </div>
      {loading ? (
        <div className={styles.loading}>
          <span>Gathering Groups, Chats & Contacts Names</span>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : (
        <div className={styles.resultsContainer}>
          {!isFilteredGroups && !isFilteredContacts && (
            <div className={styles.loading}>
              <span>Syncing Completed. Please type your desired names.</span>
            </div>
          )}
          {filteredGroups?.length > 0 && (
            <div className={styles.groupsContainer}>
              {isFilteredGroups && <h5>{`Groups & Chats`}</h5>}
              <Checkbox.Group
                options={
                  searchValue
                    ? filteredGroups?.map((group: any) => ({
                        value: group.id,
                        label: group.title,
                      }))
                    : filteredGroups
                        ?.filter((group: any) => selectedGroups?.includes(group?.id))
                        ?.map((group: any) => ({
                          value: group.id,
                          label: group.title,
                        }))
                }
                value={selectedGroups}
                onChange={handleGroupsChange}
                className={styles.options}
              />
            </div>
          )}
          {filteredContacts?.length > 0 && (
            <div className={styles.contactsContainer}>
              {isFilteredContacts && <h5>{`Contacts`}</h5>}
              <Checkbox.Group
                options={
                  searchValue
                    ? filteredContacts?.map((contact: any) => ({
                        value: contact.id,
                        label: `${contact.firstname || ''} ${contact.lastname || ''}`,
                      }))
                    : filteredContacts
                        ?.filter((contact: any) => selectedContacts?.includes(contact?.id))
                        ?.map((contact: any) => ({
                          value: contact.id,
                          label: `${contact.firstname || ''} ${contact.lastname || ''}`,
                        }))
                }
                value={selectedContacts}
                onChange={handleContactsChange}
                className={styles.options}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default OnBoardingPageE
