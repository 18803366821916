import classNames from 'classnames'

import styles from './AnalyzingData.module.scss'

const AnalyzingData = ({ completion }: any) => (
  <>
    <h3>{'Analyzing Data...'}</h3>
    <div style={{ textAlign: 'center' }}>
      {'There is a lot of data to import and analyze, it may take a few hours to complete, so please bear with us.'}
    </div>
    <div className={styles.progressContainer}>
      <div className={classNames(styles.progress, styles.progressTotal)}>
        <div className={classNames(styles.progress, styles.progressLoading)} style={{ width: `${completion}%` }} />
      </div>
      <h5>{`${completion}%`}</h5>
    </div>
  </>
)

export default AnalyzingData
