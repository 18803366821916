import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import PricingForm from './PricingForm'
import styles from './BuyConvrt.module.scss'
import BuyCredits from './BuyCredits'
import { get } from 'common/api/axios'
import ThankYouPage from './ThankYouPage'
import left_arrow from 'common/assets/svg/left_arrow.svg'
import BuyConvrtErrorModal from './BuyConvrtErrorModal'
import {
  useLazyBuyMoreSeatsQuery,
  useLazyGetAllUsersQuery,
  useLazyGetPaymentDetailsQuery,
} from 'features/settings/state/api/SettingsApi'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'common/hooks/useAuth.hooks'
import UpgradePlanModal from 'features/settings/Pages/Components/UpgradePlanModal'

const planMapping: any = {
  free_trial: 0,
  go_to_market: 1,
  connect_plus: 2,
  market_dominance: 3,
}

const BuyConvrt: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [plan, setPlan] = useState<any>({})
  const [quantity, setQuantity] = useState(1)
  const [TGQuantity, setTGQuantity] = useState(0)
  const [planDuration, setPlanDuration] = useState<any>('')
  const [nextButton, setNextButton] = useState('Pay Now')
  const [convrtPoints, setConvrtPoints] = useState(0)
  const [paymentStatus, setPaymentStatus] = useState<any>(null)
  const { userDetails, isPaidTrialUser, isBetaOrTrialUser } = useAuth()
  // eslint-disable-next-line
  const [isPaid, setIsPaid] = useState(false)
  // const [refetchUpgradeUserPlan] = useLazyUpgradeUserPlanQuery()
  // eslint-disable-next-line
  const [sessionExpired, setSessionExpired] = useState(false)
  const [invalidSession, setInvalidSession] = useState(false)
  // eslint-disable-next-line
  const [sessionId, setSessionId] = useState<any>('')
  const [loadingPage, setLoadingPage] = useState(false)
  const [errorUpgradingUser, setErrorUpgradingUser] = useState(false)
  const [openOwnerModal, setOpenOwnerModal] = useState(false)
  // const { userDetails } = useAuth()
  const [planType, setPlanType] = useState<'monthly' | 'three_months' | 'yearly'>('three_months')
  // const [refetchUpgradePlan] = useLazyUpgradePlanQuery()
  const [refetchBuyMoreSeats] = useLazyBuyMoreSeatsQuery()
  const [openPaymentSummaryModal, setOpenPaymentSummaryModal] = useState(false)
  // eslint-disable-next-line
  const [totalPrice, setTotalPrice] = useState(0)
  const [refetchGetPaymentDetails, { data: paymentData }] = useLazyGetPaymentDetailsQuery()
  const [refetchGetUserDetails, { data: dataUserDetails }] = useLazyGetUserDetailsQuery()
  const [refetchGetAllUsers, { data: dataAllUsers }] = useLazyGetAllUsersQuery()
  // eslint-disable-next-line
  const [isPaymentLoading, setIsPaymentLoading] = useState(false)
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false)

  const navigate = useNavigate()

  // const [numberOfSeats, setNumeberOfSeats] = useState({})
  // const [disableFinish, setDisableFinish] = useState(true)
  // const [disablePrevious, setDisablePrevious] = useState(false)
  // const [planURL, setPlanURL] = useState('')
  // const [openNewPageModal, setOpenNewPageModal] = useState(false)

  const handlePayNow = async () => {
    if (!plan?.id) {
      return message.error('Please choose a plan to continue.')
    }
    if (plan?.id === 'ai_meeting_setter') {
      return window.open('https://calendly.com/roy-convrt/demo-convrt', '_blank')
    }
    if (!dataUserDetails.company_admin && !dataUserDetails?.plan_type?.toLowerCase().includes('trial')) {
      return setOpenOwnerModal(true)
    }
    if (dataUserDetails?.premium_id) {
      setOpenPaymentSummaryModal(true)
    } else {
      try {
        const { data } = await refetchBuyMoreSeats({
          plan: plan?.id,
          billing_cycle: planDuration,
          convrtPoints: convrtPoints,
          quantity: quantity,
          managedAccount: TGQuantity,
        })
        if (data?.permissions === false) {
          setOpenOwnerModal(true)
        }
        const success_url = data?.url
        if (success_url) {
          window.open(success_url, '_self')
        } else {
          //
        }
      } catch (e: any) {
        console.error(e)
      }
    }
  }

  // const handlePayFromModal = async () => {
  //   try {
  //     setIsPaymentLoading(true)
  //     const { data } = await refetchBuyMoreSeats({
  //       plan: plan?.id,
  //       billing_cycle: planDuration,
  //       convrtPoints: convrtPoints,
  //       quantity: quantity,
  //     })
  //     if (data) {
  //       if (data?.permissions === false) {
  //         setOpenOwnerModal(true)
  //       } else {
  //         setIsPaymentCompleted(true)
  //       }
  //       setIsPaymentLoading(false)
  //     }
  //     const success_url = data?.url
  //     if (success_url) {
  //       window.open(success_url, '_self')
  //     } else {
  //       //
  //     }
  //   } catch (e: any) {
  //     console.error(e)
  //   }
  // }

  const getPaymentStatus = async (session_id: string) => {
    try {
      const data = await get('/user/getPaymentStatus', { params: { session_id: session_id } })
      setPaymentStatus(data.data.order)
      setIsPaid(data.data.status === 'paid')
      setSessionId(data.data.sessionId)
      setLoadingPage(false)
    } catch (e) {
      setSessionExpired(true)
      setInvalidSession(true)
      setLoadingPage(false)
      setErrorUpgradingUser(true)
      console.error(e)
    }
  }

  // const assignSeats = async () => {
  //   if (!isPaid || sessionExpired || invalidSession) {
  //     setLoadingPage(false)
  //     return
  //   }
  //   const user = await get(`/user/userDetails`)
  //   if (user.data.subscription_type !== 'BETA') {
  //     setLoadingPage(false)
  //     return
  //   }
  //   setLoadingPage(false)
  // }

  // const upgradePlan = async () => {
  //   try {
  //     if (!isPaid || sessionExpired || invalidSession) {
  //       setLoadingPage(false)
  //       return
  //     }
  //     const user = await get(`/user/userDetails`)
  //     if (user.data.subscription_type !== 'BETA') {
  //       setLoadingPage(false)
  //       return
  //     }
  //     setLoadingPage(true)
  //     const plan = paymentStatus.plan.planType
  //     const number_of_seats = paymentStatus.plan.quantity
  //     const subscription_months = paymentStatus.plan.duration
  //     const convrtPoints = paymentStatus?.points
  //     const { data } = await refetchUpgradeUserPlan({
  //       session_id: sessionId,
  //       plan: plan,
  //       number_of_seats: number_of_seats,
  //       subscription_months: subscription_months,
  //       convrtPoints: convrtPoints,
  //     })

  //       session_id: sessionId,
  //       plan: plan,
  //       number_of_seats: number_of_seats,
  //       subscription_months: subscription_months,
  //       convrtPoints: convrtPoints,
  //     })

  //     if (!data) {
  //       setErrorUpgradingUser(true)
  //       setLoadingPage(false)
  //       return
  //     }

  //     const {
  //       id,
  //       name,
  //       email,
  //       addons,
  //       telegram,
  //       outreach,
  //       did_funnel,
  //       funnelData,
  //       limit_number,
  //       company_admin,
  //       subscription_type,
  //       subscription_created_at,
  //       subscription_days,
  //       plan_type,
  //     } = data?.user || {}

  //     refetchUserLite({
  //       id,
  //       name,
  //       email,
  //       addons,
  //       telegram,
  //       outreach,
  //       did_funnel,
  //       funnelData,
  //       limit_number,
  //       company_admin,
  //       subscription_type,
  //       subscription_created_at,
  //       subscription_days,
  //       plan_type,
  //     })
  //     setLoadingPage(false)
  //   } catch (e) {
  //     message.error('Something went wrong! Please contact us.')
  //     console.error(e)
  //     setLoadingPage(false)
  //     setErrorUpgradingUser(true)
  //   }
  // }

  // useEffect(() => {
  //   if (userDetails.subscription_type !== 'BETA') {
  //     navigate('/companies')
  //   }
  //   const checkUserType = async () => {
  //     const user = await get(`/user/userDetails`)
  //     if (user.data.subscription_type !== 'BETA') {
  //       return navigate('/companies')
  //     }
  //   }
  //   checkUserType()
  // }, [])

  const handleClosePaymentModal = () => {
    setOpenPaymentSummaryModal(false)
    setIsPaymentCompleted(false)
    if (isPaymentCompleted) {
      navigate('/settings')
    }
  }

  const renderButton = (optionId: any) => {
    if (plan?.id === 'ai_meeting_setter') {
      setNextButton('Contact Us')
    } else if (isBetaOrTrialUser || isPaidTrialUser) {
      setNextButton('Pay Now')
    } else {
      const currentPlanIndex = planMapping[userDetails?.plan_type]
      const optionIndex = planMapping[optionId]

      if (optionIndex === currentPlanIndex) {
        setNextButton('Upgrade')
      } else if (optionIndex > currentPlanIndex) {
        setNextButton('Upgrade')
      } else {
        setNextButton('Downgrade')
      }
    }
  }

  useEffect(() => {
    // console.log(plan)
    // if (plan?.id === 'ai_meeting_settler') {
    //   console.log('here')
    //   setNextButton('Contact Us')
    // } else if (nextButton !== 'Pay Now') {
    //   setNextButton('Pay Now')
    // }
    renderButton(plan?.id)
  }, [plan])

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const session_id = query.get('session_id')
    if (session_id) {
      setLoadingPage(true)
      setCurrentStep(1)
      getPaymentStatus(session_id)
    }

    if (query.get('canceled')) {
      message.warning('Payment cancelled.')
    }
    // const checkUser = async () => {
    //   const user = await get(`/user/userDetails`)
    //   if (user.data.subscription_type !== 'BETA') {
    //     return
    //   } else {
    //     const query = new URLSearchParams(window.location.search)
    //     const session_id = query.get('session_id')
    //     if (session_id) {
    //       setLoadingPage(true)
    //       setCurrentStep(1)
    //       getPaymentStatus(session_id)
    //     }

    //     if (query.get('canceled')) {
    //     }
    //   }
    // }
    // checkUser()
  }, [])

  useEffect(() => {
    if (paymentStatus !== null) {
      // upgradePlan()
      // assignSeats()
    }
  }, [paymentStatus])

  useEffect(() => {
    const getCardDetails = async () => {
      await refetchGetPaymentDetails()
    }
    const getUserDetails = async () => {
      await refetchGetUserDetails()
    }
    const getAllUsers = async () => {
      await refetchGetAllUsers()
    }
    getCardDetails()
    getUserDetails()
    getAllUsers()
  }, [])

  return (
    <div style={{ display: 'unset', flexGrow: 1, backgroundColor: 'grey !important' }}>
      <div className={styles.container}>
        {currentStep === 0 && (
          <>
            <div
              className={styles.backButton}
              onClick={() => {
                navigate('/settings')
              }}>
              <img src={left_arrow} alt='left' />
              <span>Back to Settings</span>
            </div>
            <div className={styles.title}>
              <p>Supercharge Your Sales With Convrt</p>
            </div>
            <PricingForm
              setPlan={setPlan}
              setPlanDuration={setPlanDuration}
              planType={planType}
              setPlanType={setPlanType}
              setQuantity={setQuantity}
              setTGQuantity={setTGQuantity}
              setNextButton={setNextButton}
              TGQuantity={TGQuantity}
            />

            <div className={styles.credits}>
              <BuyCredits
                setConvrtPoints={setConvrtPoints}
                duration={planDuration}
                nextButton={nextButton}
                handlePayment={handlePayNow}
                plan={plan}
                planType={planType}
                quantity={quantity}
                TGQuantity={TGQuantity}
                setTotalPrice={setTotalPrice}
              />
            </div>
          </>
        )}
        {currentStep === 1 && (
          <ThankYouPage
            loadingPage={loadingPage}
            errorUpgradingUser={errorUpgradingUser}
            invalidSession={invalidSession}
          />
        )}
        {openOwnerModal && (
          <BuyConvrtErrorModal
            open={openOwnerModal}
            onClose={() => {
              setOpenOwnerModal(false)
            }}
          />
        )}
        {/* {openPaymentSummaryModal && (
          <PaymentSummaryModal
            open={openPaymentSummaryModal}
            onClose={handleClosePaymentModal}
            selectedPlan={plan?.id}
            billingCycle={planDuration}
            convrtPoints={convrtPoints * 10}
            quantity={quantity}
            defaultPaymentMethod={paymentData}
            totalPrice={totalPrice}
            onPay={handlePayFromModal}
            isLoading={isPaymentLoading}
            isCompleted={isPaymentCompleted}
          />
        )} */}
        {openPaymentSummaryModal && (
          <UpgradePlanModal
            open={openPaymentSummaryModal}
            onClose={handleClosePaymentModal}
            defaultPaymentMethod={paymentData}
            usersToUpgrade={dataAllUsers}
            billingCycle={planDuration}
            selectedPlan={plan?.id}
            buttonText={nextButton}
            // userToUpgrade={userToUpgrade}
          />
        )}
      </div>
    </div>
  )
}

export default BuyConvrt
