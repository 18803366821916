import { CheckboxProps, message } from 'antd'
import { Checkbox, Divider, Select } from 'antd'
import { HUBSPOT, HUBSPOT_CONTACT_OPTIONS } from 'common/constants/hubspot.constants'
import { hubspotDealOwnerType } from 'features/settings/settings.type'
import {
  useAddHubspotSettingMutation,
  useLazyGetHubspotDealOwnerQuery,
  useLazyGetHubspotSettingQuery,
} from 'features/settings/state/api/SettingsApi'
import { useEffect, useMemo } from 'react'
import styles from './styles.module.scss'

const SettingsBox = () => {
  const [refetchGetHubspotSetting, { data: hubspotSetting }] = useLazyGetHubspotSettingQuery()
  const [saveHubspotSetting, { isFetching: isRefetchAddSetting }] = useAddHubspotSettingMutation()
  const [refetchGetHubspotDealOwner, { data: hubspotDealOwner, isFetching: isRefetchHubspotDealOwner }] =
    useLazyGetHubspotDealOwnerQuery()
  const { action_type, update_contact_chat_activity, contact_owner_id } = hubspotSetting?.response || {}
  useEffect(() => {
    const getHubspotSetting = () => refetchGetHubspotSetting()
    const getHubspotDealOwner = () => refetchGetHubspotDealOwner()
    getHubspotDealOwner()
    getHubspotSetting()
  }, [])

  console.log({ hubspotSetting })

  const hubspotDealOwnerOptions = useMemo(() => {
    return hubspotDealOwner?.response.map((option: hubspotDealOwnerType) => ({
      label: `${option?.firstName} / ${option?.email}`,
      value: option.id,
    }))
  }, [hubspotDealOwner])

  const handleChangeProspect = (value: string) => {
    saveHubspotSetting({
      ...hubspotSetting.response,
      action_type: value,
    })
      .unwrap()
      .then(() => {
        refetchGetHubspotSetting()
        message.success('Hubspot settings added successfully')
      })
      .catch(() => message.error('Something went wrong'))
  }

  const handleChangeContactOwner = (value: string) => {
    if (value) console.log(value)

    const payload = {
      ...hubspotSetting.response,
      contact_owner_id: value,
    }
    saveHubspotSetting(payload)
      .then(() => {
        refetchGetHubspotSetting()
        message.success('Hubspot settings added successfully')
      })
      .catch(() => message.error('Something went wrong'))
  }

  const onChange: CheckboxProps['onChange'] = (e) => {
    saveHubspotSetting({
      ...hubspotSetting.response,
      update_contact_chat_activity: e.target.checked,
    })
      .unwrap()
      .then(() => {
        refetchGetHubspotSetting()
        message.success('Hubspot settings added successfully')
      })
      .catch(() => message.error('Something went wrong'))
  }

  return (
    <div className={styles.section}>
      <label className={styles.nameSection}>Settings</label>
      <Divider />

      <div className={styles.body}>
        <div className={styles.selectCustomized}>
          <label>Create Hubspot Contact</label>
          <Select
            defaultValue={HUBSPOT.repliedProspect}
            options={HUBSPOT_CONTACT_OPTIONS}
            onChange={handleChangeProspect}
            value={action_type}
            disabled={isRefetchAddSetting}
          />
        </div>

        <div className={styles.selectCustomized}>
          <label>Contact Owner</label>

          <Select
            options={hubspotDealOwnerOptions}
            onChange={handleChangeContactOwner}
            value={contact_owner_id}
            loading={isRefetchHubspotDealOwner}
            disabled={isRefetchAddSetting}
          />
        </div>

        <Checkbox
          defaultChecked
          checked={update_contact_chat_activity}
          onChange={onChange}
          disabled={isRefetchAddSetting}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontWeight: 500, color: '#000' }}>Log conversation into contact activitiy</span>
            <span style={{ color: 'rgba(9, 30, 66, 0.60)', paddingBottom: 60 }}>
              Track Telegram messages in contact's activity
            </span>
          </div>
        </Checkbox>
      </div>
    </div>
  )
}

export default SettingsBox
