import React, { useEffect, useState } from 'react'
// import { Button, Layout } from 'antd'
import { Layout } from 'antd'

import NewCampaignHeader from './NewCampaignHeader'
import NewCampaignFooter from './NewCampaignFooter'
// import styles from './NewCampaign.module.scss'
import Canvas from './Components/Canvas'
import Sider from 'antd/es/layout/Sider'
import { Content } from 'antd/es/layout/layout'

import Leads from './Leads/Leads'
import { useAppDispatch, useAppSelector } from 'state'
import NewCampaignTelegramMessage from './Components/SideScreens/Telegram/NewCampaignTelegramMessage'
import NewCampaignHubspotEnrichment from './Components/SideScreens/Hubspot/NewCampaignHubspotEnrichment'
import NewCampaignIsReplied from './Components/SideScreens/Telegram/NewCampaignIsReplied'
import NewCampaignWait from './Components/SideScreens/General/NewCampaignWait'
import {
  SET_CAMPAIGN_DATES_FILTERS,
  SET_CAMPAIGN_FILTERS,
  SET_LOAD_ANIMATION,
  SET_SEQUENCE_DATA,
} from '../state/outreachCampaignSlice'
import LinkedinLike from './Components/SideScreens/LinkedIn/LinkedinLike'
import LinkedinAccepted from './Components/SideScreens/LinkedIn/LinkedinAccepted'
import LinkedinConnect from './Components/SideScreens/LinkedIn/LinkedinConnect'
import LinkedinMessage from './Components/SideScreens/LinkedIn/LinkedinMessage'

const NewCampaign: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [currentSideBar, setCurrentSideBar] = useState({ type: '', nodeId: '' })
  const { template, ai_generated_data } = useAppSelector((state) => state.outreachCampaign)
  const [delay, setDelay] = useState(0)

  // telegram
  const tempZero = {
    campaign_name: '',
    campaign_description: '',
    status: 'draft',
    steps: {
      '1': {
        type: 'send_telegram_message',
        data: {
          messages: [ai_generated_data['1']?.message],
        },
      },
      '2': {
        type: 'is_message_replied',
        data: {
          duration: 3,
          units: 'days',
          type: 1,
        },
      },
    },
  }

  // telegram
  const tempTwo = {
    campaign_name: '',
    campaign_description: '',
    status: 'draft',
    steps: {
      '1': {
        type: 'send_telegram_message',
        data: {
          messages: [ai_generated_data['1']?.message],
        },
      },
      '2': {
        type: 'is_message_replied',
        data: {
          duration: 3,
          units: 'days',
          type: 1,
        },
      },
      '2_1': {
        type: 'is_wait',
        data: {
          duration: 0,
          units: 'days',
          type: 1,
        },
      },
      '3_1': {
        type: 'send_telegram_message',
        data: {
          messages: [ai_generated_data['3_1']?.message],
        },
      },
      '4_1': {
        type: 'is_message_replied',
        data: {
          duration: 3,
          units: 'days',
          type: 1,
        },
      },
      '5_1': {
        type: 'is_wait',
        data: {
          duration: 0,
          units: 'days',
          type: 1,
        },
      },
      '6_1': {
        type: 'send_telegram_message',
        data: {
          messages: [ai_generated_data['6_1']?.message],
        },
      },
    },
  }

  // data.action === 'send_tg_message'
  // ? step_send_message
  // : data.action === 'send_linkedin_message'
  // ? step_send_message
  // : data.action === 'enrich_hubspot'
  // ? step_enrich_hubspot
  // : data.action === 'is_message_replied'
  // ? condition_is_message_replied
  // : data.action === 'is_wait'
  // ? condition_wait
  // : data.action === 'linkedin_connect'
  // ? step_connect
  // : data.action === 'linkedin_like_post'
  // ? step_like
  // : data.action === 'is_linkedin_connected'
  // ? condition_unknown
  // : ''

  // linkedin
  const tempThree = {
    campaign_name: '',
    campaign_description: '',
    status: 'draft',
    steps: {
      '1': {
        type: 'linkedin_connect',
        data: {},
        previous_step_id: null,
      },
      '2': {
        type: 'is_linkedin_connected',
        data: {
          duration: 3,
          units: 'days',
          type: 1,
        },
        previous_step_id: '1',
      },
      '3_1': {
        type: 'linkedin_like_post',
        data: {
          max_posts: 2,
        },
        previous_step_id: '2',
      },
      '4_1': {
        type: 'is_wait',
        data: {
          duration: 2,
          units: 'days',
          type: 1,
        },
        previous_step_id: '3_1',
      },
      '5_1': {
        type: 'send_linkedin_message',
        data: {
          messages: [ai_generated_data['5_1']?.message],
          // messages: [],
        },
        previous_step_id: '4_1',
      },
      '6_1': {
        type: 'is_message_replied',
        data: {
          duration: 3,
          units: 'days',
          type: 1,
        },
        previous_step_id: '5_1',
      },
      '7_1': {
        type: 'send_linkedin_message',
        data: {
          messages: [ai_generated_data['7_1']?.message],
          // messages: [],
        },
        previous_step_id: '6_1',
      },
    },
  }

  // telegram
  const tempOne = {
    campaign_name: '',
    campaign_description: '',
    status: 'draft',
    steps: {
      '1': {
        type: 'send_telegram_message',
        data: {
          messages: [ai_generated_data['1']?.message],
        },
      },
      '2': {
        type: 'is_message_replied',
        data: {
          duration: 3,
          units: 'days',
          type: 1,
        },
      },
      '2_1': {
        type: 'is_wait',
        data: {
          duration: 0,
          units: 'days',
          type: 1,
        },
      },
      '3_1': {
        type: 'send_telegram_message',
        data: {
          messages: [ai_generated_data['3_1']?.message],
        },
      },
    },
  }

  const [nodesData, setNodesData] = useState(
    template === 0
      ? tempZero
      : template === 1
      ? tempOne
      : template === 2
      ? tempTwo
      : template === 3
      ? tempThree
      : {
          campaign_name: '',
          campaign_description: '',
          status: 'draft',
          steps: {},
        },
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(SET_LOAD_ANIMATION(true))
    dispatch(SET_CAMPAIGN_DATES_FILTERS({}))
    dispatch(SET_CAMPAIGN_FILTERS({}))
    setDelay(900)
  }, [])

  useEffect(() => {
    dispatch(SET_SEQUENCE_DATA(nodesData))
  }, [nodesData])

  useEffect(() => {
    if (Object.keys(ai_generated_data)?.length === 0) {
      setDelay(0)
      dispatch(SET_LOAD_ANIMATION(false))
    } else {
      const time = template === 0 ? 3500 : template === 1 ? 5200 : 8000
      const timeoutId = setTimeout(() => {
        setDelay(0)
        dispatch(SET_LOAD_ANIMATION(false))
      }, time)
      return () => clearTimeout(timeoutId)
    }
  }, [])

  const siderStyle: React.CSSProperties = {
    lineHeight: '120px',
    color: 'black',
    backgroundColor: 'white',
    boxShadow: '#ececec -5px 0px 9px 0px',
    borderRadius: '8px 0px 0px 8px',
    zIndex: 0,
    overflow: 'auto',
    // maxHeight: 100,
    margin: '2px 0px',
  }
  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: 'black',
    // display: 'flex',
  }

  return (
    <Layout>
      <NewCampaignHeader currentStep={currentStep} setCurrentStep={setCurrentStep} setNodesData={setNodesData} />
      {currentStep === 0 && (
        <Layout>
          <Content style={contentStyle}>
            <Canvas setCurrentSideBar={setCurrentSideBar} template={template} delay={delay} />
          </Content>
          <Sider width='500px' style={siderStyle}>
            {currentSideBar.type === 'send_tg_message' && (
              <NewCampaignTelegramMessage
                currentSideBar={currentSideBar}
                nodesData={nodesData}
                setNodesData={setNodesData}
              />
            )}
            {currentSideBar.type === 'send_linkedin_message' && (
              <LinkedinMessage currentSideBar={currentSideBar} nodesData={nodesData} setNodesData={setNodesData} />
            )}
            {currentSideBar.type === 'enrich_hubspot' && (
              <NewCampaignHubspotEnrichment
                currentSideBar={currentSideBar}
                nodesData={nodesData}
                setNodesData={setNodesData}
              />
            )}
            {currentSideBar.type === 'is_message_replied' && (
              <NewCampaignIsReplied currentSideBar={currentSideBar} nodesData={nodesData} setNodesData={setNodesData} />
            )}
            {currentSideBar.type === 'is_wait' && (
              <NewCampaignWait currentSideBar={currentSideBar} nodesData={nodesData} setNodesData={setNodesData} />
            )}
            {currentSideBar.type === 'linkedin_like_post' && (
              <LinkedinLike currentSideBar={currentSideBar} nodesData={nodesData} setNodesData={setNodesData} />
            )}
            {currentSideBar.type === 'is_linkedin_connected' && (
              <LinkedinAccepted currentSideBar={currentSideBar} nodesData={nodesData} setNodesData={setNodesData} />
            )}
            {currentSideBar.type === 'linkedin_connect' && (
              <LinkedinConnect currentSideBar={currentSideBar} nodesData={nodesData} setNodesData={setNodesData} />
            )}
          </Sider>
        </Layout>
      )}
      {currentStep === 1 && (
        <Layout>
          <Content style={contentStyle}>
            <Leads />
          </Content>
        </Layout>
      )}
      <NewCampaignFooter currentStep={currentStep} setCurrentStep={setCurrentStep} />
    </Layout>
  )
}

export default NewCampaign
