import React, { useEffect, useRef, useState } from 'react'
import { Modal, Input, Form, message, Button } from 'antd'
import styles from './SocialConnectionModal.module.scss'
import axios from 'axios'
import {
  useLazyCheckSignInStatusQuery,
  useLazyInstagramGetAccountStatusQuery,
  useLazyInstagramInstagrapiLoginQuery,
  useLazyInstagramLoginQuery,
  useLazyInstagramSendVerificationCodeQuery,
  useLazyLinkedinLoginQuery,
  useLazyLinkedinSendVerificationCodeQuery,
  useLazyLinkedinGetAccountStatusQuery,
} from './state/api/SocialConnectionApi'
import facebook_icon from 'common/assets/svg/facebook_icon.svg'
import telegram_icon from 'common/assets/svg/telegram_icon.svg'
import linkedin_icon from 'common/assets/svg/linkedin_icon.svg'
import instagram_icon from 'common/assets/svg/instagram_icon.svg'
// import discord_icon from 'common/assets/svg/discord_logo.svg'
import LoadingBox from '../LoadingBox/LoadingBox'
// import VerificationCode from './UI/VerificationCode'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'
import FlagInput from '../OutreachConnection/FlagInput'

interface SocialConnectionModalProps {
  open: boolean
  onClose: () => void
  platform: 'instagram' | 'facebook' | 'linkedin'
  setCheckLocalStorage?: any
}

const platformConfigs: any = {
  instagram: {
    icon: instagram_icon,
    loginQuery: useLazyInstagramLoginQuery,
    verificationQuery: useLazyInstagramSendVerificationCodeQuery,
    twoFAQuery: useLazyInstagramInstagrapiLoginQuery,
    accountStatusQuery: useLazyInstagramGetAccountStatusQuery,
  },
  linkedin: {
    icon: linkedin_icon,
    loginQuery: useLazyLinkedinLoginQuery,
    verificationQuery: useLazyLinkedinSendVerificationCodeQuery,
    twoFAQuery: useLazyLinkedinLoginQuery, // change later
    accountStatusQuery: useLazyLinkedinGetAccountStatusQuery,

    // getAccountStatusQuery: {},
  },
  facebook: {
    icon: facebook_icon,
    // loginQuery: useLazyFacebookLoginQuery,
    // verificationQuery: useLazyFacebookSendVerificationCodeQuery,
  },
  telegram: {
    icon: telegram_icon,
    // loginQuery: useLazyTelegramLoginQuery,
    // verificationQuery: useLazyTelegramSendVerificationCodeQuery,
  },
  discord: {
    // icon: discord_icon,
  },
}

const SocialConnectionModal: React.FC<SocialConnectionModalProps> = ({
  open,
  onClose,
  platform,
  setCheckLocalStorage,
}) => {
  const inputRef = useRef<any>(null)
  // eslint-disable-next-line
  const [user, setUser] = useLocalStorage('user', null)
  const platformUpperCase = platform[0].toLocaleUpperCase() + platform.slice(1)
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [form] = Form.useForm()
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  // eslint-disable-next-line
  // const [email, setEmail] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line
  const [countryCode, setCountryCode] = useState('')
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const { refetchUserLite } = useAuth()

  const { icon, loginQuery, verificationQuery, twoFAQuery, accountStatusQuery } = platformConfigs[platform]
  // eslint-disable-next-line
  const [login, { data: loginData, isLoading: isLoginLoading }] = loginQuery()
  // eslint-disable-next-line
  const [sendVerificationCode, { isLoading: isVerificationLoading }] = verificationQuery()
  const [twoFALogin] = twoFAQuery()
  // eslint-disable-next-line
  const [getAccountStatus, { isLoading: isAccountStatusLoading }] = accountStatusQuery()
  const [checkSignInStatus] = useLazyCheckSignInStatusQuery()
  // const [operationId, setOperationId] = useState('')
  // eslint-disable-next-line
  const [signInStatus, setSignInStatus] = useState('')
  const [openModalCheckSignedInDevices, setOpenModalCheckSignedInDevices] = useState(false)

  const pollOperationStatus = async (operationId: any, maxRetries = 20, ms = 5000) => {
    let attempts = 0
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        if (attempts >= maxRetries) {
          clearInterval(interval)
          reject(new Error('Max retries reached'))
          return
        }
        try {
          const statusResponse = await checkSignInStatus({ operationId })
          const { status, message } = statusResponse.data
          if (status !== 'processing') {
            resolve(message) // Resolve with the final message
            if (status !== 'require_check_signedin_app') clearInterval(interval)
            // setSignInStatus(message)
          }
        } catch (error) {
          clearInterval(interval)
          reject(error) // Reject with the error
        }
        attempts++
      }, ms) // Poll every 5 seconds
    })
  }

  const handleOk = () => {
    switch (platform) {
      case 'instagram':
        return handleInstagramOk()
      case 'linkedin':
        return handleLinkedinOk()
    }
  }

  const handleInstagramOk = async () => {
    if (currentStep === 2) {
      try {
        const values = await form.validateFields()
        setUsername(values?.username || '')
        setPassword(values?.password)
        setEmail(values?.email || '')
        setLoading(true)
        // await login({ ...values, country_code: countryCode })
        const { data } = await login({ ...values, country_code: countryCode })
        const d = await pollOperationStatus(data.opId)
        if (signInStatus === 'Signed in' || d === 'Signed in') {
          await getAccountStatus({ username: values?.username })
          // await getAccountStatus({ username: username, email: email })
          setCurrentStep(currentStep + 2)
        } else if (
          signInStatus === 'require_authentication_code' ||
          d === 'require_authentication_code' ||
          signInStatus === 'require_2fa_code' ||
          d === 'require_2fa_code'
        ) {
          setCurrentStep(currentStep + 1)
        } else {
          message.error('Something went wrong. Please try again.')
        }

        setLoading(false)
      } catch (errorInfo) {
        console.log('Failed:', errorInfo)
      }
    } else if (currentStep === 3) {
      try {
        setLoading(true)
        switch (platform) {
          case 'instagram':
            if (signInStatus === 'require_2fa_code') {
              const { data } = await twoFALogin({
                username: username,
                password: password,
                country_code: countryCode,
                two_factor_code: verificationCode,
              })
              if (data.message === 'Signed in') {
                await getAccountStatus({ username: username })
                // await getAccountStatus({ username: username, email: email })
                const { data: userData } = await refetchGetUserDetails()
                refetchUserLite(userData)
                setUser(userData)
                message.success(`Signed in to ${username}`)
                onClose()
              }
              if (data.message === 'Failed to update verification code') {
                setCurrentStep(currentStep)
              }
            } else {
              const { data } = await sendVerificationCode({
                username: username,
                verificationCode: verificationCode,
              })

              if (data.message === 'Signed in') {
                await getAccountStatus({ username: username })
                // await getAccountStatus({ username: username, email: email })
                const { data: userData } = await refetchGetUserDetails()
                refetchUserLite(userData)
                setUser(userData)
                message.success(`Signed in to ${username}`)
                onClose()
              }
              if (data.message === 'Failed to update verification code') {
                setCurrentStep(currentStep)
              }
            }
            break
          // case 'linkedin':
          //   const { linkedinVerificationData } = await sendVerificationCode({
          //     username: username,
          //     verificationCode: verificationCode,
          //   })
          //   if (linkedinVerificationData.message === 'Failed to update verification code') {
          //     setCurrentStep(currentStep)
          //   } else {
          //     setCurrentStep(currentStep + 1)
          //   }
          //   break
          default:
            break
        }
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
        setCurrentStep(currentStep)
        message.error('Something went wrong.')
      }
    } else if (currentStep === 4) {
      const { data: userData } = await refetchGetUserDetails()
      refetchUserLite(userData)
      setUser(userData)
      setCheckLocalStorage && setCheckLocalStorage((x: any) => !x)
      onClose()
    } else if (currentStep === 14) {
      const { data: userData } = await refetchGetUserDetails()
      setCheckLocalStorage && setCheckLocalStorage((x: any) => !x)
      refetchUserLite(userData)
      setUser(userData)
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleLinkedinOk = async () => {
    if (currentStep === 2) {
      try {
        const values = await form.validateFields()
        setUsername(values?.username || '')
        setPassword(values?.password)
        setEmail(values?.email || '')
        setLoading(true)
        // await login({ ...values, country_code: countryCode })
        const { data } = await login({ ...values, country_code: countryCode })
        const d = await pollOperationStatus(data.opId, 50, 15000)
        if (signInStatus === 'Signed in' || d === 'Signed in') {
          await getAccountStatus({ email: values?.email })
          setCurrentStep(currentStep + 2)
        } else if (signInStatus === 'Already signed in before' || d === 'Already signed in before') {
          // message.error('You already signed in with this account!')
          await getAccountStatus({ email: values?.email })
          setCurrentStep(currentStep + 2)
        } else if (
          signInStatus === 'require_authentication_code' ||
          d === 'require_authentication_code' ||
          signInStatus === 'require_2fa_code' ||
          d === 'require_2fa_code'
        ) {
          setCurrentStep(currentStep + 1)
        } else if (signInStatus === 'require_register_phone' || d === 'require_register_phone') {
          setCurrentStep(5)
        } else if (
          signInStatus === 'require_another_authentication_code' ||
          d === 'require_another_authentication_code'
        ) {
          setCurrentStep(6)
        } else if (signInStatus === 'require_check_signedin_app' || d === 'require_check_signedin_app') {
          setOpenModalCheckSignedInDevices(true)
        } else {
          message.error('Something went wrong. Please try again.')
        }
        setLoading(false)
      } catch (errorInfo) {
        console.log('Failed:', errorInfo)
      }
    } else if (currentStep === 3 || currentStep === 5 || currentStep === 6) {
      try {
        setLoading(true)
        const { data: linkedinVerificationData } = await sendVerificationCode({
          email: email,
          verificationCode: verificationCode,
        })
        // console.log(linkedinVerificationData)
        if (
          linkedinVerificationData.message === 'Failed to update verification code' ||
          linkedinVerificationData.message === 'Something went wrong'
        ) {
          setCurrentStep(currentStep)
          message.error('Please try again.')
        } else if (linkedinVerificationData?.account_status === 'require_another_authentication_code') {
          setCurrentStep(6)
        } else {
          setCurrentStep(4)
        }
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
        setCurrentStep(currentStep)
        message.error('Something went wrong.')
      }
    } else if (currentStep === 4) {
      const { data: userData } = await refetchGetUserDetails()
      refetchUserLite(userData)
      setUser(userData)
      setCheckLocalStorage && setCheckLocalStorage((x: any) => !x)
      onClose()
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleCancel = () => {
    onClose()
    setCurrentStep(1)
    form.resetFields()
  }

  useEffect(() => {
    const fetchGeoInfo = async () => {
      try {
        // const response = await axios.get('http://ip-api.com/json/')
        const response = await axios.get('https://freeipapi.com/api/json/')
        setCountryCode(response.data.countryCode)
      } catch (error) {
        console.error('Failed to fetch country code:', error)
      }
    }

    fetchGeoInfo()
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef, verificationCode])

  const StepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className={styles.firstScreen}>
            <img src={icon} alt='' />
            <p>Let's connect your {platformUpperCase} account to Convrt!</p>
          </div>
        )
      case 2:
        return isLoginLoading || loading ? (
          <div className={styles.loading}>
            <LoadingBox />
            <span>Connecting to {platformUpperCase} might take a few minutes.</span>
          </div>
        ) : (
          <Form form={form} layout='vertical' className={`socialConnectionForm ${styles.stepContent}`}>
            {platform === 'linkedin' && (
              <div className={styles.inputContainer}>
                <span>Email Address</span>
                <Form.Item name='email' rules={[{ required: true, message: 'Please input your email!' }]} hasFeedback>
                  <Input style={{ width: 300, margin: '0px !important' }} placeholder='Email' />
                </Form.Item>
              </div>
            )}
            {platform === 'instagram' && (
              <div className={styles.inputContainer}>
                <span>Username</span>
                <Form.Item
                  name='username'
                  rules={[{ required: true, message: 'Please input your username!' }]}
                  hasFeedback>
                  <Input style={{ width: 300 }} placeholder='Username' />
                </Form.Item>
              </div>
            )}
            <div className={styles.inputContainer}>
              <span>Password</span>
              <Form.Item
                name='password'
                rules={[{ required: true, message: 'Please input your password!' }]}
                hasFeedback>
                <Input.Password style={{ width: 300 }} placeholder='Password' />
              </Form.Item>
            </div>
            <div className={styles.inputContainer}>
              <span>{platformUpperCase}'s Country</span>
              <Form.Item
                name='countryCode'
                rules={[{ required: true, message: 'Please select a country!' }]}
                initialValue={countryCode}
                hasFeedback>
                <FlagInput
                  selectStyle={{ width: 300 }}
                  labelCountryCode
                  defaultCountryCodeValue={countryCode}
                  onChange={(e: any) => {
                    setCountryCode(e)
                  }}
                />
              </Form.Item>
            </div>
          </Form>
        )
      case 3:
        return (
          <div className={styles.digitCode}>
            {/* <p>Please insert your 6-digits code you received from Instagram</p> */}
            <p>Please insert your 6-digits code you received from {platformUpperCase}</p>

            {/* <VerificationCode numInputs={6} onCompletion={setVerificationCode} /> */}
            <Input
              ref={inputRef}
              style={{ width: '100%', marginTop: '20px' }}
              maxLength={6}
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder='Enter 6-digit code'
            />
          </div>
        )
      case 4:
        return <div>Your account is now successfully connected!</div>
      case 5:
        return (
          <div className={styles.digitCode}>
            <p>Please enter your phone number for confirmation</p>
            <Input
              style={{ width: '100%', marginTop: '20px' }}
              value={verificationCode}
              defaultValue={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder='Enter your phone number'
            />
          </div>
        )
      case 6:
        return (
          <div className={styles.digitCode}>
            {/* <p>Please insert your 6-digits code you received from Instagram</p> */}
            <p>
              Please insert your <span style={{ fontWeight: 600 }}>NEW</span> 6-digits code you received from{' '}
              {platformUpperCase}
            </p>

            {/* <VerificationCode numInputs={6} onCompletion={setVerificationCode} /> */}
            <Input
              ref={inputRef}
              style={{ width: '100%', marginTop: '20px' }}
              maxLength={6}
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder='Enter 6-digit code'
            />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <>
      <Modal
        title={`Connect Your ${platformUpperCase} Account`}
        className='socialConnectionModal'
        closable={false}
        centered
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Next'
        cancelText='Cancel'
        footer={false}>
        <div className={styles.container}>
          <StepContent />
        </div>
        <div className={styles.actions}>
          {/* <Button className={styles.cancelButton}> */}
          <Button className={styles.cancelButton} onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>
          <Button type='primary' onClick={handleOk} loading={loading}>
            {currentStep === 4 ? 'Confirm' : 'Next'}
          </Button>
        </div>
      </Modal>

      <Modal
        title={`Check your ${platformUpperCase} app`}
        className='socialConnectionModal'
        closable={false}
        centered
        open={openModalCheckSignedInDevices}
        style={{ maxWidth: '400px' }}
        footer={false}>
        <div className={styles.digitCode}>
          <p>
            We sent a notification to your signed in devices. Open your Linkedin App and tap Yes to confirm your sign in
            attemp .
          </p>

          <Button
            type='primary'
            style={{ marginLeft: 'auto', width: '100%' }}
            onClick={() => setOpenModalCheckSignedInDevices(false)}>
            Close
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default SocialConnectionModal
