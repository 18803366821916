import React, { useEffect } from 'react'
import { Collapse, Menu, Avatar, Spin, Tooltip } from 'antd'
import { EllipsisOutlined, LoadingOutlined } from '@ant-design/icons'
import styles from './ChatChannels.module.scss'
import user from 'common/assets/png/user_table.png'
import telegram from 'common/assets/svg/tele_icon.svg'
import instagram from 'common/assets/svg/insta_icon.svg'
import linkedin from 'common/assets/svg/linked_icon.svg'
import discord from 'common/assets/svg/disc_icon.svg'
import twitter from 'common/assets/svg/xtwit_icon.svg'
import facebook from 'common/assets/svg/facebook_icon.svg'
import add_icon from 'common/assets/svg/add_icon.svg'
import channel_icon from 'common/assets/svg/channel_icon.svg'
import { useLazyGetDialogsQuery } from './state/api/Chat-API'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_SELECTED_ACCOUNT } from './state/chatSlice'
import { useAuth } from 'common/hooks/useAuth.hooks'
import ImageWithFallback from '../General/ImageWithFallback'

const { Panel } = Collapse
const icons: any = { telegram, instagram, linkedin, discord, twitter, facebook, user, add_icon }

const MENU_ITEMS = [
  {
    id: '1',
    type: 'telegram',
    icon: 'telegram',
    title: 'Telegram',
    channels: [],
  },
  // {
  //   id: '2',
  //   type: 'instagram',

  //   icon: 'instagram',
  //   title: 'Instagram',
  //   channels: [],
  // },
  // {
  //   id: '3',
  //   type: 'facebook',
  //   icon: 'facebook',
  //   title: 'Facebook',
  //   channels: [],
  // },
  // {
  //   id: '4',
  //   type: 'linkedin',
  //   icon: 'linkedin',
  //   title: 'LinkedIn',
  //   channels: [],
  // },
  // {
  //   id: '5',
  //   type: 'discord',
  //   icon: 'discord',
  //   title: 'Discord',
  //   channels: [],
  // },
  // {
  //   id: '6',
  //   type: 'twitter',
  //   icon: 'twitter',
  //   title: 'X (Twitter)',
  //   channels: [],
  // },
]

interface ChatChannelsProps {
  collapsed: boolean
}
const ChatChannels: React.FC<ChatChannelsProps> = ({ collapsed }) => {
  // const [getChat] = useLazyGetChatQuery()
  const [getDialogs, { data: dialogsData }] = useLazyGetDialogsQuery()
  // const [unifiySpecificAccounts] = useLazyUnifySpecificAccountsQuery()
  const { userDetails } = useAuth()
  const { is_unify_finished } = useAppSelector((state) => state.chat)
  const dispatch = useAppDispatch()
  // const [getChatMessages, { data: chatMessagesData }] = useLazyGetChatMessagesQuery()
  // const [chatDataState, setChatDataState] = useState(MENU_ITEMS)
  // const [selectedChannelId, setSelectedChannelId] = useState<string | null>(null)
  // const [selectedChannelMessages, setSelectedChannelMessages] = useState<any[]>([])

  const handleMenuItemClick = async (item: any) => {
    // console.log(item)
    // Find the selected channel by ID from chatData
    // console.log(id)
    dispatch(SET_SELECTED_ACCOUNT(item))
    // post('/log', {
    //   action: 'Unified Inbox: User Switched Account',
    //   data: item,
    // })
    // const selectedChannel = chatData
    //   .flatMap((channel: any) => channel.channels)
    //   .find((channel: any) => channel.id === item.id)

    // console.log(selectedChannel)
    // setSelectedChannelId(item.account_id)

    // if (selectedChannel) {
    // Update the selected channel ID and messages
    // setSelectedChannelId(item.id)
    // setSelectedChannelMessages(selectedChannel.messages || [])
    // }
  }

  useEffect(() => {
    const getChatdata = async () => {
      await getDialogs()
    }
    if (is_unify_finished) {
      getChatdata()
    }
  }, [is_unify_finished])

  // useEffect(() => {
  //   const unifySpecific = async () => {
  //     for (const [, data] of Object.entries(dialogsData as { [key: string]: any })) {
  //       const accountsToUnify = []
  //       for (const account of data.accounts) {
  //         if (!account?.status?.is_finished) {
  //           accountsToUnify.push(account)
  //         }
  //       }
  //       if (accountsToUnify.length > 0) {
  //         console.log('Re-Unify')
  //         await unifiySpecificAccounts({ accounts: accountsToUnify })
  //       }
  //     }
  //   }

  //   if (is_unify_finished && dialogsData) {
  //      unifySpecific()
  //   }
  // }, [dialogsData, is_unify_finished])

  useEffect(() => {
    dispatch(SET_SELECTED_ACCOUNT({ name: 'All Active Accounts', account_id: `all_data`, type: 'telegram' }))
  }, [])

  return (
    <div className={styles.chat_channels}>
      <Collapse className='chat_channels_collapse' defaultActiveKey={['1']} accordion>
        <Panel
          className={`${styles['view_all_channel_item']} view_all_channel_item`}
          header={
            <>
              <span className={`${styles['left']} left`}>
                <img src={channel_icon} alt='channel_icon' />
                <span className='hide all'>View</span>
                <span className='all m_2'>all</span>
                <span className='hide all'>channels</span>
              </span>
              <span className='right'>
                <div className={styles.chat_channels_accounts}>
                  <Avatar
                    style={{ backgroundColor: 'transparent', color: '#000000', fontSize: '18px' }}
                    size={24}
                    icon={<EllipsisOutlined />}
                  />
                </div>
              </span>
            </>
          }
          key='0'></Panel>
        {MENU_ITEMS.map((item: any) => {
          const isLoading = !dialogsData && userDetails?.addons?.unified_inbox
          const platform = item.type
          const dialog = dialogsData?.[platform]
          const unread_count = dialog?.dialogData?.reduce((acc: any, item: any) => {
            const count = parseInt(item.unread_count, 10)
            return acc + (isNaN(count) ? 0 : count > 0 ? 1 : 0)
            // return acc + parseInt(item.unread_count, 10)
          }, 0)
          return (
            <Panel
              collapsible={isLoading ? 'disabled' : 'icon'}
              showArrow={!isLoading}
              header={
                <>
                  <span className={`${styles['left']} left`}>
                    <img src={icons[item.icon]} alt='' />
                    {item.title}
                    {unread_count > 0 && !collapsed && (
                      <div className={styles.unreadCount}>
                        <span>{unread_count}</span>
                      </div>
                    )}
                  </span>
                  <span className='right'>
                    {isLoading ? (
                      <div>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                      </div>
                    ) : (
                      <div className={styles.chat_channels_accounts}>
                        <span className={styles.chat_channels_dot}></span>
                        <p>{dialog?.total_count} Accounts</p>
                      </div>
                    )}
                  </span>
                </>
              }
              key={item.id}>
              <Menu theme='light' mode='inline' defaultSelectedKeys={['1']}>
                <Menu.Item
                  key={`all_data`}
                  className='default-class'
                  onClick={() =>
                    handleMenuItemClick({ name: 'All Active Accounts', account_id: `all_data`, type: platform })
                  }>
                  <div className='menu_content'>
                    <div className={styles.centered} style={{ alignItems: 'center' }}>
                      <img src={icons[item.icon]} alt='' />
                      <span className='nav-text'>All Active Accounts</span>
                    </div>
                    <div className='right'>
                      {unread_count > 0 && (
                        <div className={styles.unreadCount}>
                          <span>{unread_count}</span>
                        </div>
                      )}
                      <Avatar
                        style={{ backgroundColor: 'transparent', color: '#000000', fontSize: '18px' }}
                        size={24}
                        icon={<EllipsisOutlined />}
                      />
                    </div>
                  </div>
                </Menu.Item>
                {dialog?.accounts?.map((dialogItem: any) => {
                  const status = dialogItem?.status?.is_finished === true
                  const notExists = dialogItem?.status?.detail === 'User has not signed to telegram yet'
                  if (notExists) return <></>
                  const totalUnreadCount = dialog?.unreadData
                    ?.filter((d: any) => d.receiver_number === dialogItem?.receiver_number)
                    .reduce((acc: any, current: any) => {
                      const count = parseInt(current.unread_count, 10)
                      return acc + (isNaN(count) ? 0 : count > 0 ? 1 : 0)
                      // return acc + (isNaN(count) ? 0 : count)
                    }, 0)

                  return (
                    <Menu.Item
                      key={dialogItem.id}
                      className='online'
                      onClick={() => handleMenuItemClick(dialogItem)}
                      disabled={!status}>
                      <div className='menu_content'>
                        <div className='left'>
                          {status ? (
                            <Avatar
                              size={24}
                              icon={
                                <ImageWithFallback
                                  style={{ borderRadius: '50%' }}
                                  src={dialogItem.picture ? dialogItem.picture : icons[item.icon]}
                                  fallbackStrOrElement={icons[item.icon]}
                                />
                              }
                            />
                          ) : (
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 20, marginRight: '15px' }} spin />} />
                          )}
                          <Tooltip title={`${dialogItem.first_name} / ${dialogItem.area_code}${dialogItem.number}`}>
                            <span className='nav-text'>{`${
                              dialogItem.first_name?.length > 15
                                ? `${dialogItem.first_name.slice(0, 15)?.trim()}...`
                                : dialogItem.first_name
                            } (${dialogItem.username})`}</span>
                          </Tooltip>
                          {/* <span className='nav-text'>{`${dialogItem.area_code}${dialogItem.number}`}</span> */}
                        </div>
                        <div className='right'>
                          {dialogItem.count ? <span className='count'>{dialogItem.count}</span> : ''}
                          {dialogItem.online === false ? <span className='reconnect'>Reconnect</span> : ''}
                          {status && (
                            <>
                              {totalUnreadCount > 0 && (
                                <div className={styles.unreadCount}>
                                  <span>{totalUnreadCount}</span>
                                </div>
                              )}
                              <Avatar
                                style={{ backgroundColor: 'transparent', color: '#000000', fontSize: '18px' }}
                                size={24}
                                icon={<EllipsisOutlined />}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </Menu.Item>
                  )
                })}
                {/* <Menu.Item
                  key='add_account'
                  className='default-class'
                  onClick={() =>
                    handleMenuItemClick({ name: 'Add Account', account_id: 'add_account', type: platform })
                  }>
                  <div className='menu_content'>
                    <div className={styles.centered} style={{ alignItems: 'center' }}>
                      <img src={icons['add_icon']} alt='' />
                      <span className='nav-text'>Add Account</span>
                    </div>
                    <div className='right'>
                      <Avatar
                        style={{ backgroundColor: 'transparent', color: '#000000', fontSize: '18px' }}
                        size={24}
                        icon={<EllipsisOutlined />}
                      />
                    </div>
                  </div>
                </Menu.Item> */}
              </Menu>
            </Panel>
          )
        })}
      </Collapse>
    </div>
  )
}

export default ChatChannels
