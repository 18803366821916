import { Button } from 'antd'

// import { ReactComponent as CloseArrow } from 'common/assets/svg/CaretCircleLeft.svg'
import FancyFilter from 'common/components/fancy-filter/fancy-filter'
import './SideFilter.scss'

import { companyFilter } from 'common/static-data/company-filter'
import { contactsFilter } from 'common/static-data/contacts-filter'
import { EVM_COMPATIBLE_CHAINS } from 'common/static-data/chainFilter'

import { useAppDispatch, useAppSelector } from 'state'
// import { setReset, setSidebarOpen, setTechSidebarOpen } from 'state/filtersSlice/filtersSlice'
import { setReset } from 'state/filtersSlice/filtersSlice'

import { useActiveFilters } from 'common/hooks/useFilters'
import {
  SET_FILTER,
  SET_IS_FILTER_CHANGED,
  SET_LAST_ACTION_PAGE,
} from 'features/intent-signals/state/slice/companiesSlice'
import { SET_LAST_ACTION_PAGE_CONTACT } from '../../../features/contacts/state/slice/contactsSlice'

import _ from 'lodash'
import { useEffect } from 'react'
import TechSideFilter from '../SideFilterCategories/TechSideFilter'
import { useShowStartScreen } from 'common/providers/ShowStartScreenProvider'
import { post } from 'common/api/axios'
import { useLocation } from 'react-router-dom'
import { filterObjectToString } from 'common/utils/objectToValues'

const sortFilters = (items: any, addons: any) => {
  const falseValues = []
  const trueValues = []
  const connectPlusItem = []
  for (const item of items) {
    if (item.key === 'onChain') item.key = 'onChain_filter'
    if (item.key === 'lists') item.key = 'companyList'
    if (item.key === 'intents') item.key = 'signals'
    if (item.key === 'funding') item.key = 'funding_filter'
    if (item.key === 'revenue') item.key = 'revenue_filter'
  }
  for (const item of items) {
    if (item.key === '') {
      connectPlusItem.push(item)
    } else if (
      addons[item.key] === true ||
      item.key === 'Employees' ||
      item.key === 'companies_new' ||
      item.key === 'excludeLists'
    ) {
      trueValues.push(item)
    } else {
      falseValues.push(item)
    }
  }
  return [...trueValues, ...falseValues, ...connectPlusItem]
}

export const SideFilter = () => {
  const dispatch = useAppDispatch()
  const { isTechSidebarOpen } = useAppSelector((state) => state.filters)
  const { filter } = useAppSelector((state) => state.companies)
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const addons = user?.addons || {}

  const { setShowStartScreen } = useShowStartScreen()
  const [activeFilters, setActiveFilters] = useActiveFilters()
  const { pathname } = useLocation()

  const areFiltersEmpty = _.isEqual(filter, {}) || !filterObjectToString(filter)?.length
  const areFiltersUnchanged = _.isEqual(filter, activeFilters)
  const filterData = {
    companies: sortFilters(companyFilter, addons),
    contacts: sortFilters(contactsFilter, addons),
  }

  // const handleSideMenuClose = () => {
  //   dispatch(setSidebarOpen(false))
  //   dispatch(setTechSidebarOpen(false))
  // }

  const handleLoadFilters = (e: any) => {
    if (13 === e.keyCode) {
      setActiveFilters(filter)
    }
  }

  const handleLoadDataFilters = async () => {
    // dispatch(SET_SELECTED_COMPANIES([]))
    // dispatch(SET_SELECTED_CONTACTS([]))
    // dispatch(SET_COMPANY_SELECTED_ROWS([]))

    dispatch(SET_LAST_ACTION_PAGE(1))
    dispatch(SET_LAST_ACTION_PAGE_CONTACT(1))

    setActiveFilters(filter)
    setShowStartScreen(!Object.keys(filter)?.length)

    let updatedFilter = { ...filter }
    if (
      updatedFilter.onChain &&
      updatedFilter.onChain.chains &&
      updatedFilter.onChain.chains.includes('evm-compatible')
    ) {
      updatedFilter = {
        ...updatedFilter,
        onChain: {
          ...updatedFilter.onChain,
          chains: [...filter.onChain.chains, ...EVM_COMPATIBLE_CHAINS].filter(
            (value, index, self) => value !== 'evm-compatible' && self.indexOf(value) === index,
          ),
        },
      }
    }

    const { connectPlus, ...recentFilter } = updatedFilter
    dispatch(SET_IS_FILTER_CHANGED(true))

    if (filterObjectToString(recentFilter)?.length) {
      try {
        const response = await post('/user/filter', { ...recentFilter, route: pathname.split('/')[1] })
        if (response?.data?.has_filtered === false) {
          const user = localStorage.getItem('user') || '{}'
          const userData = JSON.parse(user)
          userData.has_filtered = true
          localStorage.setItem('user', JSON.stringify(userData))
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleResetFilters = () => {
    dispatch(setReset(true))
    dispatch(SET_IS_FILTER_CHANGED(true))
    dispatch(SET_LAST_ACTION_PAGE(1))
    dispatch(SET_LAST_ACTION_PAGE_CONTACT(1))
    dispatch(SET_FILTER({}))
    setActiveFilters({})
  }

  useEffect(() => {
    document.getElementById('filters-sidebar')?.addEventListener('keydown', handleLoadFilters)

    return () => {
      document.getElementById('filters-sidebar')?.removeEventListener('keydown', handleLoadFilters)
    }
  }, [filter])

  return (
    <div id='filters-sidebar' className='filters-sidebar' tabIndex={0}>
      <div className='c-side-filter'>
        <div className='c-side-filter__items'>
          <div className='c-side-filter__items__header'>
            <span className='c-side-filter__items__header-text'>Select Filters</span>
            {/* <CloseArrow className='c-side-filter__items__header-close' onClick={handleSideMenuClose} /> */}
          </div>
          <div>
            <div className='c-side-filter__body'>
              <FancyFilter data={filterData?.[pathname.split('/')[1] as keyof typeof filterData]} />
            </div>
          </div>
        </div>
        <div className='filterButtons'>
          <Button className='blue-text-btn' type='text' onClick={handleResetFilters} disabled={areFiltersEmpty}>
            {'Reset'}
          </Button>
          <Button type='primary' onClick={handleLoadDataFilters} disabled={areFiltersEmpty || areFiltersUnchanged}>
            <div className='c-side-filter__primary-btn'>
              <span>{'Apply Filters'}</span>
            </div>
          </Button>
        </div>
      </div>
      {isTechSidebarOpen && <TechSideFilter />}
    </div>
  )
}
