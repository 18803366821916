import React from 'react'
import { Modal } from 'antd'

import ConnectPlusOnBoarding from './ConnectPlusOnBoarding'

import styles from './ConnectPlusModal.module.scss'

interface ConnectPlusModalProps {
  open: boolean
  onClose: () => void
}

const ConnectPlusModal: React.FC<ConnectPlusModalProps> = ({ open, onClose }) => (
  <div className={styles.modal}>
    <Modal
      centered
      open={open}
      okButtonProps={{
        className: 'hidden',
      }}
      cancelButtonProps={{
        className: 'hidden',
      }}
      onCancel={onClose}
      width={600}
      bodyStyle={{
        height: 590,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <ConnectPlusOnBoarding onClose={onClose} />
    </Modal>
  </div>
)

export default ConnectPlusModal
